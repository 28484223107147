export const ActionList = [
  "LOGIN",
  "GET_USER_ROLE",
  "SIGNUP",
  "UPDATE_USER",
  "AFFECT_USERS_ROLE",
  "LOGOUT",
  "OPEN_USER_SPACE",
  "GET_PROJECTS",
  "ADD_PROJECT",
  "DELETE_PROJECT",
  "GET_PROJECT",
  "GET_PROJECT_INFO",
  "CLOSE_PROJECT",
  "UPDATE_PROJECT",
  "GET_ALL_USERS",
  "LOAD_ALL_USERS",
  "ADD_USER_TO_PROJECT",
  "REMOVE_USER_FROM_PROJECT",
  "START_VIEWER",
  "CLOSE_VIEWER",
  "UPLOAD_MODEL",
  "DELETE_MODEL",
  "HANDLE_MODELS_VISIBILITY",
  "TOGGLE_CLIPPER",
  "DELETE_ALL_CLIPPER",
  "GET_CLIPPERS",
  "REMOVE_CLIPPER",
  "TOGGLE_LENGTH_DIMENSION",
  "DELETE_ALL_LENGTH_DIMENSION",
  "GET_LENGTH_DIMENSION",
  "REMOVE_LENGTH_DIMENSION",
  "INIT_VIEWER",
  "GET_ELEMENT_PROPS",
  "HIGHLIGHT_PARENT",
  "DESELECTED_ELEMENT",
  "SET_WHITE_MATERIAL",
  "INIT_MATERIAL",
  "TOGGLE_POSTPROD",
  "TOGGLE_SPACES",
  "TOGGLE_BACKGROUND_SCENE",
  "CHANGE_LANGUE",
  "OPEN_PLAN_MODE",
  "GET_PLANS",
  "DISPLAY_PLAN",
  "EXIT_PLAN_MODE",
  "EXPORT_DXF",
  "LOAD_FILTER_DATA",
  "GET_FILTER_DATA",
  "SEND_FILTER_DATA",
  "GET_RESULT_FILTER_DATA",
  "SEND_TYPENAME_FOR_RESULT_FILTER_BQ",
  "GET_RESULT_FILTER_BQ_BY_TYPE",
  "CLOSE_RESULT_FILTER_BQ",
  "SEND_COLOR_QUERY",
  "REMOVE_COLORS",
  "CLOSE_RESULT_FILTER_DATA",
  "SEND_VISIBILITY_DATA",
  "SHOW_ALL",
  "HIDE_SELECTED",
  "ISOLATE_SELECTED",
  "SELECT_VISIBLE",
  "EXPORT_TO_EXCEL",
  "VALIDATE_SINGLE_EDITION",
  "ACTIVATE_MULTI_SELECTION",
  "GET_MULTI_SELECT_DATA",
  "CLOSE_MULTI_SELECT_BQ",
  "RESET_MULTI_SELECTION",
  "SEND_TYPENAME_FOR_MULTI_SELECT_BQ",
  "GET_MULTI_SELECT_BQ_BY_TYPE",
  "SAVE_FILTER",
  "GET_SAVED_FILTERS",
  "SEND_SAVED_FILTERS",
  "DELETE_SAVED_FILTER",
  "DISPLAY_SELECTED_FILTER",
  "UPDATE_FILTER_WITH_SAVED_FILTER_DATA",
] as const;

export type ActionType = (typeof ActionList)[number];

export interface Action {
  type: ActionType;
  payload?: any;
}
