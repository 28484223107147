import { FC } from "react";
import styles from "./classic-button.module.css";

export const ClassicButton: FC<{
  text: string;
  actionClick: () => void;
}> = (props) => {
  return (
    <div>
      <button className={styles["filter-button"]} onClick={props.actionClick}>
        {props.text}
      </button>
    </div>
  );
};
