/**
 * measure navbar button
 */
export class LeftClick {
  private canvas: HTMLCanvasElement;
  private moving: boolean;
  public isLongClick: boolean | null;
  public isLongRightClick: boolean | null;
  private isLongDoubleTouch: boolean | null;
  private isMoveTouch: boolean;
  private touchStartTime: number | undefined;

  /**
   * @constructs Click
   *
   * @param {Object} canvas - Viewer instance
   */
  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.moving = false;
    this.isLongClick = null;
    this.isLongRightClick = null;
    this.isLongDoubleTouch = null;
    this.isMoveTouch = false;
    this.touchStartTime = undefined;
    this.setupClick();
    this.setupRightClick();
    // this.setupDoubleTouch();
    this.movingTouch();
  }

  /**
   * Sets up the events listeners to load the settings
   */
  private setupClick() {
    let stop: number | undefined, start: number | undefined;
    this.canvas.addEventListener("mousedown", (e) => {
      if (e.button === 0) {
        start = window.performance.now();
      }
    });

    this.canvas.addEventListener("mouseup", (e) => {
      if (e.button === 0) {
        stop = window.performance.now();
      }
    });

    this.canvas.addEventListener("click", (event) => {
      event.preventDefault();
      let timeMoving = stop! - start!;
      if (timeMoving < 300) {
        this.isLongClick = false;
      } else {
        this.isLongClick = true;
      }
    });
  }

  private setupRightClick() {
    let stop: number | undefined, start: number | undefined;
    this.canvas.addEventListener("mousedown", (e) => {
      if (e.button === 2) {
        start = window.performance.now();
      }
    });

    this.canvas.addEventListener("mouseup", (e) => {
      if (e.button === 2) {
        stop = window.performance.now();
      }
    });
    this.canvas.addEventListener("contextmenu", (event) => {
      event.preventDefault();
      let timeMoving = stop! - start!;
      if (timeMoving > 300) {
        this.isLongRightClick = true;
      } else {
        this.isLongRightClick = false;
      }
    });
  }

  // private setupDoubleTouch() {
  //   let stop: number | undefined, start: number | undefined;
  //   this.canvas.addEventListener("touchstart", (event) => {
  //     if (!event.isPrimary && event.pointerType === "touch") {
  //       start = window.performance.now();
  //     }
  //   });

  //   this.canvas.addEventListener("touchend", (event) => {
  //     if (!event.isPrimary && event.pointerType === "touch") {
  //       stop = window.performance.now();
  //       let timeMoving = stop! - start!;
  //       if (timeMoving < 300 || this.movingTouch) {
  //         this.isLongDoubleTouch = false;
  //       } else {
  //         this.isLongDoubleTouch = true;
  //       }
  //     }
  //   });
  // }

  private movingTouch() {
    this.canvas.addEventListener("touchmove", () => {
      this.isMoveTouch = true;
    });
  }
}
