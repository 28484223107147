import { FC } from "react";
import { CardContentType } from "../../../types";
import styles from "./onglet.module.css";
export const Onglet: FC<{
  text: string;
  onTabClick: (cardContentType: CardContentType) => void;
  type: CardContentType;
}> = (props) => {
  const onReducedClickHandler = () => {
    props.onTabClick(props.type);
  };
  return (
    <div className={styles["onglet"]} onClick={onReducedClickHandler}>
      <span>{props.text}</span>
    </div>
  );
};
