import { Checkbox, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { isFragmentIdMapEmpty } from "../../../core/utils/fragmentIdMap-helpers";
import { useAppContext } from "../../../middleware/context-provider";

export const Contextmenu: FC<{
  onContextMenuClose: () => void;
  contextMenu: {
    mouseX: number;
    mouseY: number;
  } | null;
}> = ({ onContextMenuClose, contextMenu }) => {
  const [itemDisabled, setItemDisabled] = useState<{
    hide: boolean;
    highlight: boolean;
    isolate: boolean;
    selectVisible: boolean;
    displayAll: boolean;
    multiSelect: boolean;
  }>({
    hide: true,
    highlight: true,
    isolate: true,
    selectVisible: false,
    displayAll: true,
    multiSelect: false,
  });

  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);

  const handleCheckboxMenuClick = () => {
    setIsCheckboxChecked((prevState) => !prevState);
    onContextMenuClose();
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setIsCheckboxChecked(event.target.checked);
    onContextMenuClose();
  };

  const [state, dispatch] = useAppContext();

  useEffect(() => {
    if (state.elementsVisibilityState) {
      const visibilityData = state.elementsVisibilityState;
      if (
        !isFragmentIdMapEmpty(visibilityData.selectedElements) ||
        !isFragmentIdMapEmpty(visibilityData.selectedSpaces)
      ) {
        setItemDisabled((prevState) => {
          return {
            ...prevState,
            hide: false,
            highlight: false,
            isolate: false,
          };
        });
      } else {
        setItemDisabled((prevState) => {
          return { ...prevState, hide: true, highlight: true, isolate: true };
        });
      }
      if (visibilityData.hasHiddenElements) {
        setItemDisabled((prevState) => {
          return {
            ...prevState,
            displayAll: false,
          };
        });
      } else {
        setItemDisabled((prevState) => {
          return {
            ...prevState,
            displayAll: true,
          };
        });
      }
      if (visibilityData.visibleElements.length) {
        setItemDisabled((prevState) => {
          return {
            ...prevState,
            selectVisible: false,
          };
        });
      } else {
        setItemDisabled((prevState) => {
          return {
            ...prevState,
            selectVisible: false,
          };
        });
      }
    }
  }, [
    state.elementsVisibilityState,
    state.elementsVisibilityState?.selectedElements,
    state.elementsVisibilityState?.selectedSpaces,
  ]);

  useEffect(() => {
    dispatch({
      type: "ACTIVATE_MULTI_SELECTION",
      payload: isCheckboxChecked,
    });
  }, [isCheckboxChecked]);

  const isolateSelectedItems = () => {
    dispatch({ type: "ISOLATE_SELECTED" });
    onContextMenuClose();
  };
  const extractSelectedItems = () => {
    dispatch({ type: "ISOLATE_SELECTED", payload: "extract" });
    onContextMenuClose();
  };

  const hideSelectedElements = () => {
    dispatch({ type: "HIDE_SELECTED" });
    onContextMenuClose();
  };

  const selectVisibleElements = () => {
    dispatch({ type: "SELECT_VISIBLE" });
    onContextMenuClose();
  };

  const showAll = () => {
    dispatch({ type: "SHOW_ALL" });
    onContextMenuClose();
  };

  return (
    <Menu
      open={contextMenu !== null}
      onClose={onContextMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
      autoFocus={false}
    >
      <MenuItem
        disabled={itemDisabled.hide}
        dense
        divider
        onClick={hideSelectedElements}
      >
        <ListItemText>Cacher les éléments sélectionnés</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={itemDisabled.highlight}
        dense
        divider
        onClick={extractSelectedItems}
      >
        <ListItemText>Extraire les éléments sélectionnés</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={itemDisabled.isolate}
        dense
        divider
        onClick={isolateSelectedItems}
      >
        <ListItemText>Isoler les éléments sélectionnés</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={itemDisabled.selectVisible}
        dense
        divider
        onClick={selectVisibleElements}
      >
        <ListItemText>Sélectionner les éléments visibles</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={itemDisabled.displayAll}
        dense
        divider
        onClick={showAll}
      >
        <ListItemText>Tout afficher</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={itemDisabled.multiSelect}
        dense
        disableGutters
        onClick={handleCheckboxMenuClick}
      >
        <Checkbox
          size="small"
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange}
        />
        Mode Multi-selection
      </MenuItem>
    </Menu>
  );
};
