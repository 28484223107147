import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FC } from "react";

import styles from "./show-saved-filters-logo.module.css";

export const ShowSavedFiltersLogo: FC<{
  toggleFiltersVisibility: () => void;
}> = (props) => {
  const { toggleFiltersVisibility } = props;
  return (
    <div className={styles["show-filters"]}>
      <IconButton style={{ color: "white" }} onClick={toggleFiltersVisibility}>
        <KeyboardArrowUpIcon />
      </IconButton>
    </div>
  );
};
