import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../middleware/context-provider";
import { Logout } from "./logout";
import styles from "./account-menu.module.css";
import { SubscriptionForm } from "../../user/subscription-form";
import { RightsHandler } from "../../user/rights-handler";

export const AccountMenu: FC<{}> = (props) => {
  const state = useAppContext()[0];
  const [subscriptionFormVisible, setSubscriptionFormVisible] =
    useState<boolean>(false);
  const [rightsHandlerVisible, setRightsHandlerVisible] =
    useState<boolean>(false);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  useEffect(() => {
    if (state.userRole && state.userRole === "admin") {
      setIsAdmin(true);
    }
  }, [state.userRole]);
  if (!state.user) return <></>;
  if (!state.user.displayName) return <></>;

  const showSubscriptionForm = () => {
    setSubscriptionFormVisible(true);
  };
  const closeSubscriptionForm = () => {
    setSubscriptionFormVisible(false);
  };
  const showRightsHandler = () => {
    setRightsHandlerVisible(true);
  };
  const closeRightsHandler = () => {
    setRightsHandlerVisible(false);
  };
  return (
    <>
      <div className={styles["menu"]}>
        <div className={styles["menu-line"]}>
          <span>{state.user.displayName}</span>
        </div>
        <div className={styles["menu-line-btn"]}>
          <span>Editer le profil</span>
        </div>
        {isAdmin && (
          <>
            <div
              className={styles["menu-line-btn"]}
              onClick={showRightsHandler}
            >
              <span>Gestion des droits</span>
            </div>
            <div
              className={styles["menu-line-btn"]}
              onClick={showSubscriptionForm}
            >
              <span>Ajouter un utilisateur</span>
            </div>
          </>
        )}
        <Logout />
      </div>
      {rightsHandlerVisible && <RightsHandler onClose={closeRightsHandler} />}
      {subscriptionFormVisible && (
        <SubscriptionForm onClose={closeSubscriptionForm} />
      )}
    </>
  );
};
