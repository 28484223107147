export const roundValue = (value: number | undefined | null) => {
  if (!value) return;
  return Math.round(value * 1000) / 1000;
};

export const controlDataValue = (value: string) => {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else if (!isNaN(parseFloat(value))) {
    return parseFloat(value);
  } else {
    return value;
  }
};

export const intersectMaps = (
  map1: Map<string, Set<any>>,
  map2: Map<string, Set<any>>
): Map<string, Set<any>> => {
  let result = new Map();
  for (const [key, setOfValues1] of map1) {
    const setOfValues2 = map2.get(key);
    if (setOfValues2) {
      let intersect = new Set(
        [...setOfValues1].filter((value: any) => setOfValues2.has(value))
      );
      if (intersect.size > 0) {
        result.set(key, intersect);
      }
    }
  }
  return result;
};

export const unionMaps = (
  map1: Map<string, Set<any>>,
  map2: Map<string, Set<any>>
): Map<string, Set<any>> => {
  const unionMap = new Map<string, Set<any>>([...map1, ...map2]);
  for (const [key, value] of unionMap) {
    const setOfValues1 = map1.get(key) || new Set();
    const setOfValues2 = map2.get(key) || new Set();
    unionMap.set(key, new Set([...value, ...setOfValues1, ...setOfValues2]));
  }
  return unionMap;
};
