import { FC, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as OBC from "openbim-components";

import styles from "./menu-content.module.css";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import { useAppContext } from "../../../middleware/context-provider";
import { Navigate } from "react-router-dom";

export const SectionContent: FC = () => {
  const [state, dispatch] = useAppContext();
  const [clipperEnabled, setClipperEnabled] = useState<boolean>(false);

  const enableClippingPlane = () => {
    dispatch({ type: "TOGGLE_CLIPPER", payload: true });
    setClipperEnabled(true);
  };

  const disableClippingPlane = () => {
    dispatch({ type: "TOGGLE_CLIPPER", payload: false });
    setClipperEnabled(false);
  };

  const removeClipper = (clipper: OBC.EdgesPlane) => {
    dispatch({ type: "REMOVE_CLIPPER", payload: clipper });
  };

  if (!state.project) {
    return <Navigate to="/projects" />;
  }

  const activeClippers = state.activeClippers;

  return (
    <div className={styles["content"]}>
      <div className={styles["container-two-el-space"]}>
        {clipperEnabled ? (
          <>
            <IconButton onClick={disableClippingPlane}>
              <DoneIcon />
            </IconButton>
            <span className={styles["small-font"]}>
              Cliquer sur une face pour définir un plan de coupe
            </span>
          </>
        ) : (
          <IconButton onClick={enableClippingPlane}>
            <AddCircleOutlineIcon style={{ fontSize: 25, color: "#2d3e50" }} />
          </IconButton>
        )}
      </div>
      <hr />
      <div className={styles["scroll-content"]}>
        {activeClippers &&
          activeClippers.map((clipper, index) => {
            return (
              <div className={styles["list-item"]} key={index + 1}>
                <IconButton
                  onClick={() => {
                    removeClipper(clipper);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <span className={styles["margin-left"]}>{`Coupe_${
                  index + 1
                }`}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};
