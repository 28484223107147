import { User } from "firebase/auth";
import { getApp } from "firebase/app";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import { Events } from "../middleware/event-handler";

const t_users = "users";
export const adminDatabaseHandler = {
  getAllUsers: async (events: Events) => {
    const dbInstance = getFirestore(getApp());
    const userCollectionRef = collection(dbInstance, t_users);
    const querySnapshot = await getDocs(query(userCollectionRef));

    const listUsers: User[] = [];

    querySnapshot.forEach((doc) => {
      if (doc.data()) {
        listUsers.push({ ...(doc.data() as User), uid: doc.id });
      }
    });
    events.trigger({ type: "LOAD_ALL_USERS", payload: listUsers });
  },
};
