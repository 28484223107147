import { FC } from "react";
import { CoupleTypeNumberOfElem } from "../../../../types";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import styles from "./li-item.module.css";

export const TypeItem: FC<{
  coupleTypeNumberOfElem: CoupleTypeNumberOfElem;
  getBQByType: (typeName: string) => void;
}> = (props) => {
  return (
    <li
      key={props.coupleTypeNumberOfElem.typeName}
      className={`${styles["li-item"]} ${styles["margin-right"]}`}
    >
      <div className={styles["two-elements-sb"]}>
        <span>{props.coupleTypeNumberOfElem.typeName}</span>
        <span>{props.coupleTypeNumberOfElem.numberOfElem}</span>
      </div>
      <ManageSearchIcon
        className={styles["selectable"]}
        onClick={() => props.getBQByType(props.coupleTypeNumberOfElem.typeName)}
      />
    </li>
  );
};
