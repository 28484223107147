import { ChangeEvent, FC } from "react";
import styles from "./input-bar.module.css";

export const InputBar: FC<{
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}> = (props) => {
  const { placeholder } = props;
  return (
    <div className={styles["input-bar"]}>
      <input
        autoComplete="off"
        id="input"
        type="text"
        placeholder={placeholder}
        onChange={props.onInputChange}
      />
    </div>
  );
};
