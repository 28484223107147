import { ModelLocalDatabase } from "./dexie-utils";
import { getApp } from "firebase/app";
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
} from "firebase/storage";
import { ModelPropertiesToStore, Project } from "../../types";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { convertStoreDataToMapData } from "../utils/filter/filter-handler";

export class ModelDatabase {
  private db = new ModelLocalDatabase();
  async getModels(project: Project) {
    await this.db.open();
    const appInstance = getApp();
    const storageInstance = getStorage(appInstance);
    const urls: string[] = [];
    for (const model of project.models) {
      const url = await this.getModelURL(storageInstance, model.uid);
      urls.push(url);
    }
    return urls;
  }

  async deleteModels(ids: string[]) {
    await this.db.open();
    for (const id of ids) {
      if (this.idDataCached(id)) {
        localStorage.removeItem(id);
        await this.db.models.where("id").equals(id).delete();
      }
    }
    this.db.close();
  }

  private async getModelURL(instance: FirebaseStorage, id: string) {
    if (this.idDataCached(id)) {
      return this.getModelFromLocalCache(id);
    } else {
      return this.getModelFromFirebase(instance, id);
    }
  }

  private async getModelFromFirebase(instance: FirebaseStorage, id: string) {
    const fileRef = ref(instance, id);
    const url = await getDownloadURL(fileRef);
    await this.cacheModel(id, url);
    console.log("Get model from Firebase to local storage");
    return url;
  }

  private async getModelFromLocalCache(id: string) {
    const found = await this.db.models.where("id").equals(id).toArray();
    const file = found[0].file;
    console.log("model from local cache");
    return URL.createObjectURL(file);
  }

  private idDataCached(id: string) {
    const stored = localStorage.getItem(id);
    return stored !== null;
  }

  private async cacheModel(id: string, url: string) {
    const time = performance.now().toString();
    localStorage.setItem(id, time);
    const rawData = await fetch(url);
    const file = await rawData.blob();
    await this.db.models.add({
      id,
      file,
    });
  }

  async storeData(
    projectId: string,
    allData: ModelPropertiesToStore[],
    dataType: string
  ) {
    const dbInstance = getFirestore(getApp());
    const t_projects = "projects";
    try {
      console.log("here?");
      switch (dataType) {
        case "properties":
          await updateDoc(doc(dbInstance, t_projects, projectId), {
            allProperties: allData,
          });
          break;
        case "materials":
          await updateDoc(doc(dbInstance, t_projects, projectId), {
            allMaterials: allData,
          });
          break;
        default:
          break;
      }
      await this.cacheData(projectId, allData, dataType);
    } catch (error) {
      console.log(error);
    }
  }

  async getAllData(projectId: string, dataType: string) {
    if (this.idDataCached(projectId)) {
      return await this.getDataFromLocalCache(projectId, dataType);
    } else {
      return await this.getDataFromFirestore(projectId, dataType);
    }
  }

  private async getDataFromFirestore(id: string, dataType: string) {
    const dbInstance = getFirestore(getApp());
    const t_projects = "projects";
    const projectRef = doc(dbInstance, t_projects, id);
    const projectDoc = await getDoc(projectRef);
    const projectData = projectDoc.data();
    if (!projectData) return;
    switch (dataType) {
      case "properties":
        console.log("properties from Firestore");
        if (!projectData.allProperties) return;
        // this.cacheData(
        //   id,
        //   projectData.allProperties as ModelPropertiesToStore[],
        //   dataType
        // );
        const allProperties = convertStoreDataToMapData(
          projectData.allProperties
        );
        return allProperties;
      case "materials":
        console.log("materials from Firestore");
        if (!projectData.allMaterials) return;
        // this.cacheData(
        //   id,
        //   projectData.allMaterials as ModelPropertiesToStore[],
        //   dataType
        // );
        const allMaterials = convertStoreDataToMapData(
          projectData.allMaterials
        );
        return allMaterials;
      default:
        return;
    }
  }
  private async getDataFromLocalCache(id: string, dataType: string) {
    let found: any;
    switch (dataType) {
      case "properties":
        found = await this.db.properties.where("id").equals(id).toArray();
        break;
      case "materials":
        found = await this.db.materials.where("id").equals(id).toArray();
        break;
      default:
        return;
    }
    if (!found.length) return;
    const data = convertStoreDataToMapData(found[0].data);
    console.log("properties from local cache");
    return data;
  }

  private async cacheData(
    id: string,
    data: ModelPropertiesToStore[],
    dataType: string
  ) {
    const time = performance.now().toString();
    localStorage.setItem(id, time);
    switch (dataType) {
      case "properties":
        await this.db.properties.add({
          id,
          data: data,
        });
        break;
      case "materials":
        await this.db.materials.add({
          id,
          data: data,
        });
        break;
      default:
        break;
    }
  }

  // Already Use in db-handler
  // async deletePropertiesFromCache(id: string) {
  //   await this.db.open();
  //   if (this.idDataCached(id)) {
  //     localStorage.removeItem(id);
  //     await this.db.models.where("id").equals(id).delete();
  //   }
  //   this.db.close();
  // }
}
