import { Dexie } from "dexie";
import { ModelPropertiesToStore } from "../../types";

interface IModel {
  id: string;
  file: Blob;
}

interface IProperties {
  id: string;
  data: ModelPropertiesToStore[];
}

export class ModelLocalDatabase extends Dexie {
  models!: Dexie.Table<IModel, number>;
  properties!: Dexie.Table<IProperties, string>;
  materials!: Dexie.Table<IProperties, string>;

  constructor() {
    super("ModelDatabase");
    this.version(4).stores({
      models: "id, file",
      properties: "id, data",
      materials: "id, data",
    });
  }
}
