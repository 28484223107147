import { FC } from "react";
import { Class, Storey } from "../../../../types";
import { FooterWithButtons } from "../../../UI/footer-with-buttons";
import { Modal } from "../../../UI/Modal";
import { ModalTitle } from "../../../UI/text-components/modal-title";
import { useSelectionSection } from "../../../../hooks/use-selection-section";
import { ListSection } from "../../../UI/list-section/list-section";

export const AddBasicFilter: FC<{
  onClose: () => void;
  basicTypeList: (Storey | Class)[] | undefined;
  basicTypeName: string;
  onValidate: (selectedItemsName: string[], basicTypeName: string) => void;
}> = (props) => {
  const {
    dataListState: basicTypeListState,
    listDataName: listBasicTypeName,
    selectedDataIdentifier: selectedItemsName,
    checkBoxChangeHandler,
    searchChangeHandler,
  } = useSelectionSection(props.basicTypeList, true, true);

  if (!props.basicTypeList || !basicTypeListState) return <></>;

  return (
    <Modal onClose={props.onClose}>
      <ModalTitle text={`Liste des ${props.basicTypeName}`} />
      <ListSection
        dataListState={basicTypeListState}
        listDataName={listBasicTypeName}
        onCheckboxChange={checkBoxChangeHandler}
        onSearchChange={searchChangeHandler}
      />
      <FooterWithButtons
        secondButtonText="Valider"
        firstBtnClick={props.onClose}
        secondBtnClick={() =>
          props.onValidate(selectedItemsName, props.basicTypeName)
        }
      />
    </Modal>
  );
};
