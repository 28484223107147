import { ChangeEvent, FC, useEffect, useState } from "react";
import { FilterConditions } from "../../../../../../types";
import { CheckboxAll } from "../../../../../UI/list-section/checkbox-all";
import { ListItemWithCheckbox } from "../../../../../UI/list-section/list-item-with-checkbox";
import { InputBar } from "../../../../../UI/input-bar";
import { ModalTitle } from "../../../../../UI/text-components/modal-title";
import { RadioLogo } from "../radioLogo";

import styles from "./selection.module.css";

export const ValuesSelection: FC<{
  selectedPropertyName: string;
  onCheckBoxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onConditionChange: (conditionId: string) => void;
  conditionSelected: FilterConditions;
  listValues: any[];
  listValueNames: any[];
}> = (props) => {
  const {
    selectedPropertyName,
    onCheckBoxChange,
    onSearchValueChange,
    onConditionChange,
    conditionSelected,
    listValues,
    listValueNames,
  } = props;

  const [isNumeric, setIsNumeric] = useState<boolean>(false);

  useEffect(() => {
    const allValuesAreNumbers = Array.from(listValueNames).every(
      (value) => typeof value === "number"
    );
    if (allValuesAreNumbers && listValueNames.length) setIsNumeric(true);
  }, [listValueNames]);

  const contentClasses = `${styles["filter-content__block"]} ${styles["scroll-content"]}`;
  const classesLogosContainer = `${styles["filter-content__line"]} ${styles["center"]}`;

  const renderingSelectAllCheckBox = () => {
    const renderingCases = ["equal", "not-equal"];
    if (renderingCases.includes(conditionSelected)) {
      return (
        <CheckboxAll
          checkState={
            !listValues.some((basicType: any) => basicType?.isChecked !== true)
          }
          onCheckboxChange={onCheckBoxChange}
        />
      );
    }
  };

  const renderValuesList = () => {
    const renderingCases = ["equal", "not-equal"];
    if (renderingCases.includes(conditionSelected)) {
      return (
        <div className={contentClasses}>
          {listValueNames.map((valueName: any, index: number) => {
            let objectValue = listValues.find(
              (item) => item.value === valueName
            );
            objectValue.value = isNaN(objectValue.value)
              ? objectValue.value.toString()
              : objectValue.value;
            return (
              <ListItemWithCheckbox
                key={index}
                id={objectValue.value.toString()}
                name={objectValue.value.toString()}
                isChecked={objectValue.isChecked}
                onCheckBoxChange={onCheckBoxChange}
              />
            );
          })}
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <ModalTitle text={selectedPropertyName} />
      <div className={classesLogosContainer}>
        <RadioLogo
          label="="
          id="equal"
          defaultChecked={true}
          onChangeHandler={() => {
            onConditionChange("equal");
          }}
        />
        <RadioLogo
          label="≠"
          id="not-equal"
          defaultChecked={false}
          onChangeHandler={() => {
            onConditionChange("not-equal");
          }}
        />
        {isNumeric ? (
          <>
            <RadioLogo
              label=">"
              id="greater"
              defaultChecked={false}
              onChangeHandler={() => {
                onConditionChange("greater");
              }}
            />
            <RadioLogo
              label="<"
              id="lower"
              defaultChecked={false}
              onChangeHandler={() => {
                onConditionChange("lower");
              }}
            />
          </>
        ) : (
          <>
            <RadioLogo
              label="&sub;"
              id="includes"
              defaultChecked={false}
              onChangeHandler={() => {
                onConditionChange("includes");
              }}
            />
            <RadioLogo
              label="&nsub;"
              id="not-includes"
              defaultChecked={false}
              onChangeHandler={() => {
                onConditionChange("not-includes");
              }}
            />
          </>
        )}
      </div>
      {renderingSelectAllCheckBox()}
      <InputBar onInputChange={onSearchValueChange} placeholder="Rechercher" />
      {renderValuesList()}
    </>
  );
};
