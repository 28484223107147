import { FC } from "react";
import { useAppContext } from "../../../middleware/context-provider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { ShowSavedFiltersLogo } from "./show-saved-filters-logo";

import styles from "./saved-filters.module.css";
import { SavedFilter } from "../../../types";
import { useState } from "react";
import { useEffect } from "react";

export const SavedFilters: FC<{
  filtersVisible: boolean;
  toggleFiltersVisibility: () => void;
}> = (props) => {
  const { filtersVisible, toggleFiltersVisibility } = props;

  const [state, dispatch] = useAppContext();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  useEffect(() => {
    if (state.userRole && state.userRole === "admin") {
      setIsAdmin(true);
    }
  }, [state.userRole]);

  const displaySelectedFilter = (savedFilter: SavedFilter) => {
    dispatch({ type: "DISPLAY_SELECTED_FILTER", payload: savedFilter });
  };

  const deleteSavedFilter = (filterId: string) => {
    if (state.user && state.project) {
      dispatch({
        type: "DELETE_SAVED_FILTER",
        payload: {
          filterId,
          projectId: state.project.uid,
          userId: state.user.uid,
        },
      });
    }
  };

  const filtersContainerClass = filtersVisible
    ? `${styles["filters-container"]} ${styles["visible"]}`
    : `${styles["filters-container"]} ${styles["hidden"]}`;

  return (
    <>
      {!filtersVisible && (
        <ShowSavedFiltersLogo
          toggleFiltersVisibility={toggleFiltersVisibility}
        />
      )}
      <div className={filtersContainerClass}>
        <div className={styles["section-title"]}>
          <span>Filtres enregistrés</span>
          <IconButton onClick={toggleFiltersVisibility}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </div>
        <ul className={styles["scroll-content"]}>
          {state.savedFilters.map((savedFilter) => {
            return (
              <li className={styles.row} key={savedFilter.filterId}>
                <span>{savedFilter.filterName}</span>
                <div className={styles["logo-container"]}>
                  <IconButton
                    onClick={() => displaySelectedFilter(savedFilter)}
                  >
                    <ArrowOutwardIcon />
                  </IconButton>
                  {(isAdmin || (!isAdmin && !savedFilter.isGlobalFilter)) && (
                    <IconButton
                      onClick={() => deleteSavedFilter(savedFilter.filterId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
