import { ElementMaterial } from "../../types";
import * as WEB_IFC from "web-ifc";
import * as OBC from "openbim-components";

// En fonction du parent de materiaux (LayerSet, Constituent, list, etc), récupération des matériaux associés
// Entrée : materiaux parent id
// Sortie : list d'objet {name : matName, thikness: value}
export const getMaterialByMaterialParent = (
  modelProperties: any,
  relatedMaterial: any
) => {
  const elementMaterials: ElementMaterial[] = [];
  // ifc4 only
  if (relatedMaterial.MaterialConstituents) {
    return getMaterialByMaterialConstituentSet(
      modelProperties,
      relatedMaterial
    );
    // ifc 4 & 2x3
  } else if (relatedMaterial.Materials) {
    return getMaterialByMaterialList(modelProperties, relatedMaterial);
  } else if (relatedMaterial.MaterialLayers) {
    return getMaterialByMaterialLayerSet(modelProperties, relatedMaterial);
  } else if (relatedMaterial.ForLayerSet) {
    return getMaterialByMaterialLayerSetUsage(modelProperties, relatedMaterial);
  } else if (relatedMaterial.Name) {
    return getMaterial(relatedMaterial);
  }
  return elementMaterials;
};
const getMaterialByMaterialLayerSetUsage = (
  modelProperties: any,
  relatedMaterial: any
) => {
  const elementMaterials = [];
  const materialLayerSet = modelProperties[relatedMaterial.ForLayerSet.value];
  if (materialLayerSet.MaterialLayers) {
    for (const materialLayer of materialLayerSet.MaterialLayers) {
      const material = modelProperties[materialLayer.value];
      const materialThickness = material.LayerThickness?.value;
      const materialName = modelProperties[material.Material?.value].Name.value;
      elementMaterials.push({
        name: materialName,
        thickness: materialThickness,
      });
    }
  }
  return elementMaterials as ElementMaterial[];
};

const getMaterialByMaterialLayerSet = (
  modelProperties: any,
  relatedMaterial: any
) => {
  const elementMaterials: ElementMaterial[] = [];
  for (const materialLayer of relatedMaterial.MaterialLayers) {
    const material = modelProperties[materialLayer.value];
    const materialThickness = material.LayerThickness?.value;
    const materialName = getMaterialName(modelProperties, material.Material);
    elementMaterials.push({
      name: materialName,
      thickness: materialThickness,
    });
  }
  return elementMaterials;
};
const getMaterialByMaterialConstituentSet = (
  modelProperties: any,
  relatedMaterial: any
) => {
  const elementMaterials: ElementMaterial[] = [];
  for (const materialConstituentGroup of relatedMaterial.MaterialConstituents) {
    const materialConstituent =
      modelProperties[+materialConstituentGroup.value];
    const material = modelProperties[+materialConstituent.Material.value];
    let materialName: string;
    if (material.Material) {
      materialName = getMaterialName(modelProperties, material.Material);
    } else {
      materialName = material.Name.value;
    }

    elementMaterials.push({
      name: materialName,
      thickness: null,
    });
  }
  return elementMaterials;
};

const getMaterialByMaterialList = (
  modelProperties: any,
  relatedMaterial: any
) => {
  const elementMaterials: ElementMaterial[] = [];
  // Récupération des matériaux de la liste associés à des ifcMaterialLayer (qui ont une épaisseur)
  const allMaterialLayers = getAllMaterialLayers(modelProperties);
  for (const materialGroup of relatedMaterial.Materials) {
    const material = modelProperties[+materialGroup.value];
    for (const materialLayer of allMaterialLayers) {
      if (materialLayer.Material?.value === material.expressID) {
        const materialName = material.Name?.value;
        elementMaterials.push({
          name: materialName,
          thickness: materialLayer.LayerThickness?.value,
        });
        break;
      }
    }

    // Cas des matériaux de la liste non associés à des ifcMaterialLayer
    const isMaterialIncluded = elementMaterials.some(
      (matFromList) => matFromList.name === material.Name.value
    );
    if (!isMaterialIncluded) {
      elementMaterials.push({
        name: material.Name.value,
        thickness: null,
      });
    }
  }
  return elementMaterials;
};
const getMaterial = (relatedMaterial: any) => {
  const elementMaterials: ElementMaterial[] = [];
  const materialName = relatedMaterial.Name.value;
  elementMaterials.push({
    name: materialName,
    thickness: null,
  });
  return elementMaterials;
};

// Fonction qui récupère les matériaux par rapport à l'id d'un élément en passant par le type de l'élément
// Entrée : modelid, idElement
// Sortie : list d'objet {name : matName, thikness: value}
export const getMaterialTypeById = (modelProperties: any, elementType: any) => {
  let relatedMaterialId;
  const allRelAssociateMaterials = getAllRelAssociateMaterials(modelProperties);
  for (const relAssociateMaterial of allRelAssociateMaterials) {
    const allRelatedIds = [];
    for (const relatedObject of relAssociateMaterial.RelatedObjects) {
      allRelatedIds.push(relatedObject.value);
    }
    if (allRelatedIds.includes(elementType.expressID)) {
      relatedMaterialId = relAssociateMaterial.RelatingMaterial.value;
      break;
    }
  }
  const relatedMaterial = modelProperties[+relatedMaterialId];
  if (!relatedMaterial) return [];
  return getMaterialByMaterialParent(modelProperties, relatedMaterial);
};

const getMaterialName = (modelProperties: any, relatedMaterial: any) => {
  const material = modelProperties[+relatedMaterial.value];
  const materialName = material.Name.value;
  return materialName;
};

const getAllMaterialLayers = (modelProperties: any) => {
  const allMaterialLayers = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCMATERIALLAYER
  );
  return allMaterialLayers;
};

const getAllRelAssociateMaterials = (modelProperties: any) => {
  const allRelAssociateMaterials = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCRELASSOCIATESMATERIAL
  );
  return allRelAssociateMaterials;
};

export const getAllMAterialNames = (modelProperties: any) => {
  const allIfcMaterials = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCMATERIAL
  );

  const setOfAllMaterialNames = new Set<string>();
  for (const ifcMaterial of allIfcMaterials) {
    const materialName = ifcMaterial.Name.value;
    setOfAllMaterialNames.add(materialName);
  }
  return setOfAllMaterialNames;
};

export const getIdsElementByMaterialName = (
  modelProperties: any,
  materialName: string
) => {
  const matExpressId = getIfcMaterialId(modelProperties, materialName);

  const relatedIfcMaterialList = getMaterialListByMatId(
    modelProperties,
    matExpressId
  );
  const relatedIfcMaterialConstituent = getMaterialConstituentOrLayerByMatId(
    modelProperties,
    WEB_IFC.IFCMATERIALCONSTITUENT,
    matExpressId
  );
  const relatedIfcMaterialConstituentSet = getMaterialConstituentSetByMatId(
    modelProperties,
    relatedIfcMaterialConstituent
  );
  const relatedIfcMaterialLayer = getMaterialConstituentOrLayerByMatId(
    modelProperties,
    WEB_IFC.IFCMATERIALLAYER,
    matExpressId
  );
  const relatedIfcMaterialLayerSet = getMaterialLayerSetByMatId(
    modelProperties,
    relatedIfcMaterialLayer
  );
  const relatedIfcMaterialLayerSetUsage = getMaterialLayerSetUsageByMatId(
    modelProperties,
    relatedIfcMaterialLayerSet
  );

  const relatedMaterialClassesIds = [
    ...new Set([
      ...relatedIfcMaterialList,
      ...relatedIfcMaterialConstituent,
      ...relatedIfcMaterialConstituentSet,
      ...relatedIfcMaterialLayer,
      ...relatedIfcMaterialLayerSet,
      ...relatedIfcMaterialLayerSetUsage,
      ...[matExpressId],
    ]),
  ];

  let allElementsIdsFitting = new Set<number>();

  const fittingIds = getAllIdsByRelatedMaterial(
    modelProperties,
    relatedMaterialClassesIds
  );
  allElementsIdsFitting = new Set<number>([
    ...allElementsIdsFitting,
    ...fittingIds,
  ]);
  return allElementsIdsFitting;
};

const getIfcMaterialId = (modelProperties: any, materialName: string) => {
  const allIfcMaterials = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCMATERIAL
  );

  for (const ifcMaterial of allIfcMaterials) {
    if (ifcMaterial.Name.value === materialName) {
      return ifcMaterial.expressID;
    }
  }
};

// Get MaterialList who get ifcmaterial id
// sortie : [IFCMATERIALLISTFITTING]
const getMaterialListByMatId = (
  modelProperties: any,
  ifcMaterialId: number
) => {
  const materialListFitting = [];
  const allMaterialList = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCMATERIALLIST
  );
  for (const materialList of allMaterialList) {
    for (const material of materialList.Materials) {
      if (material.value === ifcMaterialId) {
        materialListFitting.push(materialList.expressID);
      }
    }
  }
  return materialListFitting;
};

// Get MaterialConstituent or layer who get ifcmaterial id
// sortie : [IFCMATERIALCONSTITUENTFITTING or IFCMATERIALLAYERFITTING]
const getMaterialConstituentOrLayerByMatId = (
  modelProperties: any,
  type: number,
  ifcMaterialId: number
) => {
  const materialConstituentFitting = [];
  const allMaterialConstituent = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    type
  );
  for (const materialConstituent of allMaterialConstituent) {
    if (materialConstituent.Material.value === ifcMaterialId) {
      materialConstituentFitting.push(materialConstituent.expressID);
    }
  }
  return materialConstituentFitting;
};

// Get MaterialLayerSet who have ifcmaterialLayerFitting
// sortie : [IFCMATERIALLAYERSETFITTING]
const getMaterialLayerSetByMatId = (
  modelProperties: any,
  materialLayerFitting: any[]
) => {
  const materialLayerSetFitting = [];
  const allMaterialLayerSet = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCMATERIALLAYERSET
  );

  for (const materialLayerSet of allMaterialLayerSet) {
    for (const materialLayer of materialLayerSet.MaterialLayers) {
      if (materialLayerFitting.includes(materialLayer.value)) {
        materialLayerSetFitting.push(materialLayerSet.expressID);
      }
    }
  }
  return materialLayerSetFitting;
};

// Get MaterialLayerSetUsage who have ifcmaterialLayerSetFitting
// sortie : [IFCMATERIALLAYERSETUSAGEFITTING]
const getMaterialLayerSetUsageByMatId = (
  modelProperties: any,
  materialLayerSetFitting: any[]
) => {
  const materialLayerSetUsageFitting = [];
  const allMaterialLayerSetUsage = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCMATERIALLAYERSETUSAGE
  );

  for (const materialLayerSetUsage of allMaterialLayerSetUsage) {
    const materialLayerSet = materialLayerSetUsage.ForLayerSet.value;
    if (materialLayerSetFitting.includes(materialLayerSet)) {
      materialLayerSetUsageFitting.push(materialLayerSetUsage.expressID);
    }
  }
  return materialLayerSetUsageFitting;
};

// Get MaterialLayerSet who have ifcmaterialLayerFitting
// sortie : [IFCMATERIALCONSTITUENTSETFITTING]
const getMaterialConstituentSetByMatId = (
  modelProperties: any,
  materialConstituentFitting: any[]
) => {
  const materialConstituentSetFitting = [];
  const allMaterialConstituentSet = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCMATERIALCONSTITUENTSET
  );
  for (const materialConstituentSet of allMaterialConstituentSet) {
    for (const materialConstituent of materialConstituentSet.MaterialConstituents) {
      if (materialConstituentFitting.includes(materialConstituent.value)) {
        materialConstituentSetFitting.push(materialConstituentSet.expressID);
      }
    }
  }
  return materialConstituentSetFitting;
};

//TODO : IFCMATERIALPROFILE && IFCMATERIALPROFILESET (exemple manquant)

const getAllIdsByRelatedMaterial = (
  modelProperties: any,
  relatedMaterialClassesIds: any[]
) => {
  const allRelAssociateMaterials = getAllRelAssociateMaterials(modelProperties);
  let allRelatedElementIds: Set<number> = new Set();
  for (const relAssociateMaterial of allRelAssociateMaterials) {
    const relatedMaterialId = relAssociateMaterial.RelatingMaterial.value;
    if (relatedMaterialClassesIds.includes(relatedMaterialId)) {
      const allRelatedObjToMaterial = relAssociateMaterial.RelatedObjects;
      for (const relatedObjectToMaterial of allRelatedObjToMaterial) {
        const relatedObject = modelProperties[+relatedObjectToMaterial.value];
        if (OBC.IfcCategoryMap[+relatedObject.type].includes("TYPE")) {
          const setOfElementIds = getAllRelDefinesByTypeId(
            modelProperties,
            relatedObjectToMaterial.value
          );
          allRelatedElementIds = new Set([
            ...allRelatedElementIds,
            ...setOfElementIds,
          ]);
        } else {
          allRelatedElementIds.add(relatedObjectToMaterial.value);
        }
      }
    }
  }
  return allRelatedElementIds;
};

const getAllRelDefinesByTypeId = (modelProperties: any, typeId: number) => {
  const allRelDefinesByType = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    WEB_IFC.IFCRELDEFINESBYTYPE
  );

  let setOfRelatedElementIds = new Set<number>();
  for (const relDefineByType of allRelDefinesByType) {
    const relatedTypeId = relDefineByType.RelatingType.value as number;
    if (relatedTypeId !== typeId) continue;
    const relatedObjects = relDefineByType.RelatedObjects;
    for (const relatedObject of relatedObjects) {
      setOfRelatedElementIds.add(relatedObject.value);
    }
  }

  return setOfRelatedElementIds;
};

export const getAllIdsByMaterial = (modelProperties: any) => {
  const allRelAssociateMaterials = getAllRelAssociateMaterials(modelProperties);
  const mapIdsByMaterial = new Map<string, Set<number>>();
  for (const relAssociateMaterial of allRelAssociateMaterials) {
    const relatedMaterialId = relAssociateMaterial.RelatingMaterial.value;
    const relatedMaterial = modelProperties[+relatedMaterialId];
    const listMaterialInfos = getMaterialByMaterialParent(
      modelProperties,
      relatedMaterial
    );

    const allRelatedIds = relAssociateMaterial.RelatedObjects.map(
      (relatedObject: any) => relatedObject.value
    );

    let allRelatedElementIds: Set<number> = new Set();
    for (const relatedId of allRelatedIds) {
      const relatedObject = modelProperties[+relatedId];
      const ifcClass = OBC.IfcCategoryMap[+relatedObject.type];
      if (ifcClass?.includes("TYPE")) {
        const setOfElementIds = getAllRelDefinesByTypeId(
          modelProperties,
          relatedId
        );
        allRelatedElementIds = new Set([
          ...allRelatedElementIds,
          ...setOfElementIds,
        ]);
      } else {
        allRelatedElementIds.add(relatedId);
      }
    }

    for (const materialInfos of listMaterialInfos) {
      const materialName = materialInfos.name;

      if (!mapIdsByMaterial.has(materialName)) {
        mapIdsByMaterial.set(materialName, allRelatedElementIds);
      } else {
        const prevRelatedElementIds = mapIdsByMaterial.get(materialName);
        if (prevRelatedElementIds) {
          mapIdsByMaterial.set(
            materialName,
            new Set<number>([...prevRelatedElementIds, ...allRelatedElementIds])
          );
        }
      }
    }
  }
  return mapIdsByMaterial;
};
