import { FragmentIdMap } from "openbim-components";
import { databaseHandler } from "../core/db-handler";
import { modelHandler } from "../core/models/model-handler";
import { Class, FilterQueryGroup, Role, SavedFilter, Storey } from "../types";
import { Action } from "./action";
import { Events } from "./event-handler";

export const executeCore = async (action: Action, events: Events) => {
  if (action.type === "LOGIN") {
    return databaseHandler.login(action, events);
  }
  if (action.type === "LOGOUT") {
    return databaseHandler.logout();
  }
  if (action.type === "SIGNUP") {
    return databaseHandler.signup(action);
  }
  if (action.type === "AFFECT_USERS_ROLE") {
    const listUserIds: string[] = action.payload.selectedUserIds;
    const role: Role = action.payload.userRole;
    return await databaseHandler.updateRole(listUserIds, role);
  }
  if (action.type === "ADD_PROJECT") {
    return databaseHandler.addProject(action, events);
  }
  if (action.type === "OPEN_USER_SPACE") {
    return databaseHandler.openUserSpace(action, events);
  }
  if (action.type === "DELETE_PROJECT") {
    const { projects, project, user } = action.payload;
    return databaseHandler.deleteProjectFromDb(projects, project, events);
  }
  if (action.type === "UPDATE_PROJECT") {
    const { project } = action.payload;
    return databaseHandler.updateProject(project, events);
  }
  if (action.type === "GET_PROJECT") {
    const projectId = action.payload;
    return databaseHandler.getProject(projectId, events);
  }
  if (action.type === "ADD_USER_TO_PROJECT") {
    return databaseHandler.addUserToProject(action, events);
  }
  if (action.type === "REMOVE_USER_FROM_PROJECT") {
    return databaseHandler.removeUserFromProject(action, events);
  }
  if (action.type === "START_VIEWER") {
    const { container, project } = action.payload;
    return modelHandler.start(container, project, events);
  }
  if (action.type === "CLOSE_VIEWER") {
    return modelHandler.remove();
  }
  if (action.type === "UPLOAD_MODEL") {
    const { listModelsUploaded, listFilesUploaded, project, user } =
      action.payload;
    const listZipFile = (await modelHandler.convertIfcToFragments(
      listFilesUploaded
    )) as File[];
    return databaseHandler.uploadModel(
      listModelsUploaded,
      listZipFile,
      project,
      events
    );
  }
  if (action.type === "DELETE_MODEL") {
    const { project, model } = action.payload;
    return databaseHandler.deleteModel(project, model, events);
  }
  if (action.type === "HANDLE_MODELS_VISIBILITY") {
    const selectedModelsName = action.payload;
    return modelHandler.handleModelsVisiblity(selectedModelsName);
  }
  if (action.type === "TOGGLE_CLIPPER") {
    return modelHandler.toggleClippingPlanes(action.payload);
  }
  if (action.type === "DELETE_ALL_CLIPPER") {
    return modelHandler.deleteAllClippingPlanes();
  }
  if (action.type === "REMOVE_CLIPPER") {
    return modelHandler.removeClipper(action.payload);
  }
  if (action.type === "TOGGLE_LENGTH_DIMENSION") {
    return modelHandler.toggleLengthDimension(action.payload);
  }
  if (action.type === "DELETE_ALL_LENGTH_DIMENSION") {
    const canvasElement = document.querySelector(
      "canvas"
    ) as HTMLCanvasElement | null;
    if (canvasElement) {
      canvasElement.style.cursor = "auto";
    }
    return modelHandler.deleteAllLengthDimension();
  }
  if (action.type === "REMOVE_LENGTH_DIMENSION") {
    return modelHandler.removeLengthDimension(action.payload);
  }
  if (action.type === "INIT_VIEWER") {
    return await modelHandler.initViewer();
  }
  if (action.type === "DESELECTED_ELEMENT") {
    return modelHandler.deselectElement();
  }
  if (action.type === "HIGHLIGHT_PARENT") {
    const parentId: number = action.payload.parentId;
    const modelId: string = action.payload.modelId;
    return modelHandler.highlightParentElement(parentId, modelId);
  }
  if (action.type === "SET_WHITE_MATERIAL") {
    return modelHandler.setUpWhiteMaterial();
  }
  if (action.type === "INIT_MATERIAL") {
    return modelHandler.initMaterial();
  }
  if (action.type === "TOGGLE_POSTPROD") {
    return modelHandler.setUpPostProduction(action.payload);
  }
  if (action.type === "TOGGLE_SPACES") {
    return modelHandler.toggleSpacesVisibility(action.payload);
  }
  if (action.type === "TOGGLE_BACKGROUND_SCENE") {
    return modelHandler.toggleBackgroundScene(action.payload);
  }
  if (action.type === "CHANGE_LANGUE") {
    return modelHandler.toggleLangue(action.payload);
  }
  if (action.type === "OPEN_PLAN_MODE") {
    return await modelHandler.getPlans();
  }
  if (action.type === "DISPLAY_PLAN") {
    return await modelHandler.displayPlan(action.payload);
  }
  if (action.type === "EXIT_PLAN_MODE") {
    return await modelHandler.exitPlanMode();
  }
  if (action.type === "EXPORT_DXF") {
    const planId = action.payload as string;
    return modelHandler.exportToDxf(planId);
  }
  if (action.type === "GET_FILTER_DATA") {
    return modelHandler.getFilterData();
  }
  if (action.type === "SEND_FILTER_DATA") {
    const listSelectedStoreysName: Storey[] = action.payload.storeys;
    const listSelectedClassesName: Class[] = action.payload.classes;
    const queriesGroupList: FilterQueryGroup[] =
      action.payload.queriesGroupList;
    return modelHandler.createFilter(
      listSelectedStoreysName,
      listSelectedClassesName,
      queriesGroupList
    );
  }
  if (action.type === "SEND_TYPENAME_FOR_RESULT_FILTER_BQ") {
    const typeName = action.payload.typeName;
    const filterResultBySelectedType =
      action.payload.filterResultBySelectedType;
    return modelHandler.getFilterBQByType(typeName, filterResultBySelectedType);
  }
  if (action.type === "SEND_COLOR_QUERY") {
    const selectedColor = action.payload.selectedColor;
    const queryGroup = action.payload.queryGroup;
    const filterResultIdMap = action.payload.resultIdMap;
    return await modelHandler.colorSelectedQuery(
      selectedColor,
      queryGroup,
      filterResultIdMap
    );
  }
  if (action.type === "CLOSE_RESULT_FILTER_DATA") {
    return modelHandler.removeColorHighlighter();
  }
  if (action.type === "REMOVE_COLORS") {
    return modelHandler.removeColorHighlighter();
  }
  if (action.type === "SHOW_ALL") {
    return await modelHandler.showAll();
  }
  if (action.type === "HIDE_SELECTED") {
    return modelHandler.hideSelected();
  }
  if (action.type === "ISOLATE_SELECTED") {
    const isolationType = action.payload;
    return modelHandler.isolateSelected(isolationType);
  }
  if (action.type === "SELECT_VISIBLE") {
    return await modelHandler.selectVisible();
  }
  if (action.type === "EXPORT_TO_EXCEL") {
    const elementsByType = action.payload.elementsByType;
    const propsToExport = action.payload.propsToExport;
    return await modelHandler.exportToExcel(elementsByType, propsToExport);
  }
  if (action.type === "VALIDATE_SINGLE_EDITION") {
    const editedProps = action.payload.editedProps;
    const selectedElementIdMap = action.payload.selectedElement;
    return await modelHandler.editSingleElement(
      selectedElementIdMap,
      editedProps
    );
  }
  if (action.type === "ACTIVATE_MULTI_SELECTION") {
    return modelHandler.toggleMultiSelection(action.payload);
  }
  if (action.type === "RESET_MULTI_SELECTION") {
    return modelHandler.resetMultiSelection();
  }
  if (action.type === "SEND_TYPENAME_FOR_MULTI_SELECT_BQ") {
    const typeName = action.payload.typeName;
    const elementsBySelectedType = action.payload.elementsBySelectedType;
    return modelHandler.getMultiSelectBQByType(
      typeName,
      elementsBySelectedType
    );
  }
  if (action.type === "SAVE_FILTER") {
    const title = action.payload.title;
    const isGlobalFilter = action.payload.globalFilter;
    const userId = action.payload.userId;
    const projectId = action.payload.projectId;
    const filterQueryGroupToSave: FilterQueryGroup[] =
      action.payload.filterQueryGroupToSave;
    const resultIdMap: FragmentIdMap = action.payload.resultIdMap;
    return await databaseHandler.saveFilter(
      title,
      isGlobalFilter,
      userId,
      projectId,
      filterQueryGroupToSave,
      resultIdMap,
      events
    );
  }
  if (action.type === "GET_SAVED_FILTERS") {
    const userId = action.payload.userId;
    const projectId = action.payload.projectId;
    return await databaseHandler.getSavedFilters(userId, projectId, events);
  }
  if (action.type === "DELETE_SAVED_FILTER") {
    const filterId = action.payload.filterId;
    const userId = action.payload.userId;
    const projectId = action.payload.projectId;
    return await databaseHandler.deleteFilter(
      projectId,
      userId,
      filterId,
      events
    );
  }
  if (action.type === "DISPLAY_SELECTED_FILTER") {
    const savedFilter: SavedFilter = action.payload;
    modelHandler.updateFilterStateWithSavedFilterData(savedFilter);
  }
};
