import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: "break-spaces",
          width: "95%",
          margin: "0px 0px 0px 5px",
          paddingBottom: "2px",
          paddingTop: "0px",
          autoFocus: false,
          "&:hover": {
            backgroundColor: "#ccc",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          margin: "0px 0px",
        },
      },
    },
  },
});
