import { FC, useState } from "react";
import { useAppContext } from "../../../middleware/context-provider";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import styles from "./menu-content.module.css";

export const PlanContent: FC = () => {
  const [state, dispatch] = useAppContext();
  const [activePlanId, setActivePlanId] = useState<string>("");

  const listPlans = state.listPlans;

  const displayPlan = (planId: string) => {
    dispatch({ type: "DISPLAY_PLAN", payload: planId });
    setActivePlanId(planId);
  };

  const exportPlanToDxf = () => {
    dispatch({ type: "EXPORT_DXF", payload: activePlanId });
  };

  const containerPlansClasses = `${styles["scroll-content"]} ${styles["plans-container"]}`;
  return (
    <>
      <div className={containerPlansClasses}>
        {listPlans.map((plan) => {
          return (
            <div
              key={plan.id}
              className={styles["plan-container"]}
              onClick={() => displayPlan(plan.id)}
            >
              <div className={styles["plan-info"]}>
                <span className={styles["title"]}>{plan.name}</span>
                <span className={styles["description"]}>
                  Hauteur : {plan.height}
                </span>
              </div>
              <ArrowOutwardIcon />
            </div>
          );
        })}
      </div>
      <div className={styles["plan-button-container"]}>
        <button className={styles["plan-button"]} onClick={exportPlanToDxf}>
          Exporter le plan en DXF
        </button>
      </div>
    </>
  );
};
