import { FC, useRef } from "react";
import Draggable from "react-draggable";
import { CardContentType, CardConfig } from "../../../types";
import { CardHeader } from "./card-header";

import stylesCard from "./card.module.css";

export const DraggableCard: FC<{
  cardProps: CardConfig;
  onCloseClick: (cardContentType: CardContentType) => void;
  onReduceClick: (cardContentType: CardContentType) => void;
  isReducted: boolean;
}> = (props) => {
  const nodeRef = useRef<any>(null);
  const { cardProps, isReducted } = props;
  const onReducedClickHandler = () => {
    props.onReduceClick(cardProps.type);
  };
  return (
    <>
      {!isReducted && (
        <Draggable
          bounds="parent"
          handle="strong"
          defaultPosition={cardProps.initialPosition}
          nodeRef={nodeRef}
        >
          <div ref={nodeRef} className={stylesCard.card}>
            <strong>
              <CardHeader
                title={cardProps.title}
                type={cardProps.type}
                mode={cardProps.mode}
                onCloseClick={props.onCloseClick.bind(cardProps.type)}
                onReduceClick={onReducedClickHandler}
              />
            </strong>
            {cardProps.children}
          </div>
        </Draggable>
      )}
    </>
  );
};
