import { FC } from "react";
import { IconButton } from "@mui/material";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import SaveIcon from "@mui/icons-material/Save";

import styles from "./save-export-footer.module.css";
import { useAppContext } from "../../../../middleware/context-provider";

export const SaveExportFooter: FC<{
  openExportExcel: () => void;
  openSaveResult: () => void;
}> = (props) => {
  const [state, dispatch] = useAppContext();

  const isAlreadySaved = state.filterRequest?.filterName !== undefined;

  return (
    <div className={styles["end"]}>
      {!isAlreadySaved && (
        <IconButton onClick={props.openSaveResult}>
          <SaveIcon />
        </IconButton>
      )}
      <IconButton onClick={props.openExportExcel}>
        <BackupTableIcon />
      </IconButton>
    </div>
  );
};
