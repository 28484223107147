import { FC, memo, useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";

import styles from "./project-item.module.css";
import { Project } from "../../types";
import { useAppContext } from "../../middleware/context-provider";
import { useEffect } from "react";

export const ProjectItem: FC<{
  project: Project;
}> = (props) => {
  const [state, dispatch] = useAppContext();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (state.userRole && state.userRole === "admin") {
      setIsAdmin(true);
    }
  }, [state.userRole]);

  return (
    <div className={styles["card"]}>
      <div className={styles["card-header"]}>
        <IconButton>
          {isAdmin && (
            <Link to={`/projectSettings/${props.project.uid}`}>
              <MoreVertIcon />
            </Link>
          )}
        </IconButton>
      </div>
      <Link
        className={styles["card-content"]}
        to={`/dataviz/${props.project.uid}`}
      >
        <span>{props.project.projectName}</span>
      </Link>
    </div>
  );
};
