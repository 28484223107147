import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../../middleware/context-provider";
import { AddProject } from "./add-project";
import { ProjectItem } from "./project-item";

import styles from "./projects.module.css";
export const Projects: FC = () => {
  const [state, dispatch] = useAppContext();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (state.user && !state.projects) {
      dispatch({ type: "OPEN_USER_SPACE", payload: state.user });
    }
  }, []);

  useEffect(() => {
    if (state.userRole && state.userRole === "admin") {
      setIsAdmin(true);
    }
  }, [state.userRole]);

  if (!state.user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className={styles["projects-content"]}>
        {state.projects &&
          state.projects.map((project) => (
            <ProjectItem key={project.uid} project={project} />
          ))}
        {isAdmin && <AddProject />}
      </div>
    </>
  );
};
