import * as OBC from "openbim-components";
import { FragmentIdMap, FragmentManager } from "openbim-components";
import { findFragmentModel } from "../fragmentIdMap-helpers";
import { translateValue } from "../translate-handler";
import IfcClassesTranslation from "../../../traduction/ifcClasses.json";

export const sortElementsByType = (
  fragments: FragmentManager,
  fragmentIdMap: FragmentIdMap,
  selectedLanguage: string
) => {
  const filterResultByTypeNumber = new Map<number, Map<string, Set<string>>>();
  for (const fragmentId in fragmentIdMap) {
    const model = findFragmentModel(fragments, fragmentId);
    if (model) {
      for (const expressId of fragmentIdMap[fragmentId]) {
        if (!expressId) continue;
        const typeNumber = +model.properties[expressId]?.type;
        if (!typeNumber) continue;
        if (!filterResultByTypeNumber.has(typeNumber)) {
          filterResultByTypeNumber.set(
            typeNumber,
            new Map<string, Set<string>>()
          );
        }
        const setOfIdsByModel = filterResultByTypeNumber.get(typeNumber);
        if (!setOfIdsByModel?.has(model.uuid)) {
          setOfIdsByModel?.set(model.uuid, new Set<string>());
        }
        setOfIdsByModel?.get(model.uuid)?.add(expressId);
      }
    }
  }
  const filterResultByType = new Map<string, Map<string, Set<string>>>();
  for (const [typeNumber, setofidsByModels] of filterResultByTypeNumber) {
    const ifcClass = OBC.IfcCategoryMap[typeNumber];
    const ifcClassTranslated = translateValue(
      selectedLanguage,
      IfcClassesTranslation,
      ifcClass
    );
    filterResultByType.set(ifcClassTranslated, setofidsByModels);
  }
  return filterResultByType;
};
