import { FC } from "react";
import styles from "./pset-title.module.css";

export const PsetTitle: FC<{ propName: string | number | boolean }> = (
  props
) => {
  return (
    <div
      className={styles["prop-line-title"]}
      key={`${props.propName} + ${Math.random()}`}
    >
      {props.propName}
    </div>
  );
};
