import { FragmentIdMap, FragmentManager } from "openbim-components";
import { ConvertFragmentIdMap } from "../../types";

export const subtractFragmentIdMaps = (
  map1: FragmentIdMap,
  map2: FragmentIdMap
): FragmentIdMap => {
  const result: FragmentIdMap = {};

  for (const fragmentID in map1) {
    if (map1.hasOwnProperty(fragmentID)) {
      const set1 = map1[fragmentID];
      const set2 = map2[fragmentID];

      if (set2) {
        // Create a new Set containing the difference between set1 and set2
        result[fragmentID] = new Set(
          Array.from(set1).filter((x) => !set2.has(x))
        );
      } else {
        // If the key is not present in map2, simply copy the Set from map1
        result[fragmentID] = new Set(Array.from(set1));
      }
    }
  }
  return result;
};

export const intersectFragmentIdMaps = (
  map1: FragmentIdMap,
  map2: FragmentIdMap
): FragmentIdMap => {
  const result: FragmentIdMap = {};

  if (Object.keys(map1).length === 0) {
    return {};
  }
  if (Object.keys(map2).length === 0) return {};
  for (const fragmentID in map1) {
    if (map1.hasOwnProperty(fragmentID) && map2.hasOwnProperty(fragmentID)) {
      const set1 = map1[fragmentID];
      const set2 = map2[fragmentID];

      // Create a new Set containing the intersection of set1 and set2
      result[fragmentID] = new Set(Array.from(set1).filter((x) => set2.has(x)));
    }
  }

  return result;
};

export const unionFragmentIdMaps = (
  map1: FragmentIdMap,
  map2: FragmentIdMap
): FragmentIdMap => {
  const result: FragmentIdMap = { ...map1 };

  for (const fragmentID in map2) {
    if (map2.hasOwnProperty(fragmentID)) {
      if (result.hasOwnProperty(fragmentID)) {
        // Convert the Sets to arrays, perform the union, and convert it back to a Set
        result[fragmentID] = new Set([
          ...Array.from(result[fragmentID]),
          ...Array.from(map2[fragmentID]),
        ]);
      } else {
        // If the key only exists in map2, copy the set to the result
        result[fragmentID] = new Set(map2[fragmentID]);
      }
    }
  }

  return result;
};

export const isFragmentIdMapEmpty = (map: FragmentIdMap): boolean => {
  return Object.keys(map).every((key) => map[key].size === 0);
};

export const toFragmentMap = (
  fragments: FragmentManager,
  data: Map<string, Set<number>>
) => {
  const fragmentMap: FragmentIdMap = {};
  data.forEach((matchingEntities, modelID) => {
    const model = fragments.groups.find((m) => m.uuid === modelID);
    if (!model) return;
    if (!matchingEntities) return;
    for (const expressID of Array.from(matchingEntities)) {
      const data = model.data[expressID as number];
      if (!data) continue;
      for (const key of data[0]) {
        const fragmentID = model.keyFragments[key];
        if (!fragmentMap[fragmentID]) {
          fragmentMap[fragmentID] = new Set();
        }
        fragmentMap[fragmentID].add(String(expressID));
      }
    }
  });
  return fragmentMap;
};

export const findFragmentModel = (
  fragments: FragmentManager,
  fragmentId: string
) => {
  let model: any;
  for (const group of fragments.groups) {
    const fragmentFound = Object.values(group.keyFragments).find(
      (id) => id === fragmentId
    );
    if (fragmentFound) model = group;
  }
  return model;
};

export const convertIdMapToArray = (fragmentIdMap: FragmentIdMap) => {
  const convertFragmentIdMap: ConvertFragmentIdMap = {};
  Object.keys(fragmentIdMap).forEach((fragmentID) => {
    convertFragmentIdMap[fragmentID] = Array.from(fragmentIdMap[fragmentID]);
  });
  return convertFragmentIdMap;
};

export const convertArrayToIdMap = (
  convertFragmentIdMap: ConvertFragmentIdMap
) => {
  const fragmentIdMap: FragmentIdMap = {};
  Object.keys(convertFragmentIdMap).forEach((fragmentID) => {
    fragmentIdMap[fragmentID] = new Set(convertFragmentIdMap[fragmentID]);
  });
  return fragmentIdMap;
};
