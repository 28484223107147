import { FC } from "react";

import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./list-item.module.css";

export const ListItemWithDelete: FC<{
  id: string;
  displayName: string;
  actionOnItem?: (itemId: string) => void;
}> = (props) => {
  const onRemoveItem = () => {
    if (props.actionOnItem) {
      props.actionOnItem(props.id);
    }
  };

  return (
    <li className={styles["li-item"]}>
      <span>{props.displayName}</span>
      <IconButton onClick={onRemoveItem}>
        <DeleteIcon />
      </IconButton>
    </li>
  );
};
