import { CardContentType, Tool } from "../../../types";
import CachedIcon from "@mui/icons-material/Cached";
import HomeIcon from "@mui/icons-material/Home";
import CropIcon from "@mui/icons-material/Crop";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import StraightenIcon from "@mui/icons-material/Straighten";
import SettingsIcon from "@mui/icons-material/Settings";
import { Action } from "../../../middleware/action";

export function getSidebarTools(
  dispatch: React.Dispatch<Action>,
  onMenuClick: (type: CardContentType) => void
): Tool[] {
  return [
    {
      name: "Réinitialiser",
      icon: <CachedIcon />,
      action: () => {
        dispatch({ type: "INIT_VIEWER" });
      },
    },
    {
      name: "Information sur le bâtiment",
      icon: <HomeIcon />,
      action: () => {
        onMenuClick("BuildingInfo");
      },
    },
    {
      name: "Section",
      icon: <CropIcon />,
      action: () => {
        onMenuClick("Section");
      },
    },
    {
      name: "Filtre multicritères",
      icon: <FilterAltIcon />,
      action: () => {
        onMenuClick("Filter");
      },
    },
    {
      name: "Mesure",
      icon: <StraightenIcon />,
      action: () => {
        onMenuClick("Measure");
      },
    },
    {
      name: "Paramètres",
      icon: <SettingsIcon />,
      action: () => {
        onMenuClick("Settings");
      },
    },
  ];
}
