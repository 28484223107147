import { FC, useEffect } from "react";

import styles from "./project-settings.module.css";
import { useAppContext } from "../../../middleware/context-provider";
import { ProjectSettingsContent } from "./project-settings-content";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { FooterWithButtons } from "../../UI/footer-with-buttons";
import { ModalTitle } from "../../UI/text-components/modal-title";

export const ProjectSettings: FC = () => {
  const [state, dispatch] = useAppContext();
  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    if (projectId) {
      dispatch({ type: "GET_PROJECT", payload: projectId });
    }
  }, []);

  const deleteProjectHandler = () => {
    if (!state.projects) return;
    if (!state.project) return;
    const projectToDelete = { ...state.project };
    let updatedProjectsList = [...state.projects];
    updatedProjectsList = updatedProjectsList.filter(
      (project) => project.uid !== projectToDelete.uid
    );
    dispatch({
      type: "DELETE_PROJECT",
      payload: {
        projects: updatedProjectsList,
        project: state.project,
        user: state.user,
      },
    });
    navigate("/projects");
  };

  if (!state.user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className={styles["settings-container"]}>
        <ModalTitle text="Paramètres du projet" />
        <ProjectSettingsContent />
        <FooterWithButtons
          twoButtonsFooter={false}
          secondButtonText="Supprimer"
          secondBtnClick={deleteProjectHandler}
        />
      </div>
    </>
  );
};
