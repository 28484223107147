import { FC } from "react";
import { ClassicButton } from "./classic-button";
import styles from "./footer-with-buttons.module.css";

export const FooterWithButtons: FC<{
  firstBtnClick?: () => void;
  secondBtnClick: () => void;
  twoButtonsFooter?: boolean;
  firstButtonText?: string;
  secondButtonText?: string;
  additionalSpan?: string;
}> = (props) => {
  const {
    twoButtonsFooter = true,
    firstButtonText = "Annuler",
    secondButtonText = "Valider",
    firstBtnClick = () => {},
    additionalSpan = "",
  } = props;
  return (
    <footer className={styles["filter-footer"]}>
      <span className={styles["additional-span"]}>{additionalSpan}</span>
      {twoButtonsFooter && (
        <ClassicButton text={firstButtonText} actionClick={firstBtnClick} />
      )}
      <ClassicButton
        text={secondButtonText}
        actionClick={props.secondBtnClick}
      />
    </footer>
  );
};
