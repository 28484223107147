import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  FilterConditions,
  FilterQuery,
  FilterQueryGroup,
  PropsAndValues,
} from "../../../../types";
import { FooterWithButtons } from "../../../UI/footer-with-buttons";
import { Modal } from "../../../UI/Modal";
import { ValuesSelection } from "./propertyFilter/selection/values-selection";
import { PropertySelection } from "./propertyFilter/selection/property-selection";

const categorizeProperties = (
  listProperties: any[]
): [PropsAndValues[], PropsAndValues[]] => {
  const numericProperties: PropsAndValues[] = [];
  const nonNumericProperties: PropsAndValues[] = [];

  for (const property of listProperties) {
    const allValuesAreNumbers = Array.from(property.values).every(
      (value) => typeof value === "number"
    );

    if (allValuesAreNumbers) {
      numericProperties.push(property);
    } else {
      nonNumericProperties.push(property);
    }
  }
  return [nonNumericProperties, numericProperties];
};

export const AddPropertyFilter: FC<{
  onClose: () => void;
  listPropAndValues: PropsAndValues[] | undefined;
  onValidate: (queryGroup: FilterQueryGroup) => void;
  listSelectedQueriesGroup: FilterQueryGroup[];
}> = (props) => {
  const [selectedPropertyName, setSelectedPropertyName] = useState<
    string | null
  >(null);
  const [listProperties, setListProperties] = useState<any[]>([]);
  const [listValues, setListValues] = useState<any[]>([]);
  const [listValueNames, setListValueNames] = useState<any[]>([]);

  const [selectedQueries, setSelectedQueries] = useState<FilterQuery[]>([]);
  const [operatorToChoose, setOperatorToChoose] = useState<boolean>(false);

  const [conditionSelected, setConditionSelected] =
    useState<FilterConditions>("equal");

  const [numericProperties, setNumericProperties] = useState<PropsAndValues[]>(
    []
  );
  const [nonNumericProperties, setNonNumericProperties] = useState<
    PropsAndValues[]
  >([]);
  useEffect(() => {
    if (props.listPropAndValues) {
      setListProperties(props.listPropAndValues);
    }
  }, []);
  useEffect(() => {
    if (listProperties) {
      const categorizedProperties = categorizeProperties(listProperties);
      setNonNumericProperties(categorizedProperties[0]);
      setNumericProperties(categorizedProperties[1]);
    }
  }, [listProperties]);

  const checkBoxPropChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedPropertyName(event.target.id);

      const setOfValues = listProperties.find(
        (property) => property.name === event.target.id
      ).values;
      setListValues(
        Array.from(setOfValues).map((value: any) => ({
          value: value as string | number | boolean,
          isChecked: true,
        }))
      );

      setListValueNames(
        Array.from(setOfValues).map(
          (value) => value as string | number | boolean
        )
      );

      fillSelectedQueriesWithAllValues(
        event.target.id,
        Array.from(setOfValues)
      );
    } else {
      setSelectedPropertyName(null);
      setSelectedQueries([]);
    }
  };

  const onConditionChange = (radioId: string) => {
    switch (radioId) {
      case "equal":
        setConditionSelected("equal");
        break;
      case "not-equal":
        setConditionSelected("not-equal");
        break;
      case "includes":
        setConditionSelected("includes");
        break;
      case "not-includes":
        setConditionSelected("not-includes");
        break;
      case "greater":
        setConditionSelected("greater");
        break;
      case "lower":
        setConditionSelected("lower");
        break;
      default:
        break;
    }
  };

  const checkBoxValuesChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!props.listPropAndValues) return;
    if (!selectedPropertyName) return;
    const isChecked = event.target.checked;
    const id = event.target.id;

    if (id === "all") {
      let tempList = listValues.map((valueObject) => {
        return { ...valueObject, isChecked: isChecked };
      });
      setListValues(tempList);
      if (isChecked) {
        // setListSelectedValues(listValues);
        fillSelectedQueriesWithAllValues(selectedPropertyName, listValueNames);
      } else {
        // setListSelectedValues([]);
        setSelectedQueries([]);
      }
    } else {
      let tempList = listValues.map((valueObject) =>
        valueObject.value.toString() === id
          ? { ...valueObject, isChecked: isChecked }
          : valueObject
      );
      setListValues(tempList);
      // récupération des éléments sélectionnés
      if (isChecked) {
        const query: FilterQuery = {
          propName: selectedPropertyName,
          propValue: id.toString(),
        };
        setSelectedQueries((prevSate) => [...prevSate, query]);
      } else {
        setSelectedQueries((prevState) => {
          const updatedState = prevState.filter(
            (item) => item.propValue.toString() !== id.toString()
          );
          return updatedState;
        });
      }
    }
  };

  const createQueriesGroup = (queries: FilterQuery[]) => {
    if (!queries.length) {
      initQuerySelection();
      return;
    }
    if (!props.listSelectedQueriesGroup.length) {
      const queriesGroup: FilterQueryGroup = {
        condition: conditionSelected,
        queries: queries,
      };
      props.onValidate(queriesGroup);
      initQuerySelection();
    } else {
      chooseOperator();
    }
  };

  const chooseOperator = () => {
    setOperatorToChoose(true);
  };

  const validateOperator = (queries: FilterQuery[], operator: string) => {
    if (operator === "OR") {
      props.onValidate({
        operator: "OR",
        condition: conditionSelected,
        queries,
      });
    } else {
      props.onValidate({
        operator: "AND",
        condition: conditionSelected,
        queries,
      });
    }
    initQuerySelection();
  };

  const initQuerySelection = () => {
    setSelectedPropertyName(null);
    setSelectedQueries([]);
    if (props.listPropAndValues) {
      setListProperties(props.listPropAndValues);
    }
    setOperatorToChoose(false);
    setConditionSelected("equal");
  };

  const canceledPropSelection = () => {
    setSelectedPropertyName(null);
    setListValues([]);
    setListValueNames([]);
    setSelectedQueries([]);
    if (!props.listPropAndValues) return;
    setListProperties(props.listPropAndValues);
    setConditionSelected("equal");
  };

  const searchPropChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!props.listPropAndValues) return;
    setListProperties(
      props.listPropAndValues.filter((propsAndValues) =>
        propsAndValues.name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    );
  };

  const searchValueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!props.listPropAndValues) return;
    if (!selectedPropertyName) return;

    const setOfValues = listProperties.find(
      (property) => property.name === selectedPropertyName
    ).values;

    setListValueNames(
      [...setOfValues].filter((value) =>
        value
          .toString()
          .toLowerCase()
          .includes(event.target.value.toString().toLowerCase())
      )
    );
    const conditionsWithInput = [
      "includes",
      "not-includes",
      "greater",
      "lower",
    ];
    if (conditionsWithInput.includes(conditionSelected)) {
      const query: FilterQuery = {
        propName: selectedPropertyName,
        propValue: event.target.value.toString().toLowerCase(),
      };
      setSelectedQueries([query]);
    }
  };

  const fillSelectedQueriesWithAllValues = (
    propName: string,
    listOfValues: any[]
  ) => {
    for (const value of listOfValues) {
      setSelectedQueries((prevState) => {
        let query: FilterQuery;
        query = {
          propName: propName,
          propValue: value as string | number | boolean,
        };
        return [...prevState, query];
      });
    }
  };

  return (
    <Modal onClose={props.onClose}>
      {selectedPropertyName ? (
        <ValuesSelection
          selectedPropertyName={selectedPropertyName}
          onConditionChange={onConditionChange}
          onCheckBoxChange={checkBoxValuesChangeHandler}
          onSearchValueChange={searchValueChangeHandler}
          conditionSelected={conditionSelected}
          listValues={listValues}
          listValueNames={listValueNames}
        />
      ) : (
        <PropertySelection
          onCheckBoxChange={checkBoxPropChangeHandler}
          onSearchPropChange={searchPropChangeHandler}
          nonNumericProperties={nonNumericProperties}
          numericProperties={numericProperties}
        />
      )}

      {!operatorToChoose ? (
        <FooterWithButtons
          secondButtonText={selectedPropertyName ? "Ajouter" : "Valider"}
          firstBtnClick={
            selectedPropertyName ? canceledPropSelection : props.onClose
          }
          secondBtnClick={
            selectedPropertyName
              ? () => createQueriesGroup(selectedQueries)
              : props.onClose
          }
        />
      ) : (
        <FooterWithButtons
          additionalSpan="(Condition) "
          firstButtonText="ET"
          secondButtonText="OU"
          firstBtnClick={() => validateOperator(selectedQueries, "AND")}
          secondBtnClick={() => validateOperator(selectedQueries, "OR")}
        />
      )}
    </Modal>
  );
};
