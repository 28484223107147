import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import { FC, useState } from "react";
import { useAppContext } from "../../../middleware/context-provider";
import { CardContentType, Tool } from "../../../types";
import styles from "./menu-content.module.css";
import getSettingsTools from "./settings-tools";

export const SettingsContent: FC<{
  settingsCheckboxStates: Record<string, boolean>;
  onSettingsCheckboxChange: (checkboxName: string) => void;
  onOpenPlanMenu: (cardContentType: CardContentType) => void;
  selectedLanguage: string;
  onLanguageChange: (selectedLanguage: string) => void;
}> = (props) => {
  const [state, dispatch] = useAppContext();

  const tools = getSettingsTools(dispatch);

  const checkboxsChange = (
    tool: Tool,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (tool.id) {
      props.onSettingsCheckboxChange(tool.id);
    }
    tool.action(event);
  };

  return (
    <div className={styles["content"]}>
      <div className={styles["two-logos"]}>
        <div className={styles["logo-container"]}>
          <input
            type="radio"
            id="fr"
            name="lang"
            defaultChecked={props.selectedLanguage === "fr"}
            onChange={() => props.onLanguageChange("fr")}
          />
          <label
            htmlFor="fr"
            className={`${styles["fr-flag"]} ${styles.logo}`}
          />
        </div>
        <div className={styles["logo-container"]}>
          <input
            type="radio"
            id="en"
            name="lang"
            defaultChecked={props.selectedLanguage === "en"}
            onChange={() => props.onLanguageChange("en")}
          />
          <label
            htmlFor="en"
            className={`${styles["eng-flag"]} ${styles.logo}`}
          />
        </div>
      </div>
      <div className={styles["scroll-content"]}>
        <ul className={styles["margin-left"]}>
          {tools.map((tool) => {
            if (!tool.id) return <></>;
            return (
              <li key={tool.id}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        id={tool.id}
                        checked={props.settingsCheckboxStates[tool.id] || false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => checkboxsChange(tool, event)}
                      />
                    }
                    label={tool.name}
                  />
                </FormGroup>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles["plan-button-container"]}>
        <button
          className={styles["plan-button"]}
          onClick={() => props.onOpenPlanMenu("Plan")}
        >
          Mode plan
        </button>
      </div>
    </div>
  );
};
