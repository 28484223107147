import { FC } from "react";
import { CardContentType, CardConfig } from "../../../types";
import { CardHeader } from "./card-header";
import stylesCard from "./card.module.css";

export const FixedCard: FC<{
  cardProps: CardConfig;
  onCloseClick: (cardContentType: CardContentType) => void;
  onReduceClick: (cardContentType: CardContentType) => void;
  isReducted: boolean;
}> = (props) => {
  const { cardProps, isReducted } = props;
  const cardClasses = `${stylesCard.card} ${stylesCard.menu}`;
  return (
    <>
      {!isReducted && (
        <div className={cardClasses}>
          <CardHeader
            title={cardProps.title}
            type={cardProps.type}
            mode={cardProps.mode}
            onCloseClick={props.onCloseClick.bind(cardProps.type)}
            onReduceClick={props.onReduceClick.bind(cardProps.type)}
          />
          {cardProps.children}
        </div>
      )}
    </>
  );
};
