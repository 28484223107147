import { IconButton } from "@mui/material";
import { FC } from "react";
import { useAppContext } from "../../../middleware/context-provider";
import { CardContentType } from "../../../types";
import { getSidebarTools } from "./sidebar-tools";
import styles from "./sidebar.module.css";

export const Sidebar: FC<{
  onMenuClick: (cardContentType: CardContentType) => void;
}> = (props) => {
  const { onMenuClick } = props;
  const [state, dispatch] = useAppContext();
  const tools = getSidebarTools(dispatch, onMenuClick);

  return (
    <aside className={styles.sidebar}>
      <ul className={styles["nav-list"]}>
        {tools.map((tool) => {
          return (
            <li key={tool.name}>
              <IconButton style={{ color: "white" }} onClick={tool.action}>
                {tool.icon}
              </IconButton>
              <span className={styles.tooltip}>{tool.name}</span>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
