import { FC, useEffect, useState } from "react";
import { useSelectionSection } from "../../../../hooks/use-selection-section";
import { useAppContext } from "../../../../middleware/context-provider";
import { FooterWithButtons } from "../../../UI/footer-with-buttons";
import { ListSection } from "../../../UI/list-section/list-section";
import { Modal } from "../../../UI/Modal";
import { ModalTitle } from "../../../UI/text-components/modal-title";
import styles from "./export-excel-content.module.css";

const defaultPropToExport = [
  {
    displayName: "Nom",
    uid: "Nom",
  },
  {
    displayName: "Catégorie",
    uid: "Catégorie",
  },
  {
    displayName: "Edifice",
    uid: "Edifice",
  },
  {
    displayName: "Niveau",
    uid: "Niveau",
  },
  {
    displayName: "GlobalID",
    uid: "GlobalID",
  },
  {
    displayName: "Quantité de bases",
    uid: "Quantité de bases",
  },
];

export const ExportExcelContent: FC<{
  elementsByType: Map<string, Map<string, Set<string>>>;
  onClose: () => void;
}> = (props) => {
  const [state, dispatch] = useAppContext();
  const [listProps, setListProps] = useState<any[]>([]);
  const defaultPropsNameToExport = defaultPropToExport.map(
    (prop) => prop.displayName
  );
  const [allPropsNameToExport, setAllPropsNameToExport] = useState<string[]>(
    defaultPropsNameToExport
  );

  const {
    dataListState: listPropsState,
    listDataName: listPropsName,
    selectedDataIdentifier: selectedPropsName,
    checkBoxChangeHandler,
    searchChangeHandler,
  } = useSelectionSection(listProps, false, true);

  useEffect(() => {
    if (!state.filterData) {
      dispatch({ type: "GET_FILTER_DATA" });
    }
  }, []);

  useEffect(() => {
    if (state.filterData) {
      const listPropAndValues = state.filterData?.get("properties");
      if (listPropAndValues) {
        setListProps(
          listPropAndValues.map((propAndValues) => {
            return { displayName: propAndValues.name, uid: propAndValues.name };
          })
        );
      }
    }
  }, [state.filterData]);

  useEffect(() => {
    setAllPropsNameToExport([
      ...defaultPropsNameToExport,
      ...selectedPropsName,
    ]);
  }, [selectedPropsName]);

  const validateExport = () => {
    dispatch({
      type: "EXPORT_TO_EXCEL",
      payload: {
        elementsByType: props.elementsByType,
        propsToExport: selectedPropsName,
      },
    });

    props.onClose();
  };

  if (!listPropsState || !state.filterData) return <></>;
  return (
    <Modal onClose={props.onClose}>
      <ModalTitle text="Choix des propriétés à exporter" />
      <ListSection
        dataListState={listPropsState}
        listDataName={listPropsName}
        onCheckboxChange={checkBoxChangeHandler}
        onSearchChange={searchChangeHandler}
      />

      <div className={styles["subtitle"]}>
        <strong>Aperçu de l'export</strong>
      </div>
      <div className={styles["preview-container"]}>
        <table className={styles["table-preview"]}>
          <thead>
            <tr>
              {allPropsNameToExport.map((name) => (
                <th className={styles["table-header"]} key={name}>
                  {name}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>

      <FooterWithButtons
        secondButtonText="Exporter"
        firstBtnClick={props.onClose}
        secondBtnClick={validateExport}
      />
    </Modal>
  );
};
