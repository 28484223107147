import { FC } from "react";
import {
  CardConfig,
  CardContentType,
  ReductedPositionState,
} from "../../../types";
import { Onglet } from "./onglet";
import styles from "./onglet-container.module.css";
export const OngletContainer: FC<{
  reductedPositionState: ReductedPositionState;
  cardContentConfig: CardConfig[];
  toggleReduction: (cardContentType: CardContentType) => void;
}> = (props) => {
  const { reductedPositionState, cardContentConfig, toggleReduction } = props;
  return (
    // Ordre alphabétique
    <div className={styles["onglet-container"]}>
      {reductedPositionState.buildingInfoWindow && (
        <Onglet
          text={cardContentConfig[8].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[8].type}
        />
      )}
      {reductedPositionState.filterWindow && (
        <Onglet
          text={cardContentConfig[5].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[5].type}
        />
      )}
      {reductedPositionState.measureWindow && (
        <Onglet
          text={cardContentConfig[1].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[1].type}
        />
      )}
      {reductedPositionState.selectedElementsWindow && (
        <Onglet
          text={cardContentConfig[7].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[7].type}
        />
      )}
      {reductedPositionState.planWindow && (
        <Onglet
          text={cardContentConfig[4].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[4].type}
        />
      )}
      {reductedPositionState.settingsWindow && (
        <Onglet
          text={cardContentConfig[3].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[3].type}
        />
      )}
      {reductedPositionState.propsWindow && (
        <Onglet
          text={cardContentConfig[2].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[2].type}
        />
      )}
      {reductedPositionState.filterResultWindow && (
        <Onglet
          text={cardContentConfig[6].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[6].type}
        />
      )}
      {reductedPositionState.sectionWindow && (
        <Onglet
          text={cardContentConfig[0].title}
          onTabClick={toggleReduction}
          type={cardContentConfig[0].type}
        />
      )}
    </div>
  );
};
