import { ChangeEvent, FC } from "react";
import { CheckboxAll } from "./checkbox-all";
import { ListItemWithCheckbox } from "./list-item-with-checkbox";
import { InputBar } from "../input-bar";

import styles from "./list-section.module.css";

export const ListSection: FC<{
  dataListState: any[];
  listDataName: string[];
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  const usersContentClasses = `${styles["section-content__block"]} ${styles["scroll-content"]}`;
  return (
    <>
      <CheckboxAll
        checkState={
          !props.dataListState.some((datum: any) => datum?.isChecked !== true)
        }
        onCheckboxChange={props.onCheckboxChange}
      />
      <InputBar onInputChange={props.onSearchChange} placeholder="Rechercher" />
      <div className={usersContentClasses}>
        {props.listDataName.map((name: string, index: number) => {
          const dataToAdd = props.dataListState.find(
            (datum) => datum.displayName === name
          );
          return (
            <ListItemWithCheckbox
              key={index}
              id={dataToAdd.uid}
              name={dataToAdd.displayName}
              isChecked={dataToAdd?.isChecked || false}
              onCheckBoxChange={props.onCheckboxChange}
            />
          );
        })}
      </div>
    </>
  );
};
