import { ChangeEvent, useEffect, useState } from "react";

export const useSelectionSection = (
  inputData: any,
  defaultCheck: boolean,
  forName: boolean
) => {
  const [dataListState, setDataListState] = useState<any[] | undefined>([]);
  const [listDataName, setListDataName] = useState<string[]>([]);
  const [selectedDataIdentifier, setSelectedDataIdentifier] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!inputData) return;
    if (inputData.length) {
      setDataListState(
        inputData.map((datum: any) => ({
          ...datum,
          isChecked: defaultCheck,
        }))
      );
      if (defaultCheck) {
        if (forName) {
          setSelectedDataIdentifier(
            inputData.map((datum: any) => datum.displayName)
          );
        } else {
          setSelectedDataIdentifier(inputData.map((datum: any) => datum.uid));
        }
      } else {
        setSelectedDataIdentifier([]);
      }
      setListDataName(inputData.map((datum: any) => datum.displayName));
    }
  }, [inputData]);

  const checkBoxChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!inputData || !dataListState) return;
    const isChecked = event.target.checked;
    const id = event.target.id;
    if (id === "all") {
      let tempList = dataListState.map((datum) => {
        return { ...datum, isChecked: isChecked };
      });
      setDataListState(tempList);
      if (isChecked) {
        if (forName) {
          setSelectedDataIdentifier(
            dataListState.map((datum) => datum.displayName)
          );
        } else {
          setSelectedDataIdentifier(dataListState.map((datum) => datum.uid));
        }
      } else {
        setSelectedDataIdentifier([]);
      }
    } else {
      let tempList = dataListState.map((datum) =>
        datum.uid === id ? { ...datum, isChecked: isChecked } : datum
      );
      setDataListState(tempList);
      // récupération des éléments sélectionnés
      if (isChecked) {
        setSelectedDataIdentifier((prevState: string[]) => [...prevState, id]);
      } else {
        setSelectedDataIdentifier((prevState: string[]) => {
          const updatedState = prevState.filter((item) => item !== id);
          return updatedState;
        });
      }
    }
  };

  const searchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!inputData || !dataListState) return;
    const listAllDataName = dataListState.map((datum) => datum.displayName);
    setListDataName(
      listAllDataName?.filter((name) =>
        name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };

  return {
    dataListState,
    listDataName,
    selectedDataIdentifier,
    checkBoxChangeHandler,
    searchChangeHandler,
  };
};
