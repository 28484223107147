import { FC, useEffect, useRef, useState } from "react";
import { BuildingInfoContent } from "../menu-content/building-info-content";
import { SectionContent } from "../menu-content/section-content";
import { FixedCard } from "../UI/fixed-card";
import { Contextmenu } from "../context-menu/context-menu";
import { MeasureContent } from "../menu-content/measure-content";
import { getCardContentConfig } from "../UI/card-content-config";
import { CardContentType, ReductedPositionState } from "../../../types";
import classes from "./viewport.module.css";
import { useAppContext } from "../../../middleware/context-provider";
import { PropsContent } from "../result-content/properties-content/props-content";
import { DraggableCard } from "../UI/draggable-card";
import { SettingsContent } from "../menu-content/settings-content";
import { PlanContent } from "../menu-content/plan-content";
import { FilterContent } from "../menu-content/filter/filter-content";
import { RightClick } from "../utils/right-click";
import { ResultFilter } from "../result-content/result-filter/result-filter-content";
import { Onglet } from "../onglet/onglet";
import { OngletContainer } from "../onglet/onglet-container";
import { MultiSelectionContent } from "../result-content/multi-selection/multi-selection-content";

export const Viewport: FC<{
  onToggleMenu: (cardContentType: CardContentType) => void;
  onCloseResultWindow: (cardContentType: CardContentType) => void;
  sectionMenu: boolean;
  measureMenu: boolean;
  filterMenu: boolean;
  settingsMenu: boolean;
  settingsCheckboxStates: Record<string, boolean>;
  onSettingsCheckboxChange: (checkboxName: string) => void;
  selectedLanguage: string;
  onLanguageChange: (selectedLanguage: string) => void;
  planMode: boolean;
  buildingInfoMenu: boolean;
  toggleReduction: (cardContentType: CardContentType) => void;
  reductedPositionState: ReductedPositionState;
}> = (props) => {
  const [state, dispatch] = useAppContext();
  const containerRef = useRef(null);
  const [rightClick, setRightClick] = useState<RightClick | null>(null);

  const { toggleReduction, reductedPositionState } = props;
  useEffect(() => {
    const container = containerRef.current;
    if (container && state.project) {
      dispatch({
        type: "START_VIEWER",
        payload: { container, project: state.project },
      });

      if (container) {
        const rightClickInstance = new RightClick(container);
        setRightClick(rightClickInstance);
      }
    }

    return () => {
      dispatch({
        type: "CLOSE_VIEWER",
        payload: state.projects,
      });
    };
  }, [state.project]);
  // const [cardActive, setCardActive] = useState<boolean>(false);

  useEffect(() => {
    if (state.project && state.user) {
      dispatch({
        type: "GET_SAVED_FILTERS",
        payload: { projectId: state.project.uid, userId: state.user.uid },
      });
    }
  }, [state.project, state.user]);

  const cardContentConfig = getCardContentConfig();
  cardContentConfig[0].children = <SectionContent />;
  cardContentConfig[1].children = <MeasureContent />;
  cardContentConfig[2].children = <PropsContent />;
  cardContentConfig[3].children = (
    <SettingsContent
      settingsCheckboxStates={props.settingsCheckboxStates}
      onSettingsCheckboxChange={props.onSettingsCheckboxChange}
      selectedLanguage={props.selectedLanguage}
      onLanguageChange={props.onLanguageChange}
      onOpenPlanMenu={props.onToggleMenu}
    />
  );
  cardContentConfig[4].children = <PlanContent />;
  cardContentConfig[5].children = (
    <FilterContent onCloseFilterMenu={props.onToggleMenu} />
  );
  cardContentConfig[6].children = <ResultFilter />;
  cardContentConfig[7].children = <MultiSelectionContent />;
  cardContentConfig[8].children = <BuildingInfoContent />;

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!rightClick) return;
    let isLongClick: boolean = false;
    if (rightClick.start !== undefined && rightClick.stop !== undefined) {
      const timeMoving = rightClick.stop - rightClick.start;
      isLongClick = timeMoving > 300;
    }
    if (isLongClick) return;
    setContextMenu((prevContextMenuState) => {
      if (prevContextMenuState === null) {
        return {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        };
      } else {
        return null;
      }
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <>
      {props.buildingInfoMenu && (
        <FixedCard
          cardProps={cardContentConfig[8]}
          onCloseClick={props.onToggleMenu}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.buildingInfoWindow}
        />
      )}
      {props.sectionMenu && (
        <FixedCard
          cardProps={cardContentConfig[0]}
          onCloseClick={props.onToggleMenu}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.sectionWindow}
        />
      )}
      {props.measureMenu && (
        <FixedCard
          cardProps={cardContentConfig[1]}
          onCloseClick={props.onToggleMenu}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.measureWindow}
        />
      )}
      {props.settingsMenu && (
        <FixedCard
          cardProps={cardContentConfig[3]}
          onCloseClick={props.onToggleMenu}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.settingsWindow}
        />
      )}
      {props.planMode && (
        <FixedCard
          cardProps={cardContentConfig[4]}
          onCloseClick={props.onToggleMenu}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.planWindow}
        />
      )}
      {props.filterMenu && (
        <FixedCard
          cardProps={cardContentConfig[5]}
          onCloseClick={props.onToggleMenu}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.filterWindow}
        />
      )}
      {state.elementProps.length > 0 && (
        <DraggableCard
          cardProps={cardContentConfig[2]}
          onCloseClick={props.onCloseResultWindow}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.propsWindow}
        />
      )}
      {state.filterResultData?.filterResultByType && (
        <DraggableCard
          cardProps={cardContentConfig[6]}
          onCloseClick={props.onCloseResultWindow}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.filterResultWindow}
        />
      )}
      {state.multiSelectionData?.globalIds.size && (
        <DraggableCard
          cardProps={cardContentConfig[7]}
          onCloseClick={props.onCloseResultWindow}
          onReduceClick={toggleReduction}
          isReducted={reductedPositionState.selectedElementsWindow}
        />
      )}

      <div
        className={classes["full-screen"]}
        onContextMenuCapture={handleContextMenu}
        ref={containerRef}
      >
        <Contextmenu
          onContextMenuClose={handleClose}
          contextMenu={contextMenu}
        />
      </div>
      <OngletContainer
        cardContentConfig={cardContentConfig}
        reductedPositionState={reductedPositionState}
        toggleReduction={toggleReduction}
      />
    </>
  );
};
