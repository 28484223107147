import * as OBC from "openbim-components";
import * as XLSX from "xlsx";
import { reverseTranslateValue, translateValue } from "../translate-handler";
import IfcClassesTranslation from "../../../traduction/ifcClasses.json";
import IfcPropertiesTranslation from "../../../traduction/ifcProperties.json";
import { FragmentIdMap } from "openbim-components";
import { roundValue } from "../general-helpers";
import { ElementMaterial } from "../../../types";
import {
  getMaterialByMaterialParent,
  getMaterialTypeById,
} from "../material-handler";

// recupération de toutes les données à afficher pour un élément sélectionné
export const getAllPropsOfSelectedElement = (
  model: any,
  propertiesProcessor: OBC.IfcPropertiesProcessor,
  selectedElementIdMap: FragmentIdMap,
  selectedLanguage: string,
  factorUnits: number
) => {
  for (const key in selectedElementIdMap) {
    if (Object.prototype.hasOwnProperty.call(selectedElementIdMap, key)) {
      // selectedExpressId
      const [expressId] = selectedElementIdMap[key];
      if (!model) return;
      if (!model.properties) return;

      let result;
      for (const elementId in model.properties) {
        if (elementId !== expressId) continue;
        result = model.properties[+elementId];
        break;
      }
      if (!result) return;
      // element infos
      const elementInfos = getElementInfos(
        result,
        model.uuid,
        selectedLanguage
      );
      const props = propertiesProcessor.getProperties(model, expressId);
      if (!props) return;
      addPredifinedTypeToElementInfos(props, elementInfos, selectedLanguage);

      // Psets & BQs
      let psetTitleCount = 1;
      const elementPropAndBQ = new Map();
      let elementMaterials: any[] = [];
      const materialClasses = [
        "IFCMATERIAL",
        "IFCMATERIALLIST",
        "IFCMATERIALLAYERSETUSAGE",
        "IFCMATERIALCONSTITUENTSET",
        "IFCMATERIALLAYERSET",
      ];
      const materialConstituentBQ: ElementMaterial[] = [];
      for (const groupProp of props) {
        const propClass = OBC.IfcCategoryMap[+groupProp.type];
        // Psets
        if (groupProp.HasProperties) {
          elementPropAndBQ.set(psetTitleCount, groupProp.Name.value);
          psetTitleCount += 1;
          getElementPsets(groupProp, elementPropAndBQ, selectedLanguage);
          // BQs
        } else if (groupProp.Quantities) {
          elementPropAndBQ.set(psetTitleCount, groupProp.Name.value);
          psetTitleCount += 1;
          const quantityGroupExpressId = groupProp.expressID;
          // listQuantityIds contient à la fois les BQ et aussi les épaisseurs des matériaux
          const listQuantityIds = OBC.IfcPropertiesUtils.getQsetQuantities(
            model.properties,
            +quantityGroupExpressId
          );
          if (!listQuantityIds) continue;
          for (const quantityID of listQuantityIds) {
            const quantityType =
              OBC.IfcCategoryMap[+model.properties[quantityID].type];
            if (quantityType !== "IFCPHYSICALCOMPLEXQUANTITY") {
              getBQFromQuantityId(
                model.properties,
                quantityID,
                elementPropAndBQ,
                selectedLanguage,
                factorUnits
              );
            } else {
              getMaterialBQByQuantityId(
                model,
                quantityID,
                materialConstituentBQ
              );
            }
          }
          // Materiaux
        } else if (materialClasses.includes(propClass)) {
          const relatedMaterials = model.properties[+groupProp.expressID];
          elementMaterials = getMaterialByMaterialParent(
            model.properties,
            relatedMaterials
          );
        }
      }
      // Si materialConstituentBQ, ça veut dire que les épaisseurs sont indiquées : on écrase
      if (materialConstituentBQ.length) {
        elementMaterials = materialConstituentBQ;
      }
      // Si aucun materiaux récupérés, on regarde au niveau de l'elementType pour remonter aux éventuels matériaux
      if (elementMaterials.length === 0) {
        if (!props) return;
        for (const groupProp of props) {
          // récupération de l'elementType
          if (OBC.IfcCategoryMap[+groupProp.type].includes("TYPE")) {
            elementMaterials = getMaterialTypeById(model.properties, groupProp);
            break;
          }
        }
      }

      // Element parent infos
      const elementParentInfos = getElementParentInfos(
        model,
        expressId,
        selectedLanguage
      );

      const elementProps: (Map<any, any> | ElementMaterial[])[] = [];
      elementProps.push(elementInfos);
      elementProps.push(elementPropAndBQ);
      elementProps.push(elementMaterials);
      elementProps.push(elementParentInfos);
      return elementProps;
    }
    break;
  }
};

// récupération des infos générales d'un élément (depuis sa "ligne ifc")
export const getElementInfos = (
  elementResult: any,
  modelID: string,
  selectedLanguage: string
) => {
  const elementInfos = new Map<string, string | number>();
  if (!elementResult) return new Map();
  elementInfos.set("ModelId", modelID);
  elementInfos.set("GUID", elementResult.GlobalId.value);
  elementInfos.set("ExpressId", elementResult.expressID);

  const name = translateValue(
    selectedLanguage,
    IfcPropertiesTranslation,
    "Name"
  );
  elementInfos.set(name, elementResult.Name.value);

  const typeNumber = elementResult?.type;
  const typeName = translateValue(
    selectedLanguage,
    IfcClassesTranslation,
    OBC.IfcCategoryMap[typeNumber]
  );

  const classTitle = translateValue(
    selectedLanguage,
    IfcPropertiesTranslation,
    "Class"
  );

  elementInfos.set(classTitle, typeName);
  return elementInfos;
};

// // récupération du modelId de l'élément sélectionné
// export const getModelOfLastSelectedElement = (
//   fragments: OBC.FragmentManager,
//   fragmentId: string
// ) => {
//   let model: any;
//   for (const group of fragments.groups) {
//     const fragmentFound = Object.values(group.keyFragments).find(
//       (id) => id === fragmentId
//     );
//     if (fragmentFound) model = group;
//   }
//   return model;
// };

// ajout aux infos générales du predifinedType
export const addPredifinedTypeToElementInfos = (
  props: any[],
  elementInfos: Map<any, any>,
  selectedLanguage: string
) => {
  // Première prop contient le predifinedType
  const firstProp = props[0];
  const predefinedType = translateValue(
    selectedLanguage,
    IfcClassesTranslation,
    firstProp.PredefinedType?.value
  );
  if (!predefinedType) return;
  const predefinedTypeTitle = translateValue(
    selectedLanguage,
    IfcClassesTranslation,
    "PredifinedType"
  );
  if (predefinedType) {
    elementInfos.set(predefinedTypeTitle, predefinedType);
  }
};

// Récupération des propriétés d'un pset (depuis la "ligne ifc" du pset)
export const getElementPsets = (
  groupProp: any,
  elementPropAndBQ: Map<any, any>,
  selectedLanguage: string
) => {
  for (const prop of groupProp.HasProperties) {
    const propName = translateValue(
      selectedLanguage,
      IfcPropertiesTranslation,
      prop.value.Name.value
    );
    const propValue = translateValue(
      selectedLanguage,
      IfcPropertiesTranslation,
      prop.value.NominalValue?.value.toString()
    );
    elementPropAndBQ.set(propName, propValue);
  }
};

// récupération de l'épaisseur des matériaux depuis les Qsets
export const getMaterialBQByQuantityId = (
  model: any,
  quantityID: number,
  materialConstituentBQ: ElementMaterial[]
) => {
  const name = model.properties[quantityID].Name?.value;
  if (model.properties[quantityID].HasQuantities) {
    const relQuantityId = model.properties[quantityID].HasQuantities[0].value;
    const thickness = model.properties[relQuantityId].LengthValue?.value;
    materialConstituentBQ.push({
      name,
      thickness: roundValue(thickness),
    });
  }
};

// Récupération des BQs d'un élément depuis la "ligne ifc" du Qset
export const getBQFromQuantityId = (
  properties: any,
  quantityID: number,
  elementPropAndBQ: Map<any, any>,
  selectedLanguage: string,
  factorUnits: number,
  isSum: boolean = false
) => {
  const quantityName = translateValue(
    selectedLanguage,
    IfcPropertiesTranslation,
    properties[quantityID].Name.value
  );
  const q = OBC.IfcPropertiesUtils.getQuantityValue(properties, quantityID);
  if (q.value) {
    let quantityNameWithUnit: string = "";
    let quantityValue: number = 0;
    switch (q.key) {
      case "LengthValue":
        quantityNameWithUnit = quantityName + " " + "(m)";
        quantityValue = q.value * factorUnits;
        break;
      case "AreaValue":
        quantityNameWithUnit = quantityName + " " + "(m²)";
        quantityValue = q.value * factorUnits;

        break;
      case "VolumeValue":
        quantityNameWithUnit = quantityName + " " + "(m³)";
        quantityValue = q.value * factorUnits;

        break;
    }

    if (isSum) {
      if (elementPropAndBQ.has(quantityNameWithUnit)) {
        let oldValue = elementPropAndBQ.get(quantityNameWithUnit);
        const valueBQ = oldValue + quantityValue;
        elementPropAndBQ.set(quantityNameWithUnit, roundValue(valueBQ));
      } else {
        elementPropAndBQ.set(quantityNameWithUnit, roundValue(quantityValue));
      }
    } else {
      elementPropAndBQ.set(quantityNameWithUnit, roundValue(quantityValue));
    }
  }
};

// Récupération des BQs depuis l'id élément
export const getBQFromElementId = (
  model: any,
  expressId: string,
  isSum: boolean,
  mapBQ: Map<string, number>,
  propertiesProcessor: OBC.IfcPropertiesProcessor,
  selectedLanguage: string,
  factorUnits: number
) => {
  const props = propertiesProcessor.getProperties(model, expressId);
  if (props) {
    for (const groupProp of props) {
      if (!groupProp.Quantities) continue;
      const quantityExpressId = groupProp.expressID;
      OBC.IfcPropertiesUtils.getQsetQuantities(
        model.properties,
        +quantityExpressId,
        (quantityID) => {
          getBQFromQuantityId(
            model.properties,
            quantityID,
            mapBQ,
            selectedLanguage,
            factorUnits,
            isSum
          );
        }
      );
    }
  }
};

// Récupération des infos de l'élément parent depuis l'id de l'élément
export const getElementParentInfos = (
  model: any,
  expressId: string,
  selectedLanguage: string
) => {
  const relAggregatesTypeNumber = 160246688;
  const relContainedInSpatialStructureTypeNumber = 3242617779;
  let elementParentId = getRelParent(
    expressId,
    model.properties,
    relAggregatesTypeNumber
  );
  // Il n'y a pas de parent via ifcRelAggregate
  if (!elementParentId) {
    elementParentId = getRelParent(
      expressId,
      model.properties,
      relContainedInSpatialStructureTypeNumber
    );
  }
  const parentResult = model.properties[Number(elementParentId)];
  const elementParentInfos = getElementInfos(
    parentResult,
    model.uuid,
    selectedLanguage
  );

  return elementParentInfos;
};

// récupération de l'id du parent depuis l'id de l'élément enfant
export const getRelParent = (
  childId: string,
  modelProperties: any,
  relTypeNumber: number
) => {
  const allRelOfType = OBC.IfcPropertiesUtils.getAllItemsOfType(
    modelProperties,
    relTypeNumber
  );
  for (const relOfType of allRelOfType) {
    let relatingObject =
      relOfType.RelatingObject || relOfType.RelatingStructure;
    let listRelatedObjects =
      relOfType.RelatedObjects || relOfType.RelatedElements;
    if (!listRelatedObjects) continue;
    const find = listRelatedObjects.find(
      (object: any) => object.value === +childId
    );
    if (!find) continue;
    return relatingObject.value;
  }
};

export const getBuildingName = (modelProperties: any) => {
  const ifcBuildingTypeNumber = 4031249490;
  const building = OBC.IfcPropertiesUtils.findItemOfType(
    modelProperties,
    ifcBuildingTypeNumber
  );
  if (!building) return "";
  if (building.LongName?.value) {
    return building.LongName.value;
  }
  if (building.Name?.value) {
    return building.Name.value;
  }
  return "";
};

export const getStoreyName = (
  components: OBC.Components,
  expressId: string
) => {
  const fragmentClassifier = components.tools.get(OBC.FragmentClassifier);
  const byStoreys = fragmentClassifier.get()?.storeys;
  for (const [name, idElements] of Object.entries(byStoreys)) {
    for (const [fraglentID, setOfIds] of Object.entries(idElements)) {
      if (setOfIds.has(expressId)) {
        return name;
      }
    }
  }
  return "";
};

export const getSelectedPropsToExport = (
  propsToExport: string[],
  expressId: string,
  modelID: string,
  selectedLanguage: string,
  allProperties: any
) => {
  let propsNameToExport: string[] = [];
  // On traduit les props sélectionnées en Anglais
  if (selectedLanguage !== "en") {
    for (const prop of propsToExport) {
      const originalPropName = reverseTranslateValue(
        selectedLanguage,
        IfcPropertiesTranslation,
        prop
      );
      propsNameToExport.push(originalPropName);
    }
  } else {
    propsNameToExport = [...propsToExport];
  }
  const selectedPropToExportObject: { [key: string]: any } = {};
  for (const [modelId, allPropertiesByModel] of allProperties) {
    if (modelId !== modelID) continue;
    for (const modelProperty of allPropertiesByModel) {
      let propName = modelProperty.name;
      const propValues = modelProperty.values;
      if (!propsNameToExport.includes(propName)) continue;
      // Gestion à part des matériaux : on concat quand plusieurs sont affectés à un élément
      if (propName === "Materials") {
        let concatMatValues = "";
        for (const [propValue, setOfIds] of propValues) {
          if (setOfIds.has(+expressId)) {
            if (!concatMatValues.length) {
              concatMatValues = propValue;
            } else {
              concatMatValues = `${concatMatValues} , ${propValue}`;
            }
          }
        }
        // On retraduit dans la langue sélectionnée
        if (selectedLanguage !== "en") {
          propName = translateValue(
            selectedLanguage,
            IfcPropertiesTranslation,
            propName
          );
        }
        selectedPropToExportObject[propName] = concatMatValues;
      } else {
        for (const [propValue, setOfIds] of propValues) {
          if (setOfIds.has(+expressId)) {
            // On retraduit dans la langue sélectionnée
            if (selectedLanguage !== "en") {
              propName = translateValue(
                selectedLanguage,
                IfcPropertiesTranslation,
                propName
              );
            }
            selectedPropToExportObject[propName] = propValue;
            break;
          }
        }
      }
    }
  }
  return selectedPropToExportObject;
};

export const getPropsToExport = (
  elementsByType: Map<string, Map<string, Set<string>>>,
  selectedPropsName: string[],
  components: OBC.Components,
  allModels: any[],
  selectedLanguage: string,
  allProperties: any,
  propertiesProcessor: OBC.IfcPropertiesProcessor,
  factorUnits: number
) => {
  const defaultPropsToExport: any[] = [];
  for (const [type, setOfIdsByModel] of elementsByType) {
    for (const [modelId, setOfIds] of setOfIdsByModel) {
      const model = allModels.find((model) => model.uuid === modelId);
      const buildingName = getBuildingName(model.properties);
      for (const expressId of setOfIds) {
        const result = model.properties[+expressId];
        const name = result.Name.value;
        const guid = result.GlobalId.value;
        const storeyName = getStoreyName(components, expressId);

        let allPropsToExport: { [key: string]: any } = {};
        let defaultPropsObject = {
          ExpressId: expressId,
          Nom: name,
          Catégorie: type,
          Edifice: buildingName,
          Niveau: storeyName,
          GlobalId: guid,
        };

        if (selectedPropsName.length) {
          const selectedPropsObject = getSelectedPropsToExport(
            selectedPropsName,
            expressId,
            modelId,
            selectedLanguage,
            allProperties
          );
          allPropsToExport = {
            ...defaultPropsObject,
            ...selectedPropsObject,
          };
        } else {
          allPropsToExport = { ...defaultPropsObject };
        }
        const mapBQ: Map<string, number> = new Map();
        getBQFromElementId(
          model,
          expressId,
          false,
          mapBQ,
          propertiesProcessor,
          selectedLanguage,
          factorUnits
        );

        if (mapBQ) {
          const objectBQs = Object.fromEntries(mapBQ);
          allPropsToExport = { ...allPropsToExport, ...objectBQs };
        }
        defaultPropsToExport.push(allPropsToExport);
      }
    }
  }
  return defaultPropsToExport;
};

export const createExcelTable = (objectToExport: any[]) => {
  const ws = XLSX.utils.json_to_sheet(objectToExport);

  // Customize all headers
  const headerRange = XLSX.utils.decode_range(ws["!ref"] || "A1:A1");
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const headerCellAddress = XLSX.utils.encode_cell({
      r: headerRange.s.r,
      c: col,
    });
    ws[headerCellAddress].s = {
      font: { bold: true, color: { rgb: "FF0000" } },
      fill: { fgColor: { rgb: "424b56" } },
    };
  }

  // Create an Excel table
  const tableName = "Export";
  const tableRange = XLSX.utils.decode_range(ws["!ref"] || "A1:A1");
  ws["!autofilter"] = { ref: XLSX.utils.encode_range(tableRange) }; // Enable autofilter
  ws["!tbl"] = {
    ref: XLSX.utils.encode_range(tableRange),
    displayName: tableName,
    headerRow: 1,
  };

  // Create a workbook with the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  // Save the workbook to a file
  XLSX.writeFile(wb, "exportDataviz.xlsx");
};
