import { FC, PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

const portalHtmlElement = document.getElementById("overlays");

const Backdrop = (props: any) => {
  return <div className={styles.backdrop} onClick={props.onClick}></div>;
};

const ModulOverlay = (props: any) => {
  return (
    <div className={styles.modal}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export const Modal: FC<PropsWithChildren<{ onClose: () => void }>> = (
  props
) => {
  return (
    <>
      {portalHtmlElement &&
        ReactDOM.createPortal(
          <Backdrop onClick={props.onClose} />,
          portalHtmlElement
        )}
      {portalHtmlElement &&
        ReactDOM.createPortal(
          <ModulOverlay>{props.children}</ModulOverlay>,
          portalHtmlElement
        )}
    </>
  );
};
