import { IconButton } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { FC, useEffect, useState } from "react";

import styles from "./tab-title.module.css";
import { EditButton } from "./edit-button";
import { useAppContext } from "../../../../middleware/context-provider";

export const TabTitle: FC<{
  title: string;
  hasIcon: boolean;
  icon?: string;
  editionActive?: boolean;
  actionOnClick?: () => void;
}> = (props) => {
  const { editionActive = false } = props;

  const [state, dispatch] = useAppContext();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  useEffect(() => {
    if (state.userRole && state.userRole === "admin") {
      setIsAdmin(true);
    }
  }, [state.userRole]);

  const buttonClass = editionActive ? styles["active-button"] : "";
  return (
    <div className={styles["column-title"]}>
      <h1>
        <strong>{props.title}</strong>
      </h1>
      {isAdmin && props.hasIcon && (
        <IconButton onClick={props.actionOnClick} className={buttonClass}>
          {props.icon === "edit" ? (
            <EditButton editionActive={editionActive} />
          ) : (
            <ArrowOutwardIcon />
          )}
        </IconButton>
      )}
    </div>
  );
};
