import { FC, useState, ChangeEvent, FormEvent } from "react";

import styles from "./login-form.module.css";
import { useAppContext } from "../../middleware/context-provider";
import { Modal } from "../UI/Modal";
import { ModalTitle } from "../UI/text-components/modal-title";
import { SelectChangeEvent } from "@mui/material";
import { Role } from "../../types";
import { RightsChoiceForm } from "./rights-choice-form";

export const SubscriptionForm: FC<{ onClose: () => void }> = (props) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isUserAdded, setIsUserAdded] = useState<boolean>(false);

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const emailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const passwordChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [userRole, setUserRole] = useState<Role>("user");
  const handleUserRoleChange = (event: SelectChangeEvent) => {
    setUserRole(event.target.value as Role);
  };

  let isFormValid = false;

  if (name && email && password) {
    isFormValid = true;
  }

  const onInputFocus = () => {
    setIsUserAdded(false);
  };

  const dispatch = useAppContext()[1];

  const onRegisterHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch({
      type: "SIGNUP",
      payload: {
        email: email,
        password: password,
        displayName: name,
        role: userRole,
      },
    });

    setIsUserAdded(true);
    setName("");
    setEmail("");
    setPassword("");
  };

  let content = (
    <p className={styles["notification"]}>L'utilisateur a été ajouté</p>
  );

  return (
    <Modal onClose={props.onClose}>
      <ModalTitle text="Nouvel utilisateur" />
      <div className={styles["subscription-form"]}>
        <form onSubmit={onRegisterHandler}>
          <div className={styles["form-control"]}>
            <label htmlFor="name">Nom</label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={nameChangeHandler}
              onFocus={onInputFocus}
            />
          </div>
          <div className={styles["form-control"]}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={emailChangeHandler}
              onFocus={onInputFocus}
            />
          </div>
          <div className={styles["form-control"]}>
            <label htmlFor="password">Mot de passe</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={passwordChangeHandler}
              onFocus={onInputFocus}
            />
          </div>
          <RightsChoiceForm
            userRole={userRole}
            handleChange={handleUserRoleChange}
          />
          <div className={styles["form-actions"]}>
            <button
              className={styles["form-button"]}
              type="submit"
              disabled={!isFormValid}
            >
              Créer un nouvel utilisateur
            </button>
          </div>
        </form>
        {isUserAdded && content}
      </div>
    </Modal>
  );
};
