import { SelectChangeEvent } from "@mui/material";
import { User } from "firebase/auth";
import { FC, useEffect, useState } from "react";
import { useSelectionSection } from "../../hooks/use-selection-section";
import { useAdminAppContext } from "../../middleware/admin-provider";
import { useAppContext } from "../../middleware/context-provider";
import { Role } from "../../types";
import { FooterWithButtons } from "../UI/footer-with-buttons";
import { ListSection } from "../UI/list-section/list-section";
import { Modal } from "../UI/Modal";
import { ModalTitle } from "../UI/text-components/modal-title";
import { RightsChoiceForm } from "./rights-choice-form";

export const RightsHandler: FC<{ onClose: () => void }> = (props) => {
  const [adminState, adminDispatch] = useAdminAppContext();
  const [state, dispatch] = useAppContext();
  const [listUsersToAdd, setListeUsersToAdd] = useState<User[]>([]);

  const {
    dataListState: usersToAddState,
    listDataName: userNamesToAdd,
    selectedDataIdentifier: selectedUserIds,
    checkBoxChangeHandler,
    searchChangeHandler,
  } = useSelectionSection(listUsersToAdd, false, false);

  const [userRole, setUserRole] = useState<Role>("user");
  const handleUserRoleChange = (event: SelectChangeEvent) => {
    setUserRole(event.target.value as Role);
  };

  useEffect(() => {
    adminDispatch({ type: "GET_ALL_USERS" });
  }, []);

  useEffect(() => {
    if (adminState.users) {
      setListeUsersToAdd(adminState.users);
    }
  }, [adminState.users]);

  const affectRights = () => {
    if (selectedUserIds.length > 0) {
      dispatch({
        type: "AFFECT_USERS_ROLE",
        payload: { selectedUserIds, userRole },
      });
      props.onClose();
    } else {
      props.onClose();
    }
  };

  if (!usersToAddState || !adminState.users) return <></>;
  return (
    <Modal onClose={props.onClose}>
      <ModalTitle text="Gestion des droits utilisateurs" />
      <ListSection
        dataListState={usersToAddState}
        listDataName={userNamesToAdd}
        onCheckboxChange={checkBoxChangeHandler}
        onSearchChange={searchChangeHandler}
      />
      <RightsChoiceForm
        key={userRole}
        userRole={userRole}
        handleChange={handleUserRoleChange}
      />
      <FooterWithButtons
        secondButtonText="Affecter"
        firstBtnClick={props.onClose}
        secondBtnClick={affectRights}
      />
    </Modal>
  );
};
