import { FC, useEffect, useState } from "react";
import { useAdminAppContext } from "../../../middleware/admin-provider";
import { useAppContext } from "../../../middleware/context-provider";
import { FooterWithButtons } from "../../UI/footer-with-buttons";
import { Modal } from "../../UI/Modal";

import { ModalTitle } from "../../UI/text-components/modal-title";
import { User } from "firebase/auth";
import { useSelectionSection } from "../../../hooks/use-selection-section";
import { ListSection } from "../../UI/list-section/list-section";

export const AddUsers: FC<{ onClose: () => void }> = (props) => {
  const [adminState, adminDispatch] = useAdminAppContext();
  const [state, dispatch] = useAppContext();

  const listUsersOfCurrentProject = state.project?.users;
  const [listUsersToAdd, setListeUsersToAdd] = useState<User[]>([]);

  const {
    dataListState: usersToAddState,
    listDataName: userNamesToAdd,
    selectedDataIdentifier: selectedUserIds,
    checkBoxChangeHandler,
    searchChangeHandler,
  } = useSelectionSection(listUsersToAdd, false, false);

  useEffect(() => {
    adminDispatch({ type: "GET_ALL_USERS" });
  }, []);

  useEffect(() => {
    if (adminState.users && listUsersOfCurrentProject) {
      // On ne récupère que les utilisateurs n'étant pas déjà dans le projet
      const setFromCurrentUsers = new Set(
        listUsersOfCurrentProject.map((user) => user.uid)
      );
      setListeUsersToAdd(
        adminState.users.filter((user) => !setFromCurrentUsers.has(user.uid))
      );
    }
  }, [adminState.users, listUsersOfCurrentProject]);

  const validateNewUsersHandler = () => {
    if (!state.project) {
      props.onClose();
    } else {
      if (selectedUserIds.length > 0) {
        for (const userId of selectedUserIds) {
          dispatch({
            type: "ADD_USER_TO_PROJECT",
            payload: { userId: userId, projectId: state.project.uid },
          });
        }
        props.onClose();
      } else {
        props.onClose();
      }
    }
  };

  if (!usersToAddState || !adminState.users) return <></>;
  return (
    <Modal onClose={props.onClose}>
      <ModalTitle text="Liste des utilisateurs" />
      <ListSection
        dataListState={usersToAddState}
        listDataName={userNamesToAdd}
        onCheckboxChange={checkBoxChangeHandler}
        onSearchChange={searchChangeHandler}
      />
      <FooterWithButtons
        secondButtonText="Ajouter"
        firstBtnClick={props.onClose}
        secondBtnClick={validateNewUsersHandler}
      />
    </Modal>
  );
};
