import { Storey, Class, FilterQueryGroup, SavedFilter } from "./../../types";
import { Events } from "./../../middleware/event-handler";
import * as OBC from "openbim-components";
import { Project } from "../../types";
import { ModelScene } from "./model-scene";
import { FragmentIdMap } from "openbim-components";

export const modelHandler = {
  viewer: null as ModelScene | null,

  start(container: HTMLDivElement, project: Project, events: Events) {
    if (!this.viewer) {
      this.viewer = new ModelScene(container, project, events);
    }
  },
  remove() {
    if (this.viewer) {
      this.viewer.dispose();
      this.viewer = null;
    }
  },
  async convertIfcToFragments(listIfc: File[]) {
    if (!this.viewer) throw new Error("Viewer non active");
    return await this.viewer.convertIfcToFragments(listIfc);
  },

  handleModelsVisiblity(selectedModelsName: string[]) {
    if (this.viewer) {
      this.viewer.updateVisibleModels(selectedModelsName);
    }
  },
  toggleClippingPlanes(active: boolean) {
    if (this.viewer) {
      this.viewer.toggleClippingPlanes(active);
    }
  },
  deleteAllClippingPlanes() {
    if (this.viewer) {
      this.viewer.deleteAllClippingPlanes();
    }
  },
  removeClipper(clipper: OBC.EdgesPlane) {
    if (this.viewer) {
      this.viewer.removeClipper(clipper);
    }
  },
  toggleLengthDimension(active: boolean) {
    if (this.viewer) {
      this.viewer.toggleLengthDimensions(active);
    }
  },
  deleteAllLengthDimension() {
    if (this.viewer) {
      this.viewer.deleteAllLengthDimensions();
    }
  },
  removeLengthDimension(dimension: OBC.SimpleDimensionLine) {
    if (this.viewer) {
      this.viewer.removeLengthDimension(dimension);
    }
  },
  async initViewer() {
    if (this.viewer) {
      await this.viewer.initViewer();
    }
  },
  deselectElement() {
    if (this.viewer) {
      this.viewer.deselectElement();
    }
  },
  highlightParentElement(parentId: number, modelId: string) {
    if (this.viewer) {
      this.viewer.highlightParentElement(parentId, modelId);
    }
  },
  setUpWhiteMaterial() {
    if (this.viewer) {
      this.viewer.setupWhiteMaterial();
    }
  },
  initMaterial() {
    if (this.viewer) {
      this.viewer.initMaterial();
    }
  },
  setUpPostProduction(active: boolean) {
    if (this.viewer) {
      this.viewer.togglePostProduction(active);
    }
  },
  toggleSpacesVisibility(active: boolean) {
    if (this.viewer) {
      this.viewer.toggleSpacesVisibility(active);
    }
  },
  toggleBackgroundScene(active: boolean) {
    if (this.viewer) {
      this.viewer.toggleBackgroundScene(active);
    }
  },
  toggleLangue(selectedLang: string) {
    if (this.viewer) {
      this.viewer.toggleLangue(selectedLang);
    }
  },
  async getPlans() {
    if (this.viewer) {
      await this.viewer.getPlans();
    }
  },
  async displayPlan(planId: string) {
    if (this.viewer) {
      await this.viewer.displayPlan(planId);
    }
  },
  async exitPlanMode() {
    if (this.viewer) {
      await this.viewer.exitPlan();
    }
  },
  exportToDxf(planId: string) {
    if (this.viewer) {
      this.viewer.exportToDxf(planId);
    }
  },
  getFilterData() {
    if (this.viewer) {
      this.viewer.getFilterData();
    }
  },
  createFilter(
    listSelectedStoreysName: Storey[],
    listSelectedClassesName: Class[],
    queriesGroupList: FilterQueryGroup[]
  ) {
    if (this.viewer) {
      this.viewer.createFilter(
        listSelectedStoreysName,
        listSelectedClassesName,
        queriesGroupList
      );
    }
  },
  getFilterBQByType(
    typeName: string,
    filterResultBySelectedType: Map<string, Set<string>>
  ) {
    if (this.viewer) {
      this.viewer.getResultFilterBqFromIdsByType(
        typeName,
        filterResultBySelectedType
      );
    }
  },
  async colorSelectedQuery(
    selectedColor: string,
    queryGroup: FilterQueryGroup,
    filterResultIdMap: FragmentIdMap
  ) {
    if (this.viewer) {
      await this.viewer.colorSelectedQuery(
        selectedColor,
        queryGroup,
        filterResultIdMap
      );
    }
  },
  removeColorHighlighter() {
    if (this.viewer) {
      this.viewer.removeColorHighlighter();
    }
  },
  async showAll() {
    if (this.viewer) {
      await this.viewer.showAll();
    }
  },
  hideSelected() {
    if (this.viewer) {
      this.viewer.hideSelected();
    }
  },
  isolateSelected(isolationType?: string) {
    if (this.viewer) {
      this.viewer.isolateSelected(isolationType);
    }
  },
  async selectVisible() {
    if (this.viewer) {
      await this.viewer.selectVisible();
    }
  },
  async exportToExcel(
    elementsByType: Map<string, Map<string, Set<string>>>,
    propsToExport: string[]
  ) {
    if (this.viewer) {
      this.viewer.exportToExcel(elementsByType, propsToExport);
    }
  },
  async editSingleElement(
    selectedElementIdMap: FragmentIdMap,
    editedProps: Map<string, string | boolean | number>
  ) {
    if (this.viewer) {
      await this.viewer.editSingleElement(selectedElementIdMap, editedProps);
    }
  },
  toggleMultiSelection(active: boolean) {
    if (this.viewer) {
      this.viewer.toggleMultiSelection(active);
    }
  },
  resetMultiSelection() {
    if (this.viewer) {
      this.viewer.resetMultiSelectionData();
    }
  },
  getMultiSelectBQByType(
    typeName: string,
    filterResultBySelectedType: Map<string, Set<string>>
  ) {
    if (this.viewer) {
      this.viewer.getMultiSelectBqFromIdsByType(
        typeName,
        filterResultBySelectedType
      );
    }
  },
  updateFilterStateWithSavedFilterData(savedFilter: SavedFilter) {
    if (this.viewer) {
      this.viewer.updateFilterStateWithSavedFilterData(savedFilter);
    }
  },
};
