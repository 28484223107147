export const getRandomHexColor = (): string => {
  // Generate random values for RGB components
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Convert RGB values to Hex
  const hexRed = red.toString(16).padStart(2, "0");
  const hexGreen = green.toString(16).padStart(2, "0");
  const hexBlue = blue.toString(16).padStart(2, "0");

  // Concatenate Hex values to form the final color
  const hexColor = `#${hexRed}${hexGreen}${hexBlue}`;

  return hexColor;
};
