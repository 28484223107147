import { User } from "firebase/auth";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { Action, ActionList } from "./action";
import { executeAdminCoreHandler } from "./admin-core-handler";
import { Authenticator } from "./authenticator";
import { Events } from "./event-handler";

export interface AdminState {
  users: User[] | null;
}

export const initialAdminState: AdminState = {
  users: null,
};

const reducer = (adminState: AdminState, action: Action) => {
  if (action.type === "LOAD_ALL_USERS") {
    return { ...adminState, users: action.payload };
  }
  return { ...adminState };
};

const adminAppContext = createContext<[AdminState, React.Dispatch<Action>]>([
  initialAdminState,
  () => {},
]);

export const AdminContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [adminState, setAdminState] = useReducer(reducer, initialAdminState);

  const events = new Events();
  events.on("LOAD_ALL_USERS", (users: User[]) => {
    setAdminState({ type: "LOAD_ALL_USERS", payload: users });
  });

  const dispatch = (action: Action) => {
    setAdminState(action);
    executeAdminCoreHandler(action, events);
  };

  return (
    <adminAppContext.Provider value={[adminState, dispatch]}>
      <Authenticator />
      {children}
    </adminAppContext.Provider>
  );
};

export const useAdminAppContext = () => {
  return useContext(adminAppContext);
};
