import React from "react";
import { Action } from "../../../middleware/action";
import { Tool } from "../../../types";

export default function getSettingsTools(
  dispatch: React.Dispatch<Action>
): Tool[] {
  return [
    {
      name: "Mode réaliste",
      id: "realisticMode",
      action: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
          dispatch({ type: "SET_WHITE_MATERIAL" });
        } else {
          dispatch({ type: "INIT_MATERIAL" });
        }
      },
    },
    {
      name: "Post Production",
      id: "postProduction",
      action: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
          dispatch({ type: "TOGGLE_POSTPROD", payload : false });
        } else {
          dispatch({ type: "TOGGLE_POSTPROD", payload : true });
        }
      },
    },
    {
      name: "Cacher les pièces et les espaces",
      id: "spacesNotVisible",
      action: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
          dispatch({ type: "TOGGLE_SPACES", payload: true });
        } else {
          dispatch({ type: "TOGGLE_SPACES", payload: false });
        }
      },
    },
    {
      name: "Mode clair",
      id: "whiteMode",
      action: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          dispatch({ type: "TOGGLE_BACKGROUND_SCENE", payload: true });
        } else {
          dispatch({ type: "TOGGLE_BACKGROUND_SCENE", payload: false });
        }
      },
    },

    {
      name: "Navigation 1ère personne",
      id: "firstPersonMode",
      action: (event: React.ChangeEvent<HTMLInputElement>) => {},
    },
  ];
}
