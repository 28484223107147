import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../middleware/context-provider";
import styles from "./go-back.module.css";

export const GoBack: FC = () => {
  const [state, dispatch] = useAppContext();
  const navigate = useNavigate();

  const closeProjectHandler = () => {
    dispatch({ type: "CLOSE_PROJECT", payload: state.projects });
    navigate("/projects");
  };

  return (
    <button className={styles["go-back-btn"]} onClick={closeProjectHandler}>
      ← Retour
    </button>
  );
};
