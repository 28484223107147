import { FragmentIdMap } from "openbim-components";
import { ElementsBQByClassType, SavedFilter } from "../types";
import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action) => {
  if (action.type === "LOGOUT") {
    return {
      user: null,
      userRole: null,
      project: null,
      projects: null,
      activeLengthDimensions: [],
      activeClippers: [],
      elementProps: [],
      listPlans: [],
      filterData: null,
      filterRequest: null,
      filterResultData: null,
      filterBqBySelectedType: null,
      elementsVisibilityState: null,
      multiSelectionData: null,
      multiSelectionBqByType: null,
      savedFilters: [],
    };
  }
  if (action.type === "UPDATE_USER") {
    return { ...state, user: action.payload };
  }
  if (action.type === "SIGNUP") {
    return { ...state, user: null, userRole: null };
  }
  if (action.type === "GET_USER_ROLE") {
    return { ...state, userRole: action.payload };
  }
  if (action.type === "GET_PROJECTS") {
    return {
      ...state,
      projects: action.payload,
      project: null,
      elementProps: [],
    };
  }
  if (action.type === "GET_PROJECT_INFO") {
    return { ...state, project: action.payload };
  }
  if (action.type === "CLOSE_PROJECT") {
    return {
      ...state,
      projects: action.payload,
      project: null,
      activeLengthDimensions: [],
      activeClippers: [],
      elementProps: [],
      listPlans: [],
      filterData: null,
      filterRequest: null,
      filterResultData: null,
      filterBqBySelectedType: null,
      elementsVisibilityState: null,
      multiSelectionData: null,
      multiSelectionBqByType: null,
      savedFilters: [],
    };
  }
  if (action.type === "GET_CLIPPERS") {
    const activeClippers = action.payload;
    return { ...state, activeClippers: activeClippers };
  }
  if (action.type === "GET_LENGTH_DIMENSION") {
    const activeLengthDimensions = action.payload;
    return { ...state, activeLengthDimensions: activeLengthDimensions };
  }
  if (action.type === "DELETE_ALL_LENGTH_DIMENSION") {
    return { ...state, activeLengthDimensions: [] };
  }
  if (action.type === "GET_ELEMENT_PROPS") {
    const elementProps = action.payload;
    return {
      ...state,
      elementProps,
      multiSelectionData: null,
      multiSelectionBqByType: null,
    };
  }
  if (action.type === "DESELECTED_ELEMENT") {
    return {
      ...state,
      elementProps: [],
      multiSelectionData: null,
      multiSelectionBqByType: null,
    };
  }
  if (action.type === "GET_PLANS") {
    const listPlans = action.payload;
    return { ...state, listPlans };
  }
  if (action.type === "EXIT_PLAN_MODE") {
    return { ...state, listPlans: [] };
  }
  if (action.type === "LOAD_FILTER_DATA") {
    const filterData = action.payload;
    return { ...state, filterData };
  }
  if (action.type === "SEND_FILTER_DATA") {
    const filterRequest = action.payload;
    return { ...state, filterRequest };
  }
  if (action.type === "SEND_VISIBILITY_DATA") {
    const visibilityData = action.payload;
    return { ...state, elementsVisibilityState: visibilityData };
  }
  if (action.type === "GET_RESULT_FILTER_DATA") {
    const filterResultData: {
      resultIdMap: FragmentIdMap;
      filterResultByType: Map<string, Map<string, Set<string>>>;
    } = action.payload;
    return { ...state, filterResultData };
  }
  if (action.type === "GET_RESULT_FILTER_BQ_BY_TYPE") {
    const filterBqBySelectedType: ElementsBQByClassType = action.payload;
    return { ...state, filterBqBySelectedType };
  }
  if (action.type === "CLOSE_RESULT_FILTER_BQ") {
    return { ...state, filterBqBySelectedType: null };
  }
  if (action.type === "CLOSE_RESULT_FILTER_DATA") {
    return { ...state, filterResultData: null, filterBqBySelectedType: null };
  }
  if (action.type === "GET_MULTI_SELECT_DATA") {
    return { ...state, multiSelectionData: action.payload };
  }
  if (action.type === "CLOSE_MULTI_SELECT_BQ") {
    return { ...state, multiSelectionBqByType: null };
  }
  if (action.type === "GET_MULTI_SELECT_BQ_BY_TYPE") {
    const multiSelectionBqByType: ElementsBQByClassType = action.payload;
    return { ...state, multiSelectionBqByType };
  }
  if (action.type === "SEND_SAVED_FILTERS") {
    const savedFilters: SavedFilter[] = action.payload;
    return { ...state, savedFilters };
  }
  if (action.type === "UPDATE_FILTER_WITH_SAVED_FILTER_DATA") {
    return {
      ...state,
      filterResultData: action.payload.filterResultData,
      filterRequest: action.payload.filterRequest,
    };
  }
  return { ...state };
};
