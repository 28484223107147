import { FilterQueryGroup } from "./../types";
import { FragmentIdMap } from "openbim-components";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  User,
} from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  query,
  QueryDocumentSnapshot,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage";

import { Action } from "../middleware/action";
import { getApp } from "firebase/app";
import { Model, Project, Role, SavedFilter } from "../types";
import { Events } from "../middleware/event-handler";

import { ModelLocalDatabase } from "./models/dexie-utils";
import {
  convertArrayToIdMap,
  convertIdMapToArray,
} from "./utils/fragmentIdMap-helpers";

const t_projects = "projects";
const t_users = "users";
const t_user_project_association = "userProjectAssociation";
const t_filter = "filters";
const t_user_filter_association = "userFilterAssociation";

export const databaseHandler = {
  login: async (action: Action, events: Events) => {
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(
        auth,
        action.payload.email,
        action.payload.password
      );

      const user = auth.currentUser;
      if (user) {
        await getUserRole(user, events);
        await getProjects(user, events);
      }
    } catch (error) {
      console.log("Auth Error: ", error);
    }
  },
  logout: () => {
    const auth = getAuth();
    signOut(auth);
  },
  signup: async (action: Action) => {
    const auth = getAuth();
    const email = action.payload.email;
    const password = action.payload.password;
    const name = action.payload.displayName;
    const role = action.payload.role;
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const newUser = response.user;
      await updateProfile(newUser, { displayName: name });
      const dbInstance = getFirestore(getApp());
      const userDocRef = doc(dbInstance, t_users, newUser.uid);
      await setDoc(userDocRef, {
        email: email,
        displayName: name,
        role: role,
      });
      signOut(auth);
    } catch (error) {
      console.log("Auth Error: ", error);
    }
  },
  updateRole: async (listUserIds: string[], role: Role) => {
    const dbInstance = getFirestore(getApp());
    for (const userId of listUserIds) {
      await updateDoc(doc(dbInstance, t_users, userId), {
        role: role,
      });
    }
  },

  addProject: async (action: Action, events: Events) => {
    const dbInstance = getFirestore(getApp());
    const { user, projectName } = action.payload;
    const userId = user.uid;
    const result = await addDoc(collection(dbInstance, t_projects), {
      projectName,
      models: [],
    });
    const result_association = await addDoc(
      collection(dbInstance, t_user_project_association),
      {
        userId: userId,
        projectId: result.id,
      }
    );
    await getProjects(user, events);
    return result_association.id;
  },

  openUserSpace: async (action: Action, events: Events) => {
    const user = action.payload;
    if (!user) return;
    await getUserRole(user, events);
    await getProjects(user, events);
  },

  deleteProjectFromDb: async (
    projects: Project[],
    project: Project,
    events: Events
  ) => {
    const app = getApp();
    const dbInstance = getFirestore(app);
    const projectRef = doc(dbInstance, t_projects, project.uid);
    const userProjectsCollectionRef = collection(
      dbInstance,
      t_user_project_association
    );

    try {
      // Delete the project document from the "projects" collection
      await deleteDoc(projectRef);

      const deletePromises: Promise<void>[] = [];
      // T_USER_PROJECT_ASSO
      const userProjectQuerySnapshot = await getDocs(
        query(userProjectsCollectionRef, where("projectId", "==", project.uid))
      );
      userProjectQuerySnapshot.forEach((doc) => {
        const userProjectRef = doc.ref;
        deletePromises.push(deleteDoc(userProjectRef));
      });
      await Promise.all(deletePromises);

      // T_FILTER
      await deleteFilterByProject(dbInstance, project.uid);

      // Suppression des modèles dans le storage
      const storageInstance = getStorage(app);
      const ids: string[] = [];
      for (const model of project.models) {
        const fileRef = ref(storageInstance, model.uid);
        await deleteObject(fileRef);
        ids.push(model.uid);
      }
      clearCache(project);
      events.trigger({ type: "CLOSE_PROJECT", payload: projects });
    } catch (error) {
      throw new Error(
        "Error deleting project and associated documents: " + error
      );
    }
  },

  updateProject: async (project: Project, events: Events) => {
    const project_id = project.uid;
    const dbInstance = getFirestore(getApp());
    await updateDoc(doc(dbInstance, t_projects, project_id), {
      projectName: project.projectName,
      models: project.models,
    });
    databaseHandler.getProject(project_id, events);
  },

  // Get Project and metada (ex : users)
  getProject: async (projectId: string, events: Events) => {
    const dbInstance = getFirestore(getApp());
    const userProjectCollectionRef = collection(
      dbInstance,
      t_user_project_association
    );
    try {
      const projectRef = doc(dbInstance, t_projects, projectId);
      const projectDoc = await getDoc(projectRef);
      const querySnapshot = await getDocs(
        query(userProjectCollectionRef, where("projectId", "==", projectId))
      );
      const userIds: string[] = [];
      querySnapshot.forEach((doc) => {
        userIds.push(doc.data().userId);
      });
      const users: User[] = [];
      for (const userId of userIds) {
        const userRef = doc(dbInstance, t_users, userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          users.push({ ...(userDoc.data() as User), uid: userDoc.id });
        }
      }
      const newProject = {
        ...projectDoc.data(),
        uid: projectDoc.id,
        users: users,
      };
      events.trigger({ type: "GET_PROJECT_INFO", payload: newProject });
    } catch (error) {
      console.error("Error getting document:", error);
    }
  },
  addUserToProject: async (action: Action, events: Events) => {
    const dbInstance = getFirestore(getApp());
    await addDoc(collection(dbInstance, t_user_project_association), {
      userId: action.payload.userId,
      projectId: action.payload.projectId,
    });
    databaseHandler.getProject(action.payload.projectId, events);
  },

  removeUserFromProject: async (action: Action, events: Events) => {
    const dbInstance = getFirestore(getApp());
    const userProjectCollectionRef = collection(
      dbInstance,
      t_user_project_association
    );
    const userId = action.payload.userId;
    const projectId = action.payload.projectId;
    const q = query(
      userProjectCollectionRef,
      where("userId", "==", userId),
      where("projectId", "==", projectId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const userProjectRef = doc.ref;
      deleteDoc(userProjectRef);
    });

    deleteFilterByUser(dbInstance, userId, projectId);
    databaseHandler.getProject(action.payload.projectId, events);
  },

  uploadModel: async (
    listModels: Model[],
    listFiles: File[],
    project: Project,
    events: Events
  ) => {
    const storageInstance = getStorage(getApp());
    for (let x = 0; x < listModels.length; x++) {
      const fileRef = ref(storageInstance, listModels[x].uid);
      await uploadBytes(fileRef, listFiles[x]);
    }

    // Quand on upload un modèle, on supprime les filtres enregistrés
    const dbInstance = getFirestore(getApp());
    await deleteFilterByProject(dbInstance, project.uid);

    console.log(project.models);
    // On supprime le cache du projet
    const db = new ModelLocalDatabase();
    await db.open();
    await removePropertiesFromLocalDatabase(db, project.uid);
    for (const model of project.models) {
      await removeModelFromLocalDatabase(db, model.uid);
    }
    db.close();

    //On vide les propriétés dans la bdd
    await removePropertiesFromProject(project.uid);
    events.trigger({ type: "UPDATE_PROJECT", payload: { project } });
  },

  deleteModel: async (project: Project, model: Model, events: Events) => {
    const storageInstance = getStorage(getApp());
    const fileRef = ref(storageInstance, model.uid);
    await deleteObject(fileRef);
    let db = new ModelLocalDatabase();
    await db.open();
    await removeModelFromLocalDatabase(db, model.uid);
    db.close();

    // Quand on supprime un modèle, on supprime les filtres enregistrés
    const dbInstance = getFirestore(getApp());
    await deleteFilterByProject(dbInstance, project.uid);
    events.trigger({ type: "UPDATE_PROJECT", payload: { project } });
  },
  saveFilter: async (
    title: string,
    isGlobalFilter: boolean,
    userId: string,
    projectId: string,
    filterQueryGroups: FilterQueryGroup[],
    resultIdMap: FragmentIdMap,
    events: Events
  ) => {
    if (!filterQueryGroups) {
      filterQueryGroups = [];
    }
    const dbInstance = getFirestore(getApp());
    const convertedResultIdMap = convertIdMapToArray(resultIdMap);
    const doc = await addDoc(collection(dbInstance, t_filter), {
      filterName: title,
      isGlobalFilter,
      projectId,
      filterQueryGroups,
      convertedResultIdMap,
    });
    if (!isGlobalFilter) {
      await addDoc(collection(dbInstance, t_user_filter_association), {
        filterId: doc.id,
        userId: userId,
      });
    }
    await getSavedFilters(userId, projectId, events);
  },
  async getSavedFilters(userId: string, projectId: string, events: Events) {
    await getSavedFilters(userId, projectId, events);
  },
  async deleteFilter(
    projectId: string,
    userId: string,
    filterId: string,
    events: Events
  ) {
    const dbInstance = getFirestore(getApp());

    // suppression dans la table d'asso
    const userFilterRef = collection(dbInstance, t_user_filter_association);
    const querySnapshot = await getDocs(
      query(userFilterRef, where("filterId", "==", filterId))
    );
    querySnapshot.forEach(async (userFilterDoc) => {
      const userFilterRef = doc(
        dbInstance,
        t_user_filter_association,
        userFilterDoc.id
      );
      await deleteDoc(userFilterRef);
    });

    const filterRef = doc(dbInstance, t_filter, filterId);
    await deleteDoc(filterRef);
    await getSavedFilters(userId, projectId, events);
  },
};

const getUserRole = async (user: User, events: Events) => {
  const querySnapshot = await getquerySnapshotOfUsers();
  const userDoc = querySnapshot.docs.find((doc) => doc.id === user.uid);
  if (userDoc) {
    let role: Role = userDoc.data().role;
    events.trigger({ type: "GET_USER_ROLE", payload: role });
  }
};

const getUserById = async (userId: string) => {
  const querySnapshot = await getquerySnapshotOfUsers();
  const userDoc = querySnapshot.docs.find((doc) => doc.id === userId);
  if (userDoc) {
    return userDoc.data();
  } else {
    return null;
  }
};

const getquerySnapshotOfUsers = async () => {
  const dbInstance = getFirestore(getApp());
  const usersCollectionRef = collection(dbInstance, t_users);
  const querySnapshot = await getDocs(query(usersCollectionRef));
  return querySnapshot;
};

// Get All projects by user
const getProjects = async (user: User, events: Events) => {
  const dbInstance = getFirestore(getApp());
  const userProjectCollectionRef = collection(
    dbInstance,
    t_user_project_association
  );
  // Query userProjects to find projects associated with the user
  const querySnapshot = await getDocs(
    query(userProjectCollectionRef, where("userId", "==", user.uid))
  );
  const projectIds: string[] = [];
  querySnapshot.forEach((doc) => {
    projectIds.push(doc.data().projectId);
  });
  // Fetch project details for each project ID
  const projects: Project[] = [];
  for (const projectId of projectIds) {
    const projectRef = doc(dbInstance, t_projects, projectId);
    const projectDoc = await getDoc(projectRef);
    if (projectDoc.exists()) {
      projects.push({ ...(projectDoc.data() as Project), uid: projectDoc.id });
    }
  }
  projects.sort((a, b) => a.projectName.localeCompare(b.projectName));
  events.trigger({ type: "GET_PROJECTS", payload: projects });
};

const clearCache = async (project: Project) => {
  let db = new ModelLocalDatabase();
  await db.open();
  await removePropertiesFromLocalDatabase(db, project.uid);
  for (const model of project.models) {
    await removeModelFromLocalDatabase(db, model.uid);
  }
  db.close();
};

const removePropertiesFromLocalDatabase = async (
  db: ModelLocalDatabase,
  projectId: string
) => {
  try {
    // id to use : projectId
    await db.materials.where("id").equals(projectId).delete();
    await db.properties.where("id").equals(projectId).delete();

    localStorage.removeItem(projectId);
    console.log(`Item with ID ${projectId} removed from the database.`);
  } catch (error) {
    console.error(`Error removing item from the database: ${error}`);
  }
};

const removeModelFromLocalDatabase = async (
  db: ModelLocalDatabase,
  modelId: string
) => {
  try {
    // Use the delete method to remove the item from the models table
    await db.models.where("id").equals(modelId).delete();
    localStorage.removeItem(modelId);
    console.log(`Item with ID ${modelId} removed from the database.`);
  } catch (error) {
    console.error(`Error removing item from the database: ${error}`);
  }
};

// const getAllUsersOfProject = async (projectId: string) => {
//   const listUserIds: string[] = [];
//   const dbInstance = getFirestore(getApp());
//   const userProjectAssoCollectionRef = collection(
//     dbInstance,
//     t_user_project_association
//   );
//   const querySnapshot = await getDocs(query(userProjectAssoCollectionRef));

//   querySnapshot.forEach((doc) => {
//     if (doc.data().projectId === projectId) {
//       listUserIds.push(doc.data().userId);
//     }
//   });

//   return listUserIds;
// };

export const getSavedFilters = async (
  userId: string,
  projectId: string,
  events: Events
) => {
  let listFilters: SavedFilter[] = [];
  // Global Filter
  const globalFilters = await getGlobalFilter(projectId);
  // Personnal Filter
  const personalFilters = await getPersonalFilter(projectId, userId);
  listFilters = [...globalFilters, ...personalFilters];
  events.trigger({ type: "SEND_SAVED_FILTERS", payload: listFilters });
};

const getGlobalFilter = async (projectId: string) => {
  const globalFilters: SavedFilter[] = [];
  const dbInstance = getFirestore(getApp());
  const filterCollectionRef = collection(dbInstance, t_filter);
  const querySnapshot = await getDocs(
    query(
      filterCollectionRef,
      where("projectId", "==", projectId),
      where("isGlobalFilter", "==", true)
    )
  );
  querySnapshot.forEach((doc) => {
    const savedFilter = fillSavedFilter(doc);
    globalFilters.push(savedFilter);
  });
  return globalFilters;
};

const getPersonalFilter = async (projectId: string, userId: string) => {
  const personalFilters: SavedFilter[] = [];
  const dbInstance = getFirestore(getApp());
  const userFilterCollectionRef = collection(
    dbInstance,
    t_user_filter_association
  );
  const userFilterQuerySnapshot = await getDocs(
    query(userFilterCollectionRef, where("userId", "==", userId))
  );
  for (const doc of userFilterQuerySnapshot.docs) {
    const filterDoc = await getFilterById(doc.data().filterId);
    if (filterDoc && filterDoc.data().projectId === projectId) {
      const savedFilter = fillSavedFilter(filterDoc);
      personalFilters.push(savedFilter);
    }
  }
  return personalFilters;
};

const getFilterById = async (filterId: string) => {
  const dbInstance = getFirestore(getApp());
  const filterCollectionRef = collection(dbInstance, t_filter);
  const querySnapshot = await getDocs(query(filterCollectionRef));
  const filterDoc = querySnapshot.docs.find((doc) => doc.id === filterId);
  return filterDoc;
};

const fillSavedFilter = (
  doc: QueryDocumentSnapshot<DocumentData, DocumentData>
): SavedFilter => {
  const savedFilter: SavedFilter = {
    filterId: doc.id,
    isGlobalFilter: doc.data().isGlobalFilter,
    filterName: doc.data().filterName,
    filterQueryGroup: doc.data().filterQueryGroups,
    convertedResultIdMap: doc.data().convertedResultIdMap,
  };
  return savedFilter;
};

const deleteFilterByProject = async (
  dbInstance: Firestore,
  projectId: string
) => {
  const filterCollectionRef = collection(dbInstance, t_filter);
  const userFilterCollectionRef = collection(
    dbInstance,
    t_user_filter_association
  );

  const deletePromises: Promise<void>[] = [];
  // T_FILTER
  const filterQuerySnapshot = await getDocs(
    query(filterCollectionRef, where("projectId", "==", projectId))
  );
  filterQuerySnapshot.forEach(async (doc) => {
    const filtertRef = doc.ref;
    deletePromises.push(deleteDoc(filtertRef));
    // T_USER_FILTER_ASSO
    const userFilterQuerySnapshot = await getDocs(
      query(userFilterCollectionRef, where("filterId", "==", filtertRef.id))
    );
    userFilterQuerySnapshot.forEach(async (doc) => {
      const userFiltertRef = doc.ref;
      deletePromises.push(deleteDoc(userFiltertRef));
    });
  });
  await Promise.all(deletePromises);
};

const deleteFilterByUser = async (
  dbInstance: Firestore,
  userId: string,
  projectId: string
) => {
  const userFilterCollectionRef = collection(
    dbInstance,
    t_user_filter_association
  );
  const deletePromises: Promise<void>[] = [];
  // T_USER_FILTER_ASSO
  const userFilterQuerySnapshot = await getDocs(
    query(userFilterCollectionRef, where("userId", "==", userId))
  );
  userFilterQuerySnapshot.forEach(async (_doc) => {
    const userFiltertRef = _doc.ref;
    deletePromises.push(deleteDoc(userFiltertRef));

    const filterId = _doc.data().filterId;
    //T_FILTER
    const filterRef = doc(dbInstance, t_filter, filterId);
    const filterSnapshot = await getDoc(filterRef);
    if (
      filterSnapshot.exists() &&
      filterSnapshot.data().projectId === projectId
    ) {
      deletePromises.push(deleteDoc(filterRef));
    }
  });
  await Promise.all(deletePromises);
};

const removePropertiesFromProject = async (projectId: string) => {
  const dbInstance = getFirestore(getApp());
  console.log("Removing inside");
  console.log(projectId);
  await updateDoc(doc(dbInstance, t_projects, projectId), {
    allMaterials: [],
    allProperties: [],
  });
};
