import { User } from "firebase/auth";
import {
  Class,
  ElementsBQByClassType,
  FilterQueryGroup,
  MultiSelectDataType,
  Plan,
  Project,
  Role,
  SavedFilter,
  Storey,
  VisibilityState,
} from "../types";
import * as OBC from "openbim-components";
import { FragmentIdMap } from "openbim-components";

export interface State {
  user: User | null;
  userRole: Role | null;
  projects: Project[] | null;
  project: Project | null;
  activeLengthDimensions: OBC.SimpleDimensionLine[];
  activeClippers: OBC.EdgesPlane[];
  elementProps: (Map<any, any> | any[])[];
  listPlans: Plan[];
  filterData: Map<string, any[]> | null;
  filterRequest: {
    storeyQuery: boolean;
    classQuery: boolean;
    classes: Storey[];
    storeys: Class[];
    queriesGroupList: FilterQueryGroup[];
    filterName?: string;
  } | null;
  filterResultData: {
    resultIdMap: FragmentIdMap;
    filterResultByType: Map<string, Map<string, Set<string>>>;
  } | null; // filterResultByType : className, Set of express Ids

  filterBqBySelectedType: ElementsBQByClassType | null;
  elementsVisibilityState: VisibilityState | null;
  multiSelectionData: MultiSelectDataType | null;
  multiSelectionBqByType: ElementsBQByClassType | null;
  savedFilters: SavedFilter[];
}

export const initialState: State = {
  user: null,
  userRole: null,
  projects: null,
  project: null,
  activeLengthDimensions: [],
  activeClippers: [],
  elementProps: [],
  listPlans: [],
  filterData: null,
  filterRequest: null,
  filterResultData: null,
  filterBqBySelectedType: null,
  elementsVisibilityState: null,
  multiSelectionData: null,
  multiSelectionBqByType: null,
  savedFilters: [],
};
