import { FC, FormEvent, useState } from "react";
import { Navigate } from "react-router-dom";
import { useInput } from "../../hooks/use-input";
import { useAppContext } from "../../middleware/context-provider";

import styles from "./login-form.module.css";

const isEmail = (value: string) => value.includes("@");
const isValidPassword = (value: string) => value.trim().length > 6;

export const LoginForm: FC = () => {
  const {
    value: email,
    isValid: isEmailValid,
    hasError: hasEmailError,
    valueChangehandler: emailChangeHandler,
    inputBlurHandler: emailInputBlurHandler,
  } = useInput(isEmail);

  const {
    value: password,
    isValid: isPasswordValid,
    hasError: hasPasswordError,
    valueChangehandler: passwordChangeHandler,
    inputBlurHandler: passwordInputBlurHandler,
  } = useInput(isValidPassword);

  const [state, dispatch] = useAppContext();

  const [isCoupleEmailPasswordValid, setIsCoupleEmailPasswordValid] =
    useState<boolean>(true);

  const onInputFocus = () => {
    setIsCoupleEmailPasswordValid(true);
  };

  const onLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsCoupleEmailPasswordValid(true);
    try {
      dispatch({
        type: "LOGIN",
        payload: { email: email, password: password },
      });
    } catch (error) {
      setIsCoupleEmailPasswordValid(false);
    }
  };

  if (state.user) {
    return <Navigate to="/projects" />;
  }

  let isFormValid = false;
  if (isEmailValid && isPasswordValid) {
    isFormValid = true;
  }

  const emailInputClasses = !hasEmailError
    ? `${styles["form-control"]}`
    : `${styles["form-control"]} ${styles["invalid"]}`;
  const passwordInputClasses = !hasPasswordError
    ? `${styles["form-control"]}`
    : `${styles["form-control"]} ${styles["invalid"]}`;

  const emailErrorClasses = hasEmailError
    ? `${styles["error-text"]}`
    : `${styles["error-text"]} ${styles["hidden"]}`;
  const passwordErrorClasses = hasPasswordError
    ? `${styles["error-text"]}`
    : `${styles["error-text"]} ${styles["hidden"]}`;

  return (
    <div>
      <div className={styles["form-container"]}>
        <form onSubmit={onLogin}>
          <div className={emailInputClasses}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={emailChangeHandler}
              onBlur={emailInputBlurHandler}
              onFocus={onInputFocus}
            />
            <p className={emailErrorClasses}>Email incorrect</p>
          </div>
          <div className={passwordInputClasses}>
            <label htmlFor="password">Mot de passe</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={passwordChangeHandler}
              onBlur={passwordInputBlurHandler}
              // onFocus={onInputFocus}
            />
            <p className={passwordErrorClasses}>
              Le mot de passe doit contenir au moins 6 caractères
            </p>
          </div>
          {!isCoupleEmailPasswordValid && (
            <p className={styles["error-text"]}>
              Identifiant ou mot de passe incorrect
            </p>
          )}
          <div className={styles["form-actions"]}>
            <button
              className={styles["form-button"]}
              type="submit"
              disabled={!isFormValid}
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
