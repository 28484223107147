import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Clear";
import { FC } from "react";

import styles from "./title-section.module.css";

export const TitleSection: FC<{
  elementBqBySelectedType: {
    selectedTypeName: string;
    bqs: Map<string, number>;
  } | null;
  onCloseBQ: () => void;
}> = (props) => {
  return (
    <div className={styles["filter-content__line"]}>
      <div className={styles.subtitle}>
        {props.elementBqBySelectedType ? (
          <>
            <span>
              {`Total Quantités de base : 
    ${props.elementBqBySelectedType.selectedTypeName}`}
            </span>
            <IconButton onClick={props.onCloseBQ}>
              <DeleteIcon style={{ color: "black" }} />
            </IconButton>
          </>
        ) : (
          <span>Nombre d'éléments par catégorie</span>
        )}
      </div>
    </div>
  );
};
