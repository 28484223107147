import { FC } from "react";
import styles from "./radioLogo.module.css";

export const RadioLogo: FC<{
  label: string;
  id: string;
  defaultChecked: boolean;
  onChangeHandler: () => void;
}> = (props) => {
  return (
    <div className={styles["content-equality"]}>
      <input
        type="radio"
        name="equality"
        id={props.id}
        defaultChecked={props.defaultChecked}
        onChange={props.onChangeHandler}
      />
      <label htmlFor={props.id} className={styles["icon"]}>
        <span className={styles["symbol"]}>{props.label}</span>
      </label>
    </div>
  );
};
