import { ChangeEvent, FC } from "react";
import styles from "./property-line.module.css";

export const PropertyLine: FC<{
  propName: string;
  value: string | number | boolean;
  isEditable?: boolean;
  editionActive?: boolean;
  editPropValue?: (
    event: ChangeEvent<HTMLInputElement>,
    propName: string
  ) => void;
}> = (props) => {
  const { isEditable = false, editionActive = false } = props;

  const onInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    propName: string
  ) => {
    if (props.editPropValue) {
      props.editPropValue(event, propName);
    }
  };

  const lineClass = editionActive
    ? `${styles["prop-line"]} ${styles["active"]}`
    : styles["prop-line"];
  return (
    <div className={lineClass} key={`${props.propName} + ${Math.random()}`}>
      <div className={styles.propTitle}>{props.propName} :</div>
      {isEditable ? (
        <input
          type="text"
          name={String(props.value)}
          className={styles["editable-content"]}
          defaultValue={String(props.value)}
          disabled={!editionActive}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onInputChange(event, props.propName)
          }
        />
      ) : (
        <div className={styles.propContent}>{props.value}</div>
      )}
    </div>
  );
};
