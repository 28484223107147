import { FC, useState } from "react";

import styles from "./project-item.module.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import { useInput } from "../../hooks/use-input";
import { useAppContext } from "../../middleware/context-provider";
import { Navigate } from "react-router-dom";

const isNotEmpty = (value: string) => value.trim().length !== 0;
export const AddProject: FC = () => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const {
    value: projectName,
    isValid: projectNameIsValid,
    hasError: hasNameError,
    valueChangehandler: nameValueChangeHandler,
    inputBlurHandler: nameInputBlurHandler,
    reset,
  } = useInput(isNotEmpty);

  const [state, dispatch] = useAppContext();

  const showAddProjectContent = () => {
    setIsCreating(true);
  };

  const hideAddProjectContent = () => {
    reset();
    setIsCreating(false);
  };

  if (!state.user) {
    return <Navigate to="/login" />;
  }

  const createProject = () => {
    dispatch({
      type: "ADD_PROJECT",
      payload: { projectName: projectName, user: state.user },
    });
    hideAddProjectContent();
  };

  const errorMessageClasses = hasNameError
    ? `${styles["alert-text"]}`
    : `${styles["alert-text"]} ${styles["hidden"]}`;

  let addprojectCardContent = isCreating ? (
    <>
      <div className={styles["card-header"]}>
        <IconButton onClick={hideAddProjectContent}>
          <DeleteIcon />
        </IconButton>
      </div>
      <div className={styles["card-content"]}>
        <input
          type="text"
          placeholder="Nom du projet"
          autoFocus={true}
          value={projectName}
          onBlur={nameInputBlurHandler}
          onChange={nameValueChangeHandler}
        />
        <p className={errorMessageClasses}>Le nom est obligatoire</p>
        <button
          className={styles["btn"]}
          disabled={!projectNameIsValid}
          onClick={createProject}
        >
          Valider
        </button>
      </div>
    </>
  ) : (
    <>
      <div className={styles["card-content"]}>
        <div className={styles["icon-add"]}>
          <AddIcon />
        </div>
        <span>Nouveau projet</span>
      </div>
    </>
  );

  const addCardClasses = isCreating
    ? `${styles["card"]} ${styles["add-card"]}`
    : `${styles["card"]}`;

  return (
    <div
      className={addCardClasses}
      onClick={!isCreating ? showAddProjectContent : () => {}}
    >
      {addprojectCardContent}
    </div>
  );
};
