import { FC, useEffect, useState } from "react";

import styles from "./filter-content.module.css";
import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

import { ListItemWithDelete } from "../../../UI/list-section/list-item-with-delete";
import {
  CardContentType,
  Class,
  FilterQueryGroup,
  PropsAndValues,
  Storey,
} from "../../../../types";
import { useAppContext } from "../../../../middleware/context-provider";
import { AddBasicFilter } from "./add-basic-filter";
import { AddPropertyFilter } from "./add-property-filter";
import { FooterWithButtons } from "../../../UI/footer-with-buttons";

export const FilterContent: FC<{
  onCloseFilterMenu: (cardContentType: CardContentType) => void;
}> = (props) => {
  const [state, dispatch] = useAppContext();

  useEffect(() => {
    if (!state.filterData) {
      dispatch({ type: "GET_FILTER_DATA" });
    }
  }, []);

  const listStoreys: Storey[] | undefined = state.filterData?.get("storeys");
  const listClasses: Class[] | undefined = state.filterData?.get("classes");
  const listPropAndValues: PropsAndValues[] | undefined =
    state.filterData?.get("properties");

  const allStoreys: Storey = {
    uid: "allStoreys",
    displayName: "Tous",
    type: "Storey",
  };
  const allClasses: Class = {
    uid: "allClasses",
    displayName: "Tous",
    type: "Class",
  };

  const [selectedStoreys, setSelectedStoreys] = useState<Storey[]>([
    allStoreys,
  ]);
  const [selectedClasses, setSelectedClasses] = useState<Class[]>([allClasses]);
  const [queriesGroupList, setQueriesGroupList] = useState<FilterQueryGroup[]>(
    []
  );

  const [isAddStoreysEnabled, setIsAddStoreysEnabled] =
    useState<boolean>(false);
  const [isAddClassesEnabled, setIsAddClassesEnabled] =
    useState<boolean>(false);
  const [isAddPropertiesEnabled, setIsAddPropertiesEnabled] =
    useState<boolean>(false);
  const selectStoreys = () => {
    setIsAddStoreysEnabled(true);
  };

  if (!listStoreys) return <></>;
  if (!listClasses) return <></>;
  if (!listPropAndValues) return <></>;

  const closeAddStoreysHandler = () => {
    setIsAddStoreysEnabled(false);
  };

  const selectClasses = () => {
    setIsAddClassesEnabled(true);
  };

  const closeAddClassesHandler = () => {
    setIsAddClassesEnabled(false);
  };

  const selectProperties = () => {
    setIsAddPropertiesEnabled(true);
  };

  const closeAddPropertiesHandler = () => {
    setIsAddPropertiesEnabled(false);
  };

  const onDeleteStoreyFilter = (storeyId: string) => {
    setSelectedStoreys((prevState: Storey[]) => {
      const updatedState = prevState.filter((item) => {
        if (item.uid !== storeyId || item.uid === "allStoreys") {
          return item;
        }
      });
      // Lorsqu'on supprime le dernier champ, par défaut retour au champ : "Tous"
      if (updatedState.length) {
        return updatedState;
      }
      return [allStoreys];
    });
  };
  const onDeleteClassFilter = (classId: string) => {
    setSelectedClasses((prevState: Class[]) => {
      const updatedState = prevState.filter((item) => {
        if (item.uid !== classId && item.uid !== "allClasses") {
          return item;
        }
      });
      // Lorsqu'on supprime le dernier champ, par défaut retour au champ : "Tous"
      if (updatedState.length) {
        return updatedState;
      }
      return [allClasses];
    });
  };

  const validateBasicFilter = (
    selectedItemsName: string[],
    basicTypeName: string
  ) => {
    if (basicTypeName === "Niveaux") {
      const storeysSelected = fillSelectedItems(
        selectedItemsName,
        "Storey"
      ) as Storey[];
      // On affiche "Tous" si tous les champs sont sélectionnés
      if (listStoreys) {
        if (selectedItemsName.length === listStoreys.length) {
          setSelectedStoreys([allStoreys]);
        } else {
          setSelectedStoreys(storeysSelected);
        }
      }
      closeAddStoreysHandler();
    }
    if (basicTypeName === "Catégories") {
      const classesSelected = fillSelectedItems(
        selectedItemsName,
        "Class"
      ) as Class[];
      // On affiche "Tous" si tous les champs sont sélectionnés
      if (listClasses) {
        if (selectedItemsName.length === listClasses.length) {
          setSelectedClasses([allClasses]);
        } else {
          setSelectedClasses(classesSelected);
        }
      }
      closeAddClassesHandler();
    }
  };

  const addQueriesGroup = (queriesGroup: FilterQueryGroup) => {
    setQueriesGroupList((prevState) => [...prevState, queriesGroup]);
  };

  const deleteQueriesGroup = (queriesGroupToDelete: FilterQueryGroup) => {
    setQueriesGroupList((prevState) => {
      const updatedState = prevState.filter(
        (queriesGroup) => queriesGroup !== queriesGroupToDelete
      );
      return updatedState;
    });
  };

  const validateFilter = () => {
    let allStoreysSelected: Storey[] = [];
    let allClassesSelected: Class[] = [];
    if (selectedStoreys[0].uid === "allStoreys") {
      const listStoreysName: string[] = listStoreys.map(
        (storey) => storey.displayName
      );
      allStoreysSelected = fillSelectedItems(
        listStoreysName,
        "Storey"
      ) as Storey[];
    }
    if (selectedClasses[0].uid === "allClasses") {
      const listClassesName: string[] = listClasses.map(
        (ifcClass) => ifcClass.displayName
      );
      allClassesSelected = fillSelectedItems(
        listClassesName,
        "Class"
      ) as Class[];
    }
    props.onCloseFilterMenu("Filter");
    if (allStoreysSelected.length && allClassesSelected.length) {
      return dispatch({
        type: "SEND_FILTER_DATA",
        payload: {
          storeyQuery: false,
          classQuery: false,
          storeys: allStoreysSelected,
          classes: allClassesSelected,
          queriesGroupList: queriesGroupList,
        },
      });
    } else if (!allStoreysSelected.length && allClassesSelected.length) {
      return dispatch({
        type: "SEND_FILTER_DATA",
        payload: {
          storeyQuery: true,
          classQuery: false,
          storeys: selectedStoreys,
          classes: allClassesSelected,
          queriesGroupList: queriesGroupList,
        },
      });
    } else if (allStoreysSelected.length && !allClassesSelected.length) {
      return dispatch({
        type: "SEND_FILTER_DATA",
        payload: {
          storeyQuery: false,
          classQuery: true,
          storeys: allStoreysSelected,
          classes: selectedClasses,
          queriesGroupList: queriesGroupList,
        },
      });
    } else {
      return dispatch({
        type: "SEND_FILTER_DATA",
        payload: {
          storeyQuery: true,
          classQuery: true,
          storeys: selectedStoreys,
          classes: selectedClasses,
          queriesGroupList: queriesGroupList,
        },
      });
    }
  };

  const fillSelectedItems = (
    selectedItems: string[],
    type: string
  ): (Class | Storey)[] => {
    let itemsSelected: (Class | Storey)[] = [];
    for (const itemName of selectedItems) {
      if (type === "Class") {
        itemsSelected.push({
          uid: itemName,
          displayName: itemName,
          type: "Class",
        });
      } else if (type === "Storey") {
        itemsSelected.push({
          uid: itemName,
          displayName: itemName,
          type: "Storey",
        });
      }
    }
    return itemsSelected;
  };

  const filterContentClasses = `${styles["filter-content__block"]} ${styles["larger"]}`;
  const itemListClasses = `${styles["scroll-content"]} ${styles["smaller"]}`;

  const firstColumnClasses = `${styles["column"]} ${styles["first"]}`;
  const thirdColumnClasses = `${styles["column"]} ${styles["third"]}`;
  return (
    <>
      <div className={filterContentClasses}>
        <div className={styles["filter-content__line"]}>
          <div className={styles.subtitle}>
            <span>Niveaux</span>
            <IconButton onClick={selectStoreys}>
              <AddCircleOutlineIcon
                style={{ fontSize: 25, color: "#2a313d" }}
              />
            </IconButton>
          </div>
        </div>
        <ul className={itemListClasses}>
          {selectedStoreys.map((storey: Storey) => (
            <ListItemWithDelete
              id={storey.uid}
              key={storey.uid}
              displayName={storey.displayName}
              actionOnItem={onDeleteStoreyFilter}
            />
          ))}
        </ul>
      </div>
      <div className={filterContentClasses}>
        <div className={styles["filter-content__line"]}>
          <div className={styles.subtitle}>
            <span>Catégories</span>
            <IconButton onClick={selectClasses}>
              <AddCircleOutlineIcon
                style={{ fontSize: 25, color: "#2a313d" }}
              />
            </IconButton>
          </div>
        </div>
        <ul className={itemListClasses}>
          {selectedClasses.map((ifcClass: Class) => (
            <ListItemWithDelete
              id={ifcClass.uid}
              key={ifcClass.uid}
              displayName={ifcClass.displayName}
              actionOnItem={onDeleteClassFilter}
            />
          ))}
        </ul>
      </div>
      <div className={filterContentClasses}>
        <div className={styles["filter-content__line"]}>
          <div className={styles.subtitle}>
            <span>Propriétés</span>
            <IconButton onClick={selectProperties}>
              <AddCircleOutlineIcon
                style={{ fontSize: 25, color: "#2a313d" }}
              />
            </IconButton>
          </div>
        </div>
        <ul className={itemListClasses}>
          {queriesGroupList &&
            queriesGroupList.map((queryGroup, index) => {
              let listValues: string = "";
              queryGroup.queries.forEach((query) => {
                listValues = `${listValues}${query.propValue.toString()}, `;
              });
              listValues = listValues.replace(/, $/, "");

              const queryGroupPropName = queryGroup.queries[0].propName;
              const queryGroupPropCondition = queryGroup.condition;
              return (
                <li key={index} className={styles["li-item"]}>
                  <div className={styles.flex}>
                    <div className={firstColumnClasses}>
                      {queryGroup.operator}
                    </div>
                    <div className={styles.column}>{queryGroupPropName}</div>
                    <div className={thirdColumnClasses}>
                      {queryGroupPropCondition}
                    </div>
                    <div className={styles.column}>{listValues}</div>
                  </div>
                  <IconButton
                    className={styles.column}
                    onClick={() => deleteQueriesGroup(queryGroup)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </li>
              );
            })}
        </ul>
      </div>
      <FooterWithButtons
        twoButtonsFooter={false}
        secondButtonText="Valider"
        secondBtnClick={validateFilter}
      />
      {isAddStoreysEnabled && (
        <AddBasicFilter
          basicTypeList={listStoreys}
          basicTypeName="Niveaux"
          onClose={closeAddStoreysHandler}
          onValidate={validateBasicFilter}
        />
      )}
      {isAddClassesEnabled && (
        <AddBasicFilter
          basicTypeList={listClasses}
          basicTypeName="Catégories"
          onClose={closeAddClassesHandler}
          onValidate={validateBasicFilter}
        />
      )}
      {isAddPropertiesEnabled && (
        <AddPropertyFilter
          listPropAndValues={listPropAndValues}
          onClose={closeAddPropertiesHandler}
          onValidate={addQueriesGroup}
          listSelectedQueriesGroup={queriesGroupList}
        />
      )}
    </>
  );
};
