import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Clear";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { FC } from "react";
import { CardContentType, CardModeType } from "../../../types";

import styles from "./card-header.module.css";

export const CardHeader: FC<{
  mode?: CardModeType;
  title: string;
  type: CardContentType;
  onCloseClick: (cardContentType: CardContentType) => void;
  onReduceClick: (cardContentType: CardContentType) => void;
}> = (props) => {
  const closeClickHandler = () => {
    props.onCloseClick(props.type);
  };

  const onReduceClickHandler = () => {
    props.onReduceClick(props.type);
  };

  let headerClasses = `${styles["card-header"]}`;
  if (props.mode === "draggable") {
    headerClasses = `${styles["card-header"]} ${styles["draggable"]}`;
  }
  if (props.mode === "fixed") {
    headerClasses = `${styles["card-header"]}`;
  }

  return (
    <header className={headerClasses}>
      <span className={styles["header-title"]}>{props.title}</span>
      <div>
        <IconButton onClick={onReduceClickHandler}>
          <HorizontalRuleIcon style={{ color: "white" }}></HorizontalRuleIcon>
        </IconButton>
        <IconButton onClick={closeClickHandler}>
          <DeleteIcon style={{ color: "white" }} />
        </IconButton>
      </div>
    </header>
  );
};
