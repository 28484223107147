import { ChangeEvent, FC, useState } from "react";
import styles from "./props-content.module.css";
import { useAppContext } from "../../../../middleware/context-provider";
import { ElementMaterial } from "../../../../types";
import { getTabsData } from "./tabs-data";

export const PropsContent: FC = () => {
  const [toggleTabState, setToggleTabState] = useState<number>(1);
  const [state, dispatch] = useAppContext();

  const elementInfos = state.elementProps[0] as Map<
    string,
    string | boolean | number
  >;
  const elementPropAndBQ = state.elementProps[1] as Map<
    string,
    string | boolean | number
  >;
  const elementMaterials = state.elementProps[2] as ElementMaterial[];

  const elementParentInfos = state.elementProps[3] as Map<
    string,
    string | boolean | number
  >;

  const [editionActive, setEditionActive] = useState<boolean>(false);
  const [editedProps, setEditedProps] = useState<
    Map<string, string | boolean | number>
  >(new Map());

  let selectedElement = state.elementsVisibilityState?.selectedElements;
  if (!selectedElement)
    selectedElement = state.elementsVisibilityState?.selectedSpaces;
  if (!selectedElement) return <></>;

  const activateEditionMode = () => {
    setEditionActive(true);
  };

  const editPropValue = (
    event: ChangeEvent<HTMLInputElement>,
    propName: string
  ) => {
    const newValue = event.target.value;
    const oldValue = elementPropAndBQ.get(propName);
    if (oldValue !== newValue) {
      setEditedProps((prevState) => prevState.set(propName, newValue));
    }
  };

  const validateEdition = () => {
    setEditionActive(false);
    dispatch({
      type: "VALIDATE_SINGLE_EDITION",
      payload: { editedProps, selectedElement },
    });
  };

  const toggleTab = (index: number) => {
    setToggleTabState(index);
  };

  const highlightParentClick = () => {
    let parentId: number = 0;
    let modelId: string = "";
    Array.from(elementParentInfos).map(([propName, value]) => {
      if (propName === "ModelId") {
        modelId = value as string;
      }
      if (propName === "ExpressId") {
        parentId = value as number;
      }
    });
    dispatch({ type: "HIGHLIGHT_PARENT", payload: { parentId, modelId } });
  };

  const tabsData = getTabsData(
    elementInfos,
    elementPropAndBQ,
    elementMaterials,
    elementParentInfos,
    editionActive,
    activateEditionMode,
    editPropValue,
    validateEdition,
    highlightParentClick
  );

  return (
    <>
      <div className={styles["bloc-tabs"]}>
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            className={`${styles.tab} ${
              toggleTabState === tab.id ? styles["active-tab"] : ""
            }`}
            onClick={() => toggleTab(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div>

      <div className={styles["tabs-content"]}>
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            className={`${styles.content} ${
              toggleTabState === tab.id ? styles["active-content"] : ""
            }`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </>
  );
};
