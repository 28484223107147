import { ChangeEvent, FC, useState } from "react";
import { PropsAndValues } from "../../../../../../types";
import { ListItemWithCheckbox } from "../../../../../UI/list-section/list-item-with-checkbox";
import { InputBar } from "../../../../../UI/input-bar";
import { ModalTitle } from "../../../../../UI/text-components/modal-title";

import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import AddIcon from "@mui/icons-material/Add";
import styles from "./selection.module.css";

export const PropertySelection: FC<{
  onCheckBoxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchPropChange: (event: ChangeEvent<HTMLInputElement>) => void;
  nonNumericProperties: PropsAndValues[];
  numericProperties: PropsAndValues[];
}> = (props) => {
  const {
    onCheckBoxChange,
    onSearchPropChange,
    nonNumericProperties,
    numericProperties,
  } = props;

  const [isBQVisible, setIsBQVisible] = useState<boolean>(false);
  const [isPropVisible, setIsPropVisible] = useState<boolean>(true);

  const toggleBQVisibility = () => {
    setIsBQVisible((prevState) => !prevState);
  };

  const togglePropVisibility = () => {
    setIsPropVisible((prevState) => !prevState);
  };

  const contentClasses = `${styles["filter-content__block"]} ${styles["scroll-content"]}`;
  const renderPropertiesList = (properties: PropsAndValues[]) => {
    return (
      <div className={contentClasses}>
        {properties.map((property: any, index: number) => {
          const propertyName = property.name;
          return (
            <ListItemWithCheckbox
              key={index}
              id={propertyName}
              name={propertyName}
              onCheckBoxChange={onCheckBoxChange}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <ModalTitle text="Sélectionner une propriété" />
      <InputBar onInputChange={onSearchPropChange} placeholder="Rechercher" />
      <div className={styles.accentuation}>
        <span>Propriétés</span>
        {isPropVisible ? (
          <HorizontalRuleIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={togglePropVisibility}
          />
        ) : (
          <AddIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={togglePropVisibility}
          />
        )}
      </div>
      {isPropVisible && renderPropertiesList(nonNumericProperties)}
      <div className={styles.accentuation}>
        <span>Quantités de base</span>
        {isBQVisible ? (
          <HorizontalRuleIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={toggleBQVisibility}
          />
        ) : (
          <AddIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={toggleBQVisibility}
          />
        )}
      </div>
      {isBQVisible &&
        numericProperties &&
        renderPropertiesList(numericProperties)}
    </>
  );
};
