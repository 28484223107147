import { ChangeEvent, FC } from "react";
import { Checkbox } from "@mui/material";

import styles from "./list-item.module.css";

export const ListItemWithCheckbox: FC<{
  name: string;
  id: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onCheckBoxChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  const {
    isChecked = false,
    isDisabled = false,
    onCheckBoxChange = () => {},
  } = props;
  return (
    <li className={styles["li-item"]}>
      <span>{props.name}</span>
      <Checkbox
        size="small"
        id={props.id}
        checked={isChecked}
        onChange={onCheckBoxChange}
        disabled={isDisabled}
      />
    </li>
  );
};
