import { Checkbox } from "@mui/material";
import { FragmentIdMap } from "openbim-components";
import { ChangeEvent, FC, useState } from "react";
import { useAppContext } from "../../../../middleware/context-provider";
import { FilterQueryGroup } from "../../../../types";
import { FooterWithButtons } from "../../../UI/footer-with-buttons";
import { InputBar } from "../../../UI/input-bar";
import { Modal } from "../../../UI/Modal";
import { ModalTitle } from "../../../UI/text-components/modal-title";

import styles from "./save-result-content.module.css";

export const SaveResultContent: FC<{
  onClose: () => void;
  filterQueryGroupToSave?: FilterQueryGroup[];
  multiSelectIdMap?: FragmentIdMap;
}> = (props) => {
  const [state, dispatch] = useAppContext();
  const [title, setTitle] = useState<string>("");
  const [globalFilter, setGlobalFilter] = useState<boolean>(false);

  const validateSaving = () => {
    if (!title) return;
    dispatch({
      type: "SAVE_FILTER",
      payload: {
        title,
        globalFilter,
        userId: state.user?.uid,
        projectId: state.project?.uid,
        filterQueryGroupToSave: props.filterQueryGroupToSave,
        resultIdMap: props.multiSelectIdMap
          ? props.multiSelectIdMap
          : state.filterResultData?.resultIdMap,
      },
    });

    props.onClose();
  };
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(event.target.checked);
  };

  return (
    <Modal onClose={props.onClose}>
      <ModalTitle text="Sauvegarde du filtre" />
      <InputBar onInputChange={onInputChange} placeholder="Titre du filtre" />
      <div className={styles["checkbox-container"]}>
        <Checkbox
          size="small"
          id="all"
          checked={globalFilter}
          onChange={onCheckboxChange}
        />
        <span>Filtre commun à tous les membres du projet</span>
      </div>
      <FooterWithButtons
        secondButtonText="Enregistrer"
        firstBtnClick={props.onClose}
        secondBtnClick={validateSaving}
      />
    </Modal>
  );
};
