import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC } from "react";
import { Role } from "../../types";

const roles: Role[] = ["admin", "user", "visitor"];

export const RightsChoiceForm: FC<{
  userRole: Role;
  handleChange: (event: SelectChangeEvent) => void;
}> = (props) => {
  return (
    <FormControl sx={{ marginTop: 3, minWidth: 120 }} size="small">
      <InputLabel id="demo-simple-select-helper-label">
        <b>Rôle</b>
      </InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select"
        value={props.userRole}
        label="Role"
        onChange={props.handleChange}
      >
        {roles.map((role) => {
          return (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
