import { CardConfig } from "../../../types";

export function getCardContentConfig(): CardConfig[] {
  return [
    {
      mode: "fixed",
      title: "Section",
      type: "Section",
    },
    {
      mode: "fixed",
      title: "Mesure",
      type: "Measure",
    },
    {
      mode: "draggable",
      title: "Propriétés",
      type: "Props",
      initialPosition: { x: 50, y: 20 },
    },
    {
      mode: "fixed",
      title: "Paramètres",
      type: "Settings",
    },
    {
      mode: "fixed",
      title: "Mode Plan",
      type: "Plan",
    },
    {
      mode: "fixed",
      title: "Filtre multicritères",
      type: "Filter",
    },
    {
      mode: "draggable",
      title: "Résultat du Filtre",
      type: "FilterResult",
      initialPosition: { x: 50, y: 80 },
    },
    {
      mode: "draggable",
      title: "Multi-sélection",
      type: "SelectedElements",
      initialPosition: { x: 50, y: 80 },
    },
    {
      mode: "fixed",
      title: "Bâtiment",
      type: "BuildingInfo",
    },
  ];
}
