import { FC, useEffect, useState } from "react";
import { Sidebar } from "../viewer/sidebar/sidebar";
import { CardContentType, Model, ReductedPositionState } from "../../types";
import { Viewport } from "./viewport/viewport";
import { Navigate, useParams } from "react-router-dom";
import { useAppContext } from "../../middleware/context-provider";
import { IconButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MenuIcon from "@mui/icons-material/Menu";
import { ListSection } from "../UI/list-section/list-section";

import styles from "./viewer.module.css";
import { useSelectionSection } from "../../hooks/use-selection-section";
import { SavedFilters } from "./saved-filter/saved-filters";

export const Viewer: FC = () => {
  const [openSectionMenu, setOpenSectionMenu] = useState<boolean>(false);
  const [openMeasureMenu, setOpenMeasureMenu] = useState<boolean>(false);
  const [openSettingsMenu, setOpenSettingsMenu] = useState<boolean>(false);
  const [openPlanMode, setOpenPlanMode] = useState<boolean>(false);
  const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);
  const [settingsCheckboxStates, setSettingsCheckboxStates] = useState<
    Record<string, boolean>
  >({
    realisticMode: true,
    spacesNotVisible: true,
    whiteMode: false,
    firstPersonMode: false,
  });
  const [selectedLanguage, setSelectedLanguage] = useState<string>("fr");

  const [reductedPositionState, setReductedPositionState] =
    useState<ReductedPositionState>({
      propsWindow: false,
      filterResultWindow: false,
      filterWindow: false,
      sectionWindow: false,
      measureWindow: false,
      settingsWindow: false,
      planWindow: false,
      buildingInfoWindow: false,
      selectedElementsWindow: false,
    });

  const handleLanguage = (selectedLang: string) => {
    setSelectedLanguage(selectedLang);
    dispatch({ type: "CHANGE_LANGUE", payload: selectedLang });
  };

  const handleSettingsCheckboxChange = (checkboxName: string) => {
    setSettingsCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  const [openBuildingInfoMenu, setOpenBuildingInfoMenu] =
    useState<boolean>(false);

  const [modelsMenuVisible, setModelsMenuVisible] = useState<boolean>(false);
  const [state, dispatch] = useAppContext();
  const { projectId } = useParams();

  const [modelsContainerVisible, setModelsContainerVisible] =
    useState<boolean>(false);
  const {
    dataListState: visibleModelsState,
    listDataName: visibleModelsNames,
    selectedDataIdentifier: selectedModelsId,
    checkBoxChangeHandler,
    searchChangeHandler,
  } = useSelectionSection(state.project?.models, true, false);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [filtersVisible, setFiltersVisible] = useState<boolean>(true);

  useEffect(() => {
    if (state.userRole && state.userRole === "admin") {
      setIsAdmin(true);
    }
  }, [state.userRole]);

  useEffect(() => {
    if (projectId) {
      dispatch({ type: "GET_PROJECT", payload: projectId });
    }
  }, [projectId]);

  useEffect(() => {
    if (state.project?.models) {
      if (state.project.models.length > 1) {
        setModelsMenuVisible(true);
      }
    }
  }, [state.project?.models]);

  useEffect(() => {
    const selectModelsName = state.project?.models.map((model) => {
      return selectedModelsId.includes(model.uid) ? model.displayName : null;
    });
    dispatch({ type: "HANDLE_MODELS_VISIBILITY", payload: selectModelsName });
  }, [selectedModelsId, state.project?.models]);

  if (!visibleModelsState) return <></>;

  const onUploadModel = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.style.visibility = "hidden";
    input.multiple = true;
    document.body.appendChild(input);

    input.onchange = () => {
      if (input.files && input.files.length) {
        const projectUpdated = { ...state.project };
        const listModelsUploaded: Model[] = [];
        const listFilesUploaded: File[] = [];
        for (let x = 0; x < input.files.length; x++) {
          const file = input.files[x];
          const { name } = file;
          const uid = `${name}-${performance.now()}`;
          const model = { displayName: name, uid };
          listModelsUploaded.push(model);
          listFilesUploaded.push(file);
          projectUpdated.models?.push(model);
        }
        dispatch({
          type: "UPLOAD_MODEL",
          payload: {
            listModelsUploaded,
            listFilesUploaded,
            project: projectUpdated,
            user: state.user,
          },
        });
      }
      input.remove();
    };
    input.click();
  };

  const handleMenuOpenning = (cardContentType: CardContentType) => {
    if (cardContentType === "Section") {
      setOpenSectionMenu((prevSectionMenuState: boolean) => {
        return !prevSectionMenuState;
      });
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, sectionWindow: false };
      });
      dispatch({ type: "DELETE_ALL_CLIPPER" });
    }
    if (cardContentType === "Measure") {
      setOpenMeasureMenu((prevMeasureMenuState: boolean) => {
        return !prevMeasureMenuState;
      });
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, measureWindow: false };
      });
      dispatch({ type: "DELETE_ALL_LENGTH_DIMENSION" });
    }
    if (cardContentType === "Settings") {
      setOpenSettingsMenu((prevSettingsMenuState: boolean) => {
        return !prevSettingsMenuState;
      });
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, settingsWindow: false };
      });
    }
    if (cardContentType === "Plan") {
      if (!openPlanMode) {
        dispatch({ type: "OPEN_PLAN_MODE" });
      } else {
        dispatch({ type: "EXIT_PLAN_MODE" });
      }
      setOpenPlanMode((prevPlanModeState: boolean) => {
        return !prevPlanModeState;
      });
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, planWindow: false };
      });
    }
    if (cardContentType === "Filter") {
      setOpenFilterMenu((prevFilterMenuState: boolean) => {
        return !prevFilterMenuState;
      });
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, filterWindow: false };
      });
    }
    if (cardContentType === "BuildingInfo") {
      setOpenBuildingInfoMenu((prevBuildingInfoMenuState: boolean) => {
        return !prevBuildingInfoMenuState;
      });
      setReductedPositionState((prevState: ReductedPositionState) => {
        return {
          ...prevState,
          buildingInfoWindow: false,
        };
      });
    }
  };

  const closeResultWindow = (cardContentType: CardContentType) => {
    if (cardContentType === "Props") {
      dispatch({ type: "DESELECTED_ELEMENT" });
    }
    if (cardContentType === "FilterResult") {
      dispatch({ type: "CLOSE_RESULT_FILTER_DATA" });
    }
    if (cardContentType === "SelectedElements") {
      dispatch({ type: "RESET_MULTI_SELECTION" });
    }
  };

  const toggleReduction = (cardContentType: CardContentType) => {
    if (cardContentType === "Props") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, propsWindow: !prevState.propsWindow };
      });
    }
    if (cardContentType === "Section") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, sectionWindow: !prevState.sectionWindow };
      });
    }
    if (cardContentType === "Measure") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, measureWindow: !prevState.measureWindow };
      });
    }
    if (cardContentType === "Settings") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, settingsWindow: !prevState.settingsWindow };
      });
    }
    if (cardContentType === "Plan") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, planWindow: !prevState.planWindow };
      });
    }
    if (cardContentType === "Filter") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return { ...prevState, filterWindow: !prevState.filterWindow };
      });
    }
    if (cardContentType === "FilterResult") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return {
          ...prevState,
          filterResultWindow: !prevState.filterResultWindow,
        };
      });
    }
    if (cardContentType === "BuildingInfo") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return {
          ...prevState,
          buildingInfoWindow: !prevState.buildingInfoWindow,
        };
      });
    }
    if (cardContentType === "SelectedElements") {
      setReductedPositionState((prevState: ReductedPositionState) => {
        return {
          ...prevState,
          selectedElementsWindow: !prevState.selectedElementsWindow,
        };
      });
    }
  };

  if (!state.user) {
    return <Navigate to="/login" />;
  }

  const toggleFiltersVisibility = () => {
    setFiltersVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className={styles["models-logo-container"]}>
        {isAdmin && (
          <IconButton style={{ color: "white" }} onClick={onUploadModel}>
            <FileUploadIcon />
          </IconButton>
        )}
        {modelsMenuVisible && (
          <IconButton
            style={{ color: "white" }}
            onClick={() => setModelsContainerVisible(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
      </div>
      {modelsContainerVisible && (
        <div
          className={styles["models-container"]}
          onMouseLeave={() =>
            setTimeout(() => {
              setModelsContainerVisible(false);
            }, 300)
          }
        >
          <ListSection
            dataListState={visibleModelsState}
            listDataName={visibleModelsNames}
            onCheckboxChange={checkBoxChangeHandler}
            onSearchChange={searchChangeHandler}
          />
        </div>
      )}
      <Sidebar onMenuClick={handleMenuOpenning} />
      <Viewport
        onToggleMenu={handleMenuOpenning}
        onCloseResultWindow={closeResultWindow}
        sectionMenu={openSectionMenu}
        measureMenu={openMeasureMenu}
        filterMenu={openFilterMenu}
        settingsMenu={openSettingsMenu}
        settingsCheckboxStates={settingsCheckboxStates}
        selectedLanguage={selectedLanguage}
        onLanguageChange={handleLanguage}
        onSettingsCheckboxChange={handleSettingsCheckboxChange}
        planMode={openPlanMode}
        buildingInfoMenu={openBuildingInfoMenu}
        toggleReduction={toggleReduction}
        reductedPositionState={reductedPositionState}
      />
      <SavedFilters
        filtersVisible={filtersVisible}
        toggleFiltersVisibility={toggleFiltersVisibility}
      />
    </>
  );
};
