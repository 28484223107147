import { FC } from "react";
import styles from "./menu-content.module.css";

export const BuildingInfoContent: FC = () => {
  return (
    <div className={styles["container-table"]}>
      <table className={styles["table"]}></table>
    </div>
  );
};
