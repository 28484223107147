import { ChangeEvent } from "react";
import { ElementMaterial, Tab } from "../../../../types";
import { PropertyLine } from "./property-line";
import { PsetTitle } from "./pset-title";
import { TabTitle } from "./tab-title";
import styles from "./tabs-data.module.css";

export function getTabsData(
  elementInfos: Map<string, string | boolean | number>,
  elementPropAndBQ: Map<string, string | boolean | number>,
  elementMaterials: ElementMaterial[],
  elementParentInfos: Map<string, string | boolean | number>,
  editionActive: boolean,
  activateEditionMode: () => void,
  editPropValue: (
    event: ChangeEvent<HTMLInputElement>,
    propName: string
  ) => void,
  validateEdition: () => void,
  highlightParentAction: () => void
): Tab[] {
  return [
    {
      id: 1,
      title: "Informations générales",
      content: (
        <>
          <TabTitle title="Informations générales" hasIcon={false} />
          <div className={styles["prop-container"]}>
            {Array.from(elementInfos).map(([propName, value]) => {
              if (propName !== "ExpressId" && propName !== "ModelId") {
                return <PropertyLine propName={propName} value={value} />;
              }
            })}
          </div>
        </>
      ),
    },
    {
      id: 2,
      title: "Jeux de propriétés",
      content: (
        <>
          <TabTitle
            title="Jeux de propriétés"
            hasIcon={true}
            icon="edit"
            editionActive={editionActive}
            actionOnClick={
              !editionActive ? activateEditionMode : validateEdition
            }
          />

          <div className={styles["prop-container"]}>
            {Array.from(elementPropAndBQ).map(([propName, value]) => {
              if (!isNaN(+propName)) {
                return <PsetTitle propName={value} />;
              }
              if (value !== undefined) {
                return (
                  <PropertyLine
                    propName={propName}
                    value={value}
                    isEditable={true}
                    editionActive={editionActive}
                    editPropValue={(
                      event: ChangeEvent<HTMLInputElement>,
                      propName: string
                    ) => editPropValue(event, propName)}
                  />
                );
              }
            })}
          </div>
        </>
      ),
    },
    {
      id: 3,
      title: "Matériaux",
      content: (
        <>
          <TabTitle title="Matériaux" hasIcon={false} />
          <div className={styles["prop-container"]}>
            {elementMaterials.map(({ name, thickness }) => {
              if (name && name !== "<Unnamed>") {
                return (
                  <PropertyLine
                    propName={name}
                    value={thickness ? thickness : "-"}
                  />
                );
              }
            })}
          </div>
        </>
      ),
    },
    {
      id: 4,
      title: "Elément Parent",
      content: (
        <>
          <TabTitle
            title="Elément parent"
            hasIcon={true}
            actionOnClick={highlightParentAction}
          />
          <div className={styles["prop-container"]}>
            {Array.from(elementParentInfos).map(([propName, value]) => {
              if (propName !== "ExpressId" && propName !== "ModelId") {
                return <PropertyLine propName={propName} value={value} />;
              }
            })}
          </div>
        </>
      ),
    },
  ];
}
