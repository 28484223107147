import { FC } from "react";

import styles from "./li-item.module.css";
export const BQItem: FC<{ quantityName: string; quantityValue: number }> = (
  props
) => {
  return (
    <li
      className={`${styles["li-item"]} ${styles["margin-right"]}`}
      key={props.quantityName}
    >
      <span>{props.quantityName}</span>
      <span>{props.quantityValue}</span>
    </li>
  );
};
