import { PropsAndValues } from "./../../types";
import { Class, DataTranslation } from "../../types";
import IfcClassesTranslation from "../../traduction/ifcClasses.json";
import IfcPropertiesTranslation from "../../traduction/ifcProperties.json";

export const translateValue = (
  selectedLang: string,
  objectTranslationFile: DataTranslation[],
  value: string
): string => {
  if (selectedLang === "fr") {
    objectTranslationFile.map((elem) => {
      if (elem.origin === value) {
        return (value = elem.fr);
      }
    });
  }
  return value;
};

export const reverseTranslateValue = (
  selectedLang: string,
  objectTranslationFile: DataTranslation[],
  value: string
): string => {
  if (selectedLang === "fr") {
    objectTranslationFile.map((elem) => {
      if (value === elem.fr) {
        return (value = elem.origin);
      }
    });
  }
  return value;
};

export const translateListOfClasses = (
  listClassesName: Class[],
  lang: string
) => {
  for (const listClassName of listClassesName) {
    listClassName.displayName = translateValue(
      lang,
      IfcClassesTranslation,
      listClassName.displayName
    );
    listClassName.uid = translateValue(
      lang,
      IfcClassesTranslation,
      listClassName.displayName
    );
  }
  listClassesName.sort((a, b) => a.displayName.localeCompare(b.displayName));
  return listClassesName;
};
export const reverseTranslateListOfClasses = (
  listClassesName: Class[],
  lang: string
) => {
  for (const listClassName of listClassesName) {
    listClassName.displayName = reverseTranslateValue(
      lang,
      IfcClassesTranslation,
      listClassName.displayName
    );
    listClassName.uid = reverseTranslateValue(
      lang,
      IfcClassesTranslation,
      listClassName.displayName
    );
  }
  return listClassesName;
};

export const translateProps = (
  listPropAndValues: PropsAndValues[],
  lang: string
) => {
  for (const propAndValues of listPropAndValues) {
    propAndValues.name = translateValue(
      lang,
      IfcPropertiesTranslation,
      propAndValues.name
    );
  }
  listPropAndValues.sort((a, b) => a.name.localeCompare(b.name));
  return listPropAndValues;
};

export const reverseTranslateProps = (
  listPropAndValues: PropsAndValues[],
  lang: string
) => {
  for (const propAndValues of listPropAndValues) {
    propAndValues.name = reverseTranslateValue(
      lang,
      IfcPropertiesTranslation,
      propAndValues.name
    );
  }
  return listPropAndValues;
};
