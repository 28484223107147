import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../../middleware/context-provider";
import { CoupleTypeNumberOfElem } from "../../../../types";
import { BQItem } from "./bq-item";

import styles from "./detail-by-type-section.module.css";
import { TitleSection } from "./title-section";
import { TypeItem } from "./type-item";

const getNumberOfElementsByType = (
  elementsByType: Map<string, Map<string, Set<string>>> | null
) => {
  if (!elementsByType) return null;
  const listOfCouples: CoupleTypeNumberOfElem[] = [];

  for (const [typeName, setOfIdsByModel] of elementsByType) {
    let totalNumber = 0;
    for (const [modelId, setOfIds] of setOfIdsByModel) {
      totalNumber += setOfIds.size;
    }
    const coupleTypeNumberOfElem: CoupleTypeNumberOfElem = {
      typeName,
      numberOfElem: totalNumber,
    };
    listOfCouples.push(coupleTypeNumberOfElem);
  }
  return listOfCouples;
};

export const DetailByTypeSection: FC<{
  elementsByType: Map<string, Map<string, Set<string>>>;
  elementBqBySelectedType: {
    selectedTypeName: string;
    bqs: Map<string, number>;
  } | null;
  closeBQ: () => void;
  getBqByType: (typeName: string) => void;
}> = (props) => {
  const [state, dispatch] = useAppContext();

  const [listCoupleTypeNumberElem, setListCoupleTypeNumberElem] = useState<
    CoupleTypeNumberOfElem[]
  >([]);

  useEffect(() => {
    const calculateCoupleTypeNumberElem = () => {
      if (props.elementsByType) {
        let result = getNumberOfElementsByType(props.elementsByType);
        if (!result) result = [];
        setListCoupleTypeNumberElem(result);
      }
    };
    calculateCoupleTypeNumberElem();
  }, [props.elementsByType]);

  if (!props.elementsByType) return <></>;

  const filterContentClasses = `${styles["filter-content__block"]} ${styles["larger"]}`;
  return (
    <div className={filterContentClasses}>
      <TitleSection
        elementBqBySelectedType={props.elementBqBySelectedType}
        onCloseBQ={props.closeBQ}
      />
      <ul className={styles["scroll-content"]}>
        {props.elementBqBySelectedType
          ? Array.from(props.elementBqBySelectedType.bqs).map(
              ([quantityName, quantityValue]) => {
                return (
                  <BQItem
                    quantityName={quantityName}
                    quantityValue={quantityValue}
                  ></BQItem>
                );
              }
            )
          : props.elementsByType.size > 0 &&
            listCoupleTypeNumberElem.map((coupleTypeNumberOfElem) => {
              return (
                <TypeItem
                  coupleTypeNumberOfElem={coupleTypeNumberOfElem}
                  getBQByType={props.getBqByType}
                />
              );
            })}
      </ul>
    </div>
  );
};
