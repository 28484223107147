import { Checkbox } from "@mui/material";
import { ChangeEvent, FC } from "react";
import styles from "./checkbox-all.module.css";

export const CheckboxAll: FC<{
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checkState: boolean;
}> = (props) => {
  const classesCheckBoxAll = `${styles["li-item"]} ${styles["main"]}`;
  return (
    <div className={classesCheckBoxAll}>
      <span>Tous</span>
      <Checkbox
        size="small"
        id="all"
        checked={props.checkState}
        onChange={props.onCheckboxChange}
      />
    </div>
  );
};
