import { FC, useState } from "react";
import { useAppContext } from "../../../../middleware/context-provider";
import { DetailByTypeSection } from "../detailByType/detail-by-type-section";
import { SaveExportFooter } from "../save-export-footer/save-export-footer";
import { ExportExcelContent } from "../exportExcel/export-excel-content";

import styles from "./multi-selection-content.module.css";
import { SaveResultContent } from "../save-result/save-result-content";

const getTypeName = (
  expressId: string,
  elementsByType: Map<string, Map<string, Set<string>>>
) => {
  for (const [type, mapIdsByModel] of elementsByType) {
    for (const [modelId, setOfIds] of mapIdsByModel) {
      for (const id of setOfIds) {
        if (id === expressId) {
          return type;
        }
      }
    }
  }
};

export const MultiSelectionContent: FC<{}> = (props) => {
  const [state, dispatch] = useAppContext();
  const multiSelectionData = state.multiSelectionData;
  const [exportExl, setExportExl] = useState<boolean>(false);
  const openExportExcel = () => {
    setExportExl(true);
  };
  const closeExportExcel = () => {
    setExportExl(false);
  };

  const [saveResult, setSaveResult] = useState<boolean>(false);
  const openSaveResult = () => {
    setSaveResult(true);
  };
  const closeSaveResult = () => {
    setSaveResult(false);
  };
  if (!multiSelectionData) return <></>;

  const getMultiSelectBqByType = (typeName: string) => {
    if (multiSelectionData.elementsByType.has(typeName)) {
      const elementsBySelectedType =
        multiSelectionData.elementsByType.get(typeName);
      if (elementsBySelectedType) {
        dispatch({
          type: "SEND_TYPENAME_FOR_MULTI_SELECT_BQ",
          payload: {
            elementsBySelectedType,
            typeName,
          },
        });
      }
    }
  };

  const closeBQ = () => {
    dispatch({
      type: "CLOSE_MULTI_SELECT_BQ",
    });
  };

  return (
    <>
      <div className={styles["two-rows-with-footer"]}>
        <div>
          <div className={styles["filter-content__line"]}>
            <div className={styles.subtitle}>
              <span>Eléments sélectionnés</span>
            </div>
          </div>
          <div className={styles["scroll-content"]}>
            <table className={styles["table"]}>
              <thead>
                <tr>
                  <th>GlobalId</th>
                  <th>Catégorie</th>
                </tr>
              </thead>
              <tbody>
                {Array.from(multiSelectionData.globalIds).map((element) => {
                  const type = getTypeName(
                    element.expressId,
                    multiSelectionData.elementsByType
                  );
                  return (
                    <tr>
                      <td>{element.globalId}</td>
                      <td>{type}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <DetailByTypeSection
            elementsByType={multiSelectionData.elementsByType}
            elementBqBySelectedType={state.multiSelectionBqByType}
            closeBQ={closeBQ}
            getBqByType={getMultiSelectBqByType}
          />
        </div>
        <SaveExportFooter
          openExportExcel={openExportExcel}
          openSaveResult={openSaveResult}
        />
      </div>
      {exportExl && (
        <ExportExcelContent
          elementsByType={multiSelectionData.elementsByType}
          onClose={closeExportExcel}
        />
      )}
      {saveResult && (
        <SaveResultContent
          onClose={closeSaveResult}
          multiSelectIdMap={state.elementsVisibilityState?.selectedElements}
        />
      )}
    </>
  );
};
