import { ThemeProvider } from "@mui/material";
import "./App.css";
import { theme } from "./mui-theme";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginForm } from "./components/user/login-form";
import { Projects } from "./components/projects/projects";
import { ContextProvider } from "./middleware/context-provider";
import { AdminContextProvider } from "./middleware/admin-provider";
import { Viewer } from "./components/viewer/viewer";
import { ProjectSettings } from "./components/projects/project-settings/project-settings";
import { RootLayout } from "./components/root";

const router = createBrowserRouter([
  { path: "/", element: <LoginForm /> },
  { path: "/login", element: <LoginForm /> },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/projects", element: <Projects /> },
      { path: "/dataviz/:projectId", element: <Viewer /> },
      { path: "/projectSettings/:projectId", element: <ProjectSettings /> },
    ],
  },
]);
function App() {
  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <AdminContextProvider>
          <RouterProvider router={router} />
        </AdminContextProvider>
      </ContextProvider>
    </ThemeProvider>
  );
}

export default App;
