import { FC, useState, useRef, ChangeEvent } from "react";
import { useAppContext } from "../../../middleware/context-provider";

import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoneIcon from "@mui/icons-material/Done";

import styles from "./project-settings.module.css";
import { ListItemWithDelete } from "../../UI/list-section/list-item-with-delete";

import { User } from "firebase/auth";
import { AddUsers } from "./add-users";
import { Navigate } from "react-router-dom";

export const ProjectSettingsContent: FC = () => {
  const [isNameEditable, setIsNameEditable] = useState<boolean>(false);
  const [isAddUsersEnabled, setIsAddUsersEnabled] = useState<boolean>(false);

  const [state, dispatch] = useAppContext();

  const nameInputRef = useRef<HTMLInputElement>(null);

  const onEditNameClickHandler = () => {
    setIsNameEditable(true);
    setTimeout(() => {
      if (nameInputRef.current) {
        nameInputRef.current.focus();
        nameInputRef.current.select();
      }
    }, 100);
  };

  let isNameValid = true;
  const onNameinputBlurHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length === 0) {
      isNameValid = false;
    } else {
      isNameValid = true;
    }
  };

  const onValidateNameClickHandler = () => {
    if (isNameValid) {
      const newProject = { ...state.project };
      newProject.projectName =
        nameInputRef.current?.value || state.project?.projectName;
      dispatch({
        type: "UPDATE_PROJECT",
        payload: { project: newProject },
      });
      setIsNameEditable(false);
    }
  };

  const onAddUsersHandler = () => {
    setIsAddUsersEnabled(true);
  };

  const closeAddUsersHandler = () => {
    setIsAddUsersEnabled(false);
  };

  const onDeleteUser = (userId: string) => {
    if (state.project) {
      dispatch({
        type: "REMOVE_USER_FROM_PROJECT",
        payload: { userId: userId, projectId: state.project.uid },
      });
    }
  };

  const onDeleteModel = (model_id: string) => {
    const newProject = { ...state.project };
    if (!newProject.models) throw new Error("Error : No model found");
    const model = newProject.models.find((model) => model.uid === model_id);
    if (!model) throw new Error("Error : No model found");
    newProject.models = newProject.models.filter(
      (model) => model.uid !== model_id
    );
    dispatch({
      type: "DELETE_MODEL",
      payload: { project: newProject, model },
    });
  };

  if (!state.project) {
    <Navigate to="/projects" />;
  }

  let listUsers: User[] | null = [];
  if (state.project) {
    listUsers = [...state.project.users];
  }

  const itemListClasses = `${styles["settings-content__line"]} ${styles["scroll-content"]}`;
  return (
    <>
      <div className={styles["settings-content__line"]}>
        <div className={styles["name-control"]}>
          <input
            id="name-input"
            ref={nameInputRef}
            type="text"
            defaultValue={state.project?.projectName}
            disabled={!isNameEditable}
            onBlur={onNameinputBlurHandler}
          />
        </div>
        <div className={styles["edit-control"]}>
          {!isNameEditable ? (
            <IconButton onClick={onEditNameClickHandler}>
              <EditIcon />
            </IconButton>
          ) : (
            <IconButton onClick={onValidateNameClickHandler}>
              <DoneIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className={styles["settings-content__block"]}>
        <div className={styles["settings-content__line"]}>
          <div className={styles.subtitle}>
            <span>Utilisateurs</span>
            <IconButton onClick={onAddUsersHandler}>
              <AddCircleOutlineIcon
                style={{ fontSize: 25, color: "#2d3e50" }}
              />
            </IconButton>
          </div>
        </div>
        <div className={itemListClasses}>
          <ul>
            {listUsers.map((user) => (
              <ListItemWithDelete
                key={user.uid}
                id={user.uid}
                displayName={user.displayName || ""}
                actionOnItem={onDeleteUser}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className={styles["settings-content__block"]}>
        <div className={styles["settings-content__line"]}>
          <div className={styles.subtitle}>
            <span>Maquettes IFC</span>
          </div>
        </div>
        <div className={itemListClasses}>
          <ul>
            {state.project &&
              state.project.models.map((model) => (
                <ListItemWithDelete
                  key={model.uid}
                  id={model.uid}
                  displayName={model.displayName}
                  actionOnItem={onDeleteModel}
                />
              ))}
          </ul>
        </div>
      </div>
      {isAddUsersEnabled && <AddUsers onClose={closeAddUsersHandler} />}
    </>
  );
};
