import { FC } from "react";
import { useAppContext } from "../../../middleware/context-provider";

import styles from "./account-menu.module.css";

export const Logout: FC = () => {
  const dispatch = useAppContext()[1];

  const onLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <button className={styles["menu-line-btn"]} onClick={onLogout}>
      Déconnexion
    </button>
  );
};
