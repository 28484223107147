import { intersectFragmentIdMaps } from "./../fragmentIdMap-helpers";
import {
  FilterQuery,
  FilterQueryGroup,
  ModelPropertiesToStore,
  PropsAndValues,
} from "../../../types";
import { Class, ModelProperties, Storey } from "../../../types";
import * as OBC from "openbim-components";
import {
  FragmentIdMap,
  IfcPropertiesManager,
  IfcPropertiesUtils,
} from "openbim-components";
import { unionFragmentIdMaps } from "../fragmentIdMap-helpers";

import * as WEBIFC from "web-ifc";
import { controlDataValue } from "../general-helpers";
import {
  getAllMAterialNames,
  getIdsElementByMaterialName,
} from "../material-handler";
import { reverseTranslateValue } from "../translate-handler";
import IfcPropertiesTranslation from "../../../traduction/ifcProperties.json";

export const getAllStoreys = (
  fragmentClassifier: OBC.FragmentClassifier
): Storey[] => {
  const listStoreysName: Storey[] = [];

  const byStoreys = fragmentClassifier.get()?.storeys;
  for (const [name, idElements] of Object.entries(byStoreys)) {
    const storeyToAdd: Storey = {
      displayName: name,
      uid: name,
      type: "Storey",
    };
    if (!listStoreysName.includes(storeyToAdd)) {
      listStoreysName.push(storeyToAdd);
    }
  }

  return listStoreysName;
};

export const getAllClasses = (
  fragmentClassifier: OBC.FragmentClassifier
): Class[] => {
  const listClasses: Class[] = [];

  const byClasses = fragmentClassifier.get()?.entities;
  for (const [name, idElements] of Object.entries(byClasses)) {
    const classToAdd: Class = {
      displayName: name,
      uid: name,
      type: "Class",
    };
    if (!listClasses.includes(classToAdd)) {
      listClasses.push(classToAdd);
    }
  }
  return listClasses;
};

export const indexEntityRelations = (model: any) => {
  const map: { [expressID: number]: Set<number> } = {};
  const { properties } = IfcPropertiesManager.getIFCInfo(model);
  IfcPropertiesUtils.getRelationMap(
    properties,
    WEBIFC.IFCRELDEFINESBYPROPERTIES,
    (relatingID, relatedIDs) => {
      if (!map[relatingID]) map[relatingID] = new Set();
      const props: number[] = [];
      IfcPropertiesUtils.getPsetProps(properties, relatingID, (propID) => {
        props.push(propID);
        map[relatingID].add(propID);
        if (!map[propID]) map[propID] = new Set();
        map[propID].add(relatingID);
      });
      IfcPropertiesUtils.getQsetQuantities(properties, relatingID, (propID) => {
        props.push(propID);
        map[relatingID].add(propID);
        if (!map[propID]) map[propID] = new Set();
        map[propID].add(relatingID);
      });
      for (const relatedID of relatedIDs) {
        map[relatingID].add(relatedID);
        for (const propID of props) map[propID].add(relatedID);
        if (!map[relatedID]) map[relatedID] = new Set();
        map[relatedID].add(relatedID);
      }
    }
  );

  const ifcRelations = [
    WEBIFC.IFCRELCONTAINEDINSPATIALSTRUCTURE,
    WEBIFC.IFCRELDEFINESBYTYPE,
    WEBIFC.IFCRELASSIGNSTOGROUP,
  ];

  for (const relation of ifcRelations) {
    IfcPropertiesUtils.getRelationMap(
      properties,
      relation,
      (relatingID, relatedIDs) => {
        if (!map[relatingID]) map[relatingID] = new Set();
        for (const relatedID of relatedIDs) {
          map[relatingID].add(relatedID);
          if (!map[relatedID]) map[relatedID] = new Set();
          map[relatedID].add(relatedID);
        }
      }
    );
  }

  const result: ModelProperties[] = [];
  const resultMap = new Map<string, Map<any, Set<number>>>();

  for (const [key, value] of Object.entries(map)) {
    const relationData = properties[+key];

    let propValue: string = "";

    const propertyNames = [
      "NominalValue",
      "AreaValue",
      "LengthValue",
      "VolumeValue",
    ];
    for (const propName of propertyNames) {
      if (relationData[propName]) {
        propValue = relationData[propName].value;
        break;
      }
    }

    if (propValue.toString()) {
      const name = relationData.Name.value;
      if (!resultMap.has(name)) {
        resultMap.set(name, new Map());
      }

      const valueMap = resultMap.get(name) as Map<any, Set<number>>;

      if (!valueMap.has(propValue)) {
        valueMap.set(propValue, new Set());
      }

      const arrayOfIds = valueMap.get(propValue) as Set<number>;

      for (const expressId of Array.from(value)) {
        const type = properties[+expressId]?.type;
        if (type) {
          if (
            OBC.IfcElements[type] &&
            OBC.IfcElements[type] !== "IFCBUILDINGSTOREY" &&
            OBC.IfcElements[type] !== "IFCBUILDING" &&
            OBC.IfcElements[type] !== "IFCSITE" &&
            OBC.IfcElements[type] !== "IFCPROJECT"
          ) {
            arrayOfIds.add(+expressId);
          }
        }
      }
    }
  }

  resultMap.forEach((values, name) => {
    for (const [modelId, setOfIds] of values) {
      if (setOfIds.size) {
        result.push({ name, values }); // Push values as a Map.
        break;
      }
    }
  });

  return result;
};

export const convertMapDataToStore = (
  mapData: Map<string, ModelProperties[]>
) => {
  const dataToStore = Array.from(mapData).map(([modelId, modelProperties]) => ({
    modelId,
    modelProperties: modelProperties.map((property) => ({
      name: property.name,
      values: Array.from(property.values).map(([value, set]) => ({
        value,
        set: Array.from(set),
      })),
    })),
  }));
  return dataToStore;
};

export const convertStoreDataToMapData = (
  firestoreData: ModelPropertiesToStore[]
) => {
  const mapData = new Map();
  for (const entry of firestoreData) {
    const key = entry.modelId;
    const modelProperties = entry.modelProperties.map((property) => ({
      name: property.name,
      values: new Map(
        property.values.map((value) => [value.value, new Set(value.set)])
      ),
    }));
    mapData.set(key, modelProperties);
  }
  return mapData;
};

export const getPropsAndValues = (
  allProperties: Map<string, ModelProperties[]>,
  allModels: any[],
  materialsWorkerLoaded: boolean
): PropsAndValues[] => {
  // properties :
  const listPropAndValues: PropsAndValues[] = [];
  allProperties.forEach((propByModel, modelId) => {
    for (const data of propByModel) {
      const { name, values } = data;
      let nominalValueSet: Set<any> = new Set(values.keys());
      // Check if the name already exists in the output array
      const existingData = listPropAndValues.find((item) => item.name === name);
      if (existingData) {
        const existingDataArray = [
          ...Array.from(existingData.values),
          ...Array.from(values.keys()),
        ];
        existingData.values = new Set(existingDataArray);
      } else {
        const newEntry = { name, values: nominalValueSet };
        listPropAndValues.push(newEntry);
      }
    }
  });

  // On ne charge les matériaux uniquement si le web workers des propriétés est achevé
  if (!materialsWorkerLoaded) {
    console.log("Mat loaded from ifc file");
    let setOfAllMaterialNames = new Set<string>();
    for (const model of allModels) {
      setOfAllMaterialNames = new Set<string>([
        ...setOfAllMaterialNames,
        ...getAllMAterialNames(model.properties),
      ]);
    }

    listPropAndValues.push({
      name: "Materials",
      values: setOfAllMaterialNames,
    });
  }

  return listPropAndValues;
};

export const getAllEntitiesIdMap = (
  allElementsIdMap: FragmentIdMap,
  allSpacesIdMap: FragmentIdMap
) => {
  const allElementsAndSpacesIdMap = unionFragmentIdMaps(
    allElementsIdMap,
    allSpacesIdMap
  );
  return allElementsAndSpacesIdMap;
};

export const getFittingElementsFromFilter = async (
  listSelectedItemFromFilter: Class[] | Storey[],
  fragmentClassifier: OBC.FragmentClassifier,
  allElementsAndSpacesIdMap: OBC.FragmentIdMap
): Promise<FragmentIdMap> => {
  let fittingElementsFromFilter: FragmentIdMap = {};

  for (const selectedItem of listSelectedItemFromFilter) {
    const selectItemName: string = selectedItem.displayName;
    let elementsFound: FragmentIdMap = {};
    if (selectedItem.type === "Class") {
      elementsFound = fragmentClassifier.find({
        entities: [selectItemName],
      });
    } else if (selectedItem.type === "Storey") {
      elementsFound = fragmentClassifier.find({
        storeys: [selectItemName],
      });
    }
    fittingElementsFromFilter = unionFragmentIdMaps(
      fittingElementsFromFilter,
      elementsFound
    );

    // On filtre sur les modèles visibles
    fittingElementsFromFilter = intersectFragmentIdMaps(
      allElementsAndSpacesIdMap,
      fittingElementsFromFilter
    );
  }
  return fittingElementsFromFilter;
};
// Récupère les ids qui matcth avec la condition "égalité" (la condition different est traité après : c'est l'inverse de matchingIds)
export const getMatchingIdsFromQueryGroup = (
  queryGroup: FilterQueryGroup,
  allProperties: Map<string, ModelProperties[]>,
  allModels: any[],
  materialsWorkerLoaded: boolean,
  selectedLanguage: string
) => {
  const matchingIdsQueryGroup = new Map<string, Set<number>>();
  // Un nom par queryGroup
  const propName = reverseTranslateValue(
    selectedLanguage,
    IfcPropertiesTranslation,
    queryGroup.queries[0].propName
  );
  const condition = queryGroup.condition;
  const conditionsWithInput = ["includes", "not-includes", "greater", "lower"];
  if (conditionsWithInput.includes(condition)) {
    // queryGroup ne contient qu'une query
    const word = queryGroup.queries[0].propValue;
    let matchingValues: any[] = [];
    if (condition === "includes" || condition === "not-includes") {
      allProperties.forEach((propByModel, modelId) => {
        for (const prop of propByModel) {
          if (prop.name !== propName) continue;
          const listOfValues = Array.from(prop.values.keys());
          matchingValues = [
            ...matchingValues,
            ...listOfValues.filter((value) =>
              value.toString().toLowerCase().includes(word)
            ),
          ];
        }
      });
    } else {
      // cas d'une propriété numérique
      const wordToNumber: number = Number(word);
      if (!wordToNumber) return matchingIdsQueryGroup;
      allProperties.forEach((propByModel, modelId) => {
        for (const prop of propByModel) {
          if (prop.name !== propName) continue;

          const listOfValues = Array.from(prop.values.keys());
          matchingValues = [
            ...matchingValues,
            ...listOfValues.filter((value) => {
              const numericValue = Number(value);
              return (
                (condition === "greater" && numericValue > wordToNumber) ||
                (condition === "lower" && numericValue < wordToNumber)
              );
            }),
          ];
        }
      });
    }

    // on transforme le queryGroup en ajoutant les nouvelles queries et en transformant la condition
    let newFilterQueries: FilterQuery[] = [];

    // Suppression des doublons
    let uniqueValuesSet = new Set(matchingValues);
    matchingValues = Array.from(uniqueValuesSet);

    for (const matchingValue of matchingValues) {
      const newQuery: FilterQuery = {
        propName,
        propValue: matchingValue,
      };
      newFilterQueries.push(newQuery);
    }
    queryGroup.queries = newFilterQueries;
  }

  // cas des Matériaux traités à part quand le prop worker n'est pas achevé
  if (
    !materialsWorkerLoaded &&
    (propName === "Materials" || propName === "Matériaux")
  ) {
    for (const query of queryGroup.queries) {
      const propValue = controlDataValue(query.propValue as string) as string;
      for (const model of allModels) {
        const matchingIdsBypropValue = getIdsElementByMaterialName(
          model.properties,
          propValue
        );
        if (!matchingIdsBypropValue) continue;

        if (!matchingIdsQueryGroup.has(model.uuid)) {
          matchingIdsQueryGroup.set(
            model.uuid,
            new Set(matchingIdsBypropValue)
          );
        } else {
          const existingSet =
            matchingIdsQueryGroup.get(model.uuid) || new Set<number>();

          matchingIdsQueryGroup.set(
            model.uuid,
            new Set([
              ...Array.from(existingSet),
              ...Array.from(matchingIdsBypropValue),
            ])
          );
        }
      }
    }
  } else {
    for (const query of queryGroup.queries) {
      const propValue = controlDataValue(query.propValue as string);
      allProperties.forEach((propByModel, modelId) => {
        for (const prop of propByModel) {
          if (prop.name !== propName) continue;
          // On test sur la valeur convertie et la valeur d'origine : ex "1980" peut être converti
          if (
            !prop.values.has(propValue) &&
            !prop.values.get(propValue) &&
            !prop.values.get(query.propValue)
          )
            continue;

          const matchingIdsBypropValue: Set<number> | undefined =
            prop.values.get(propValue)
              ? prop.values.get(propValue)
              : prop.values.get(query.propValue);
          if (!matchingIdsBypropValue) continue;
          // TODO Condition à gérer. Ici cas d'égalité uniquement
          if (!matchingIdsQueryGroup.has(modelId)) {
            matchingIdsQueryGroup.set(modelId, matchingIdsBypropValue);
          } else {
            const existingSet =
              matchingIdsQueryGroup.get(modelId) || new Set<number>();

            matchingIdsQueryGroup.set(
              modelId,
              new Set([
                ...Array.from(existingSet),
                ...Array.from(matchingIdsBypropValue),
              ])
            );
          }
        }
      });
    }
  }

  return matchingIdsQueryGroup;
};
