export class RightClick {
  private canvas: HTMLDivElement;
  public start: number | undefined;
  public stop: number | undefined;

  constructor(canvas: HTMLDivElement) {
    this.canvas = canvas;
    this.setupRightClick();
  }

  private setupRightClick() {
    if (!this.canvas) return;
    this.canvas.addEventListener("mousedown", (e) => {
      if (e.button === 2) {
        this.start = window.performance.now();
      }
    });

    this.canvas.addEventListener("mouseup", (e) => {
      if (e.button === 2) {
        this.stop = window.performance.now();
      }
    });
  }
}
