import { FC, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as OBC from "openbim-components";

import styles from "./menu-content.module.css";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import { useAppContext } from "../../../middleware/context-provider";
import { Navigate } from "react-router-dom";

export const MeasureContent: FC = () => {
  const [state, dispatch] = useAppContext();
  const [lengthDimensionEnabled, setLengthDimensionEnabled] =
    useState<boolean>(false);

  const enableLengthDimension = () => {
    dispatch({ type: "TOGGLE_LENGTH_DIMENSION", payload: true });
    setLengthDimensionEnabled(true);
  };

  const disableLengthDimension = () => {
    dispatch({ type: "TOGGLE_LENGTH_DIMENSION", payload: false });
    setLengthDimensionEnabled(false);
  };

  const removeDimension = (dimension: OBC.SimpleDimensionLine) => {
    dispatch({ type: "REMOVE_LENGTH_DIMENSION", payload: dimension });
  };

  if (!state.project) {
    return <Navigate to="/projects" />;
  }
  const activeLengthDimensions = state.activeLengthDimensions;

  return (
    <div className={styles["content"]}>
      <div className={styles["container-two-el-space"]}>
        {lengthDimensionEnabled ? (
          <>
            <IconButton onClick={disableLengthDimension}>
              <DoneIcon />
            </IconButton>
            <span className={styles["small-font"]}>
              Cliquer sur une face pour définir un plan de coupe
            </span>
          </>
        ) : (
          <IconButton onClick={enableLengthDimension}>
            <AddCircleOutlineIcon style={{ fontSize: 25, color: "#2d3e50" }} />
          </IconButton>
        )}
      </div>
      <hr className={styles["hr-margin-bottom"]} />
      <div className={styles["scroll-content"]}>
        {activeLengthDimensions &&
          activeLengthDimensions.map((dimension, index) => {
            return (
              <div
                className={styles["list-item"]}
                key={dimension.label.get().uuid}
              >
                <IconButton
                  onClick={() => {
                    removeDimension(dimension);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <span className={styles["margin-left"]}>
                  {`Mesure_${index + 1}`}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};
