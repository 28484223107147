import { convertArrayToIdMap } from "./../utils/fragmentIdMap-helpers";
import {
  getAllStoreys,
  getAllClasses,
  getFittingElementsFromFilter,
  indexEntityRelations,
  getPropsAndValues,
  getMatchingIdsFromQueryGroup,
  convertMapDataToStore,
  convertStoreDataToMapData,
  getAllEntitiesIdMap,
} from "../utils/filter/filter-handler";
// import { Project } from "./../../types";
// import { Model } from "../../types";
import * as OBC from "openbim-components";
import * as THREE from "three";
import { downloadZip } from "client-zip";
import { unzip } from "unzipit";
import {
  Project,
  Plan,
  Class,
  Storey,
  VisibilityState,
  ModelProperties,
  FilterQueryGroup,
  ChangeMap,
  Uint8ArrayMap,
  MultiSelectDataType,
  SavedFilter,
} from "../../types";
import { ModelDatabase } from "./model-database";
import { LeftClick } from "../utils/left-click";

import { Events } from "../../middleware/event-handler";
// import { PlanView } from "openbim-components/fragments/FragmentPlans/src/types";
import { PlanView } from "openbim-components/src/fragments/FragmentPlans/src/types";
import { FragmentIdMap } from "openbim-components";
import * as WEBIFC from "web-ifc";
import {
  findFragmentModel,
  intersectFragmentIdMaps,
  isFragmentIdMapEmpty,
  subtractFragmentIdMaps,
  toFragmentMap,
  unionFragmentIdMaps,
} from "../utils/fragmentIdMap-helpers";
import IfcPropertiesTranslation from "../../traduction/ifcProperties.json";
import {
  reverseTranslateListOfClasses,
  reverseTranslateValue,
  translateListOfClasses,
  translateProps,
} from "../utils/translate-handler";

import { sortElementsByType } from "../utils/filter/filter-result-handler";
import {
  createExcelTable,
  getAllPropsOfSelectedElement,
  getBQFromElementId,
  getPropsToExport,
} from "../utils/properties/properties-helpers";

export class ModelScene {
  private events: Events;
  private components: OBC.Components;
  private fragments: OBC.FragmentManager;
  private fragmentIfcLoader: OBC.FragmentIfcLoader;
  private allModels: any[];
  private allModelsNameAndId: { modelId: string; displayName: string }[];
  private uint8ArrayMap: Uint8ArrayMap;
  private factorUnits: number;
  private fragmentHider: OBC.FragmentHider;
  private allElementsIdMap: OBC.FragmentIdMap;
  private allSpacesIdMap: OBC.FragmentIdMap;
  private isSpacesVisible: boolean;
  private visibilityData: VisibilityState;
  private allProperties: Map<string, ModelProperties[]>;
  private allMaterials: Map<string, ModelProperties[]>;
  private fragmentPlans: OBC.FragmentPlans;
  private postProdRenderer: OBC.PostproductionRenderer;
  private isPostProdActive: boolean;
  private cameraComponent: OBC.SimpleCamera;
  private culler: OBC.ScreenCuller;
  private multiSelectionMode: boolean;
  private multiSelectData: MultiSelectDataType;
  private highlighter: OBC.FragmentHighlighter;
  private defaultHighlightColor: string;
  private transparentColor: string;
  private propertiesProcessor: OBC.IfcPropertiesProcessor;
  private propertiesManager: OBC.IfcPropertiesManager;
  private clipper: OBC.EdgesClipper;
  private lengthDimension: OBC.LengthMeasurement;
  private fragmentBbox: OBC.FragmentBoundingBox;
  private bboxMesh: THREE.Mesh<
    THREE.BoxGeometry,
    THREE.Material | THREE.Material[]
  >;
  private materialManager: OBC.MaterialManager;
  database = new ModelDatabase();
  clickHandler: LeftClick;
  private sceneEvents: { name: any; action: any; type: string }[] = [];

  private materialWorker: Worker;
  private materialsWorkerLoaded: boolean;

  private selectedLanguage: string;

  get container() {
    const domElement = this.components.renderer.get().domElement;
    return domElement.parentElement as HTMLDivElement;
  }

  constructor(container: HTMLDivElement, project: Project, events: Events) {
    this.events = events;
    this.components = new OBC.Components();
    const sceneComponent = new OBC.SimpleScene(this.components);
    const scene = sceneComponent.get();

    this.components.scene = sceneComponent;
    this.postProdRenderer = new OBC.PostproductionRenderer(
      this.components,
      container
    );
    this.components.renderer = this.postProdRenderer;
    this.components.uiEnabled = false;

    this.cameraComponent = new OBC.OrthoPerspectiveCamera(this.components);
    this.components.camera = this.cameraComponent;
    this.components.raycaster = new OBC.SimpleRaycaster(this.components);
    this.components.init();
    this.lengthDimension = new OBC.LengthMeasurement(this.components);
    this.lengthDimension.snapDistance = 1;

    this.components.scene.get().background = new THREE.Color("#262c32");
    scene.background = null;
    const directionalLight = new THREE.DirectionalLight();
    directionalLight.position.set(5, 10, 3);
    directionalLight.intensity = 0.5;
    scene.add(directionalLight);
    const ambientLight = new THREE.AmbientLight();
    ambientLight.intensity = 0.5;
    scene.add(ambientLight);

    this.clickHandler = new LeftClick(
      this.components.renderer.get().domElement
    );

    this.fragments = new OBC.FragmentManager(this.components);
    this.allModels = [];
    this.allModelsNameAndId = [];
    this.uint8ArrayMap = {};
    this.factorUnits = 1;
    this.allElementsIdMap = {};
    this.allSpacesIdMap = {};
    this.isSpacesVisible = false;
    this.visibilityData = {
      visibleModels: [],
      visibleElements: {},
      visibleSpaces: {},
      selectedElements: {},
      selectedSpaces: {},
      hasHiddenElements: false,
      isolated: false,
      highlight: false,
    };

    this.allProperties = new Map<string, any>();
    this.allMaterials = new Map<string, any>();
    this.fragmentHider = new OBC.FragmentHider(this.components);
    this.multiSelectionMode = false;
    this.multiSelectData = {
      globalIds: new Set(),
      elementsByType: new Map(),
    };
    this.highlighter = new OBC.FragmentHighlighter(this.components);
    this.defaultHighlightColor = "#5bde99";
    this.transparentColor = "#eeeeee";
    this.propertiesProcessor = new OBC.IfcPropertiesProcessor(this.components);
    this.propertiesManager = new OBC.IfcPropertiesManager(this.components);

    this.fragmentPlans = new OBC.FragmentPlans(this.components);
    this.propertiesProcessor.propertiesManager = this.propertiesManager;
    this.culler = new OBC.ScreenCuller(this.components);
    this.clipper = new OBC.EdgesClipper(this.components);

    this.fragmentBbox = new OBC.FragmentBoundingBox(this.components);
    this.bboxMesh = this.fragmentBbox.getMesh();

    this.materialManager = new OBC.MaterialManager(this.components);

    this.fragmentIfcLoader = new OBC.FragmentIfcLoader(this.components);

    this.selectedLanguage = "fr";

    this.materialWorker = new Worker(
      new URL("../../materialWorker.ts", import.meta.url)
    );
    this.materialsWorkerLoaded = false;
    this.setupIfcLoader();
    this.loadAllModels(project);
    this.setUpCulling();
    this.isPostProdActive = false;
    this.setUpPostProduction(false);
    this.setUpHighlight();
    this.setupEvents();
  }

  dispose() {
    this.toggleEvents(false);
    this.allModels = [];
    // this.fragmentPlans.exitPlanView();
    // (this.fragmentPlans as any) = null;
    // this.deleteAllClippingPlanes();
    this.initMaterial();
    this.culler.dispose();
    this.highlighter.dispose();
    this.fragmentBbox.dispose();
    this.materialManager.dispose();
    // this.components.dispose();
    this.fragments.dispose();
    (this.components as any) = null;
    (this.fragments as any) = null;
    (this.fragmentIfcLoader as any) = null;
    (this.fragmentHider as any) = null;
    (this.propertiesManager as any) = null;
    (this.propertiesProcessor as any) = null;
    (this.clipper as any) = null;
    (this.lengthDimension as any) = null;

    this.allElementsIdMap = {};
    this.allSpacesIdMap = {};
    this.visibilityData = {
      visibleModels: [],
      visibleElements: {},
      visibleSpaces: {},
      selectedElements: {},
      selectedSpaces: {},
      hasHiddenElements: false,
      isolated: false,
      highlight: false,
    };
  }

  private setupEvents() {
    this.sceneEvents = [
      {
        name: "dblclick",
        action: async () => await this.highlightOnClick(),
        type: "scene",
      },
      { name: "click", action: this.createClippingPlane, type: "scene" },
      { name: "click", action: this.createLengthDimension, type: "scene" },
      { name: "keydown", action: this.cancelLengthDimension, type: "window" },
    ];
    this.toggleEvents(true);
  }

  private toggleEvents(active: boolean) {
    for (const event of this.sceneEvents) {
      if (event.type === "scene") {
        if (active) {
          this.components.renderer
            .get()
            .domElement.addEventListener(event.name, event.action);
        } else {
          this.components.renderer
            .get()
            .domElement.removeEventListener(event.name, event.action);
        }
      } else {
        if (active) {
          window.addEventListener(event.name, event.action);
        } else {
          window.removeEventListener(event.name, event.action);
        }
      }
    }
  }

  // CLIPPING PLANE TOOL

  toggleClippingPlanes = (active: boolean) => {
    const clipper = this.components.tools.get(OBC.EdgesClipper);
    if (clipper) {
      if (active) {
        this.setUpEdgesSection();
      } else {
        clipper.styles.deleteStyle("sectionStyle", true);
      }
      clipper.enabled = active;
    }
  };

  deleteAllClippingPlanes = () => {
    const clipper = this.components.tools.get(OBC.EdgesClipper);
    if (clipper) {
      clipper.deleteAll();
      this.toggleClippingPlanes(false);
    }
  };

  private createClippingPlane = () => {
    const clipper = this.components.tools.get(OBC.EdgesClipper);
    if (!this.clickHandler.isLongClick) {
      clipper.create();
      this.events.trigger({
        type: "GET_CLIPPERS",
        payload: clipper.get(),
      });
    }
  };

  setUpEdgesSection = () => {
    const clipper = this.components.tools.get(OBC.EdgesClipper);
    const sectionFill = new THREE.MeshBasicMaterial({
      color: "#e7d075",
      side: 2,
    });
    const sectionLine = new THREE.LineBasicMaterial({ color: "#000000" });
    const sectionOutline = new THREE.MeshBasicMaterial({
      color: "#000000",
      opacity: 0.2,
      side: 2,
      transparent: true,
    });
    clipper.styles.create(
      "sectionStyle",
      new Set(this.components.meshes),
      sectionLine,
      sectionFill,
      sectionOutline
    );
  };

  removeClipper = (clipperPlane: OBC.EdgesPlane) => {
    const clipper = this.components.tools.get(OBC.EdgesClipper);
    if (clipper) {
      clipper.delete(clipperPlane);
      this.events.trigger({
        type: "GET_CLIPPERS",
        payload: clipper.get(),
      });
    }
  };

  // END CLIPPING PLANE TOOL \\

  // LENGTH MEASUREMENT TOOL
  toggleLengthDimensions = (active: boolean) => {
    if (this.lengthDimension) {
      this.lengthDimension.enabled = active;
      const canvasElement = document.querySelector(
        "canvas"
      ) as HTMLCanvasElement | null;
      if (!canvasElement) return;
      if (active) {
        canvasElement.style.cursor = "crosshair";
      } else {
        canvasElement.style.cursor = "auto";
      }
    }
  };

  deleteAllLengthDimensions = () => {
    if (this.lengthDimension) {
      this.lengthDimension.deleteAll();
      this.toggleLengthDimensions(false);
    }
  };

  private createLengthDimension = () => {
    if (this.lengthDimension.enabled && !this.clickHandler.isLongClick) {
      this.lengthDimension.create();
      this.events.trigger({
        type: "GET_LENGTH_DIMENSION",
        payload: this.lengthDimension.get(),
      });
    }
  };

  private cancelLengthDimension = (event: KeyboardEvent) => {
    if (this.lengthDimension.enabled && event.code === "Escape") {
      this.lengthDimension.cancelCreation();
    }
  };

  removeLengthDimension = (dimension: OBC.SimpleDimensionLine) => {
    dimension.dispose();
    let listLengthDimension = this.lengthDimension.get();
    const index = listLengthDimension.indexOf(dimension);
    listLengthDimension = listLengthDimension.splice(index, 1);
    this.events.trigger({
      type: "GET_LENGTH_DIMENSION",
      payload: this.lengthDimension.get(),
    });
  };

  // END LENGTH MEASUREMENT TOOL \\

  // PLAN MODE \\
  setUpEdgesPlan = () => {
    const sectionMaterial = new THREE.LineBasicMaterial({ color: "black" });
    const fillMaterial = new THREE.MeshBasicMaterial({
      color: "gray",
      side: 2,
    });
    const fillOutline = new THREE.MeshBasicMaterial({
      color: "black",
      side: 1,
      opacity: 0.5,
      transparent: true,
    });

    this.clipper.styles.create(
      "filled",
      new Set(),
      sectionMaterial,
      fillMaterial,
      fillOutline
    );
    this.clipper.styles.create("projected", new Set(), sectionMaterial);
    sectionMaterial.dispose();
    fillMaterial.dispose();
    fillOutline.dispose();
  };

  applyEdgesStyleToMeshes = () => {
    const styles = this.clipper.styles.get();
    const fragmentClassifier = this.components.tools.get(
      OBC.FragmentClassifier
    );
    fragmentClassifier.byEntity(this.allModels[0]);
    const found = fragmentClassifier.find({
      entities: ["IFCWALLSTANDARDCASE", "IFCWALL"],
    });
    for (const fragID in found) {
      const { mesh } = this.fragments.list[fragID];
      styles.filled.fragments[fragID] = new Set(found[fragID]);
      styles.filled.meshes.add(mesh);
    }

    const meshes = [];
    for (const fragment of this.allModels[0].items) {
      const { mesh } = fragment;
      meshes.push(mesh);
      styles.projected.meshes.add(mesh);
    }

    this.setupWhiteMaterial();
  };

  getPlans = async () => {
    if (!this.fragmentPlans.get().length) {
      await this.fragmentPlans.computeAllPlanViews(this.allModels[0]);
    }
    const listPlans: Plan[] = [];
    for (const plane of this.fragmentPlans.get()) {
      const height = Math.trunc(plane.point.y * 10) / 10;
      listPlans.push({ id: plane.id, name: plane.name, height: height });
    }
    this.events.trigger({ type: "GET_PLANS", payload: listPlans });
  };

  displayPlan = async (planId: string) => {
    if (!this.fragmentPlans.get().length) return;

    this.setUpEdgesPlan();
    this.applyEdgesStyleToMeshes();
    const whiteColor = new THREE.Color("white");
    this.postProdRenderer.postproduction.customEffects.glossEnabled = false;
    this.materialManager.setBackgroundColor(whiteColor);
    await this.fragmentPlans.goTo(planId, true);
  };

  exitPlan = async () => {
    this.postProdRenderer.postproduction.customEffects.glossEnabled = true;
    this.materialManager.resetBackgroundColor();
    this.initMaterial();
    await this.fragmentPlans.exitPlanView(true);
  };

  exportToDxf = async (planId: string) => {
    const dxfExporter = this.components.tools.get(OBC.DXFExporter);
    const listPlans: PlanView[] = this.fragmentPlans.get();
    if (!listPlans) return;
    const plan: PlanView | undefined = listPlans.find(
      (plan) => plan.id === planId
    );
    if (!plan) return;
    const link = document.createElement("a");
    const result = await dxfExporter.export(plan.name);
    const fileName = `${plan.name}.dxf`;
    const file = new File([new Blob([result])], fileName);
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    link.click();
    link.remove();

    dxfExporter.dispose();
  };

  // END PLAN MODE \\

  toggleBackgroundScene(active: boolean) {
    if (active) {
      this.components.scene.get().background = new THREE.Color("#ffffff");
    } else {
      this.components.scene.get().background = new THREE.Color("#262c32");
    }
  }

  toggleLangue(selectedLang: string) {
    this.selectedLanguage = selectedLang;
  }

  toggleMultiSelection(active: boolean) {
    this.multiSelectionMode = active;
    this.events.trigger({ type: "DESELECTED_ELEMENT" });
    this.visibilityData = {
      ...this.visibilityData,
      selectedElements: {},
      selectedSpaces: {},
    };
  }

  private async highlightOnClick() {
    let lastSelectionFragmentIdMap: FragmentIdMap = {};
    let singleSelection = {
      value: !this.multiSelectionMode,
    };
    const result = await this.highlighter.highlight(
      this.defaultHighlightColor,
      singleSelection.value
    );

    if (!result) {
      this.resetMultiSelectionData();
      return;
    }

    for (const fragment of result.fragments) {
      const fragmentID = fragment.id;
      lastSelectionFragmentIdMap[fragmentID] = new Set<string>();
      lastSelectionFragmentIdMap[fragmentID].add(result.id);
    }
    this.handleVisibilityDataByPick(lastSelectionFragmentIdMap);

    const model = findFragmentModel(this.fragments, result.fragments[0].id);

    // All Properties
    if (!this.multiSelectionMode) {
      const elementProps = getAllPropsOfSelectedElement(
        model,
        this.propertiesProcessor,
        lastSelectionFragmentIdMap,
        this.selectedLanguage,
        this.factorUnits
      );
      this.events.trigger({
        type: "GET_ELEMENT_PROPS",
        payload: elementProps,
      });
    } else {
      const selectedElementsByType = sortElementsByType(
        this.fragments,
        unionFragmentIdMaps(
          this.visibilityData.selectedElements,
          this.visibilityData.selectedSpaces
        ),
        this.selectedLanguage
      );

      const expressId = result.id;
      const element = model.properties[+expressId];

      const exists = Array.from(this.multiSelectData.globalIds).some(
        (item) => item.expressId === result.id
      );
      if (!exists) {
        this.multiSelectData.globalIds.add({
          expressId: result.id,
          globalId: element.GlobalId.value,
        });
      }
      this.multiSelectData.elementsByType = selectedElementsByType;
      this.events.trigger({
        type: "GET_MULTI_SELECT_DATA",
        payload: this.multiSelectData,
      });
      this.events.trigger({ type: "CLOSE_MULTI_SELECT_BQ" });
    }
  }

  resetMultiSelectionData() {
    this.events.trigger({ type: "DESELECTED_ELEMENT" });
    this.multiSelectData = {
      globalIds: new Set(),
      elementsByType: new Map(),
    };
    this.visibilityData = {
      ...this.visibilityData,
      selectedElements: {},
      selectedSpaces: {},
    };
  }

  clearMultiSelectData() {
    this.multiSelectData = {
      globalIds: new Set(),
      elementsByType: new Map(),
    };
  }

  handleVisibilityDataByPick(selectedElementIdMap: FragmentIdMap) {
    if (!this.multiSelectionMode) {
      this.visibilityData = {
        ...this.visibilityData,
        selectedElements: {},
        selectedSpaces: {},
      };
    }
    if (this.checkIfSpaces(selectedElementIdMap)) {
      const selectedSpacesIdMap = unionFragmentIdMaps(
        selectedElementIdMap,
        this.visibilityData.selectedSpaces
      );
      this.visibilityData.selectedSpaces = selectedSpacesIdMap;
    } else {
      const selectedElementsIdMap = unionFragmentIdMaps(
        selectedElementIdMap,
        this.visibilityData.selectedElements
      );
      this.visibilityData.selectedElements = selectedElementsIdMap;
    }
    this.sendVisibilityData();
  }

  highlightParentElement(parentId: number, modelId: string) {
    const model = this.fragments.groups.find((m) => m.uuid === modelId);
    if (!model) return;
    if (!model.properties) return;

    const storeyTypeNumber = 3124254112;
    const parentResult = model.properties[Number(parentId)];
    const parentType = parentResult.type;
    let allChildrenIdMap: FragmentIdMap = {};
    if (parentType === storeyTypeNumber) {
      const fragmentClassifier = this.components.tools.get(
        OBC.FragmentClassifier
      );
      const storeyName = parentResult.Name.value;
      allChildrenIdMap = fragmentClassifier.find({
        storeys: [storeyName],
      });
    } else {
      const relAggregatesTypeNumber = 160246688;
      const childrenFromAggregatesIdMap = this.getChildrenIdMap(
        parentId,
        model,
        relAggregatesTypeNumber
      );
      const relSpatialStructureTypeNumber = 3242617779;
      const childrenFromStructureIdMap = this.getChildrenIdMap(
        parentId,
        model,
        relSpatialStructureTypeNumber
      );
      if (childrenFromAggregatesIdMap) {
        allChildrenIdMap = unionFragmentIdMaps(
          allChildrenIdMap,
          childrenFromAggregatesIdMap
        );
      }
      if (childrenFromStructureIdMap) {
        allChildrenIdMap = unionFragmentIdMaps(
          allChildrenIdMap,
          childrenFromStructureIdMap
        );
      }
    }

    this.visibilityData = {
      ...this.visibilityData,
      selectedElements: allChildrenIdMap,
    };
    this.highlighter.highlightByID(
      this.defaultHighlightColor,
      allChildrenIdMap
    );
    return this.sendVisibilityData();
  }

  hasChildren(expressId: number, model: any) {
    const allRelAggregates = OBC.IfcPropertiesUtils.getAllItemsOfType(
      model.properties,
      160246688
    );
    for (const ifcRelAggregate of allRelAggregates) {
      const relatingObject = ifcRelAggregate.RelatingObject;
      if (relatingObject.value === expressId) return true;
    }
    return false;
  }

  getChildrenIdMap(parentId: number, model: any, relTypeNumber: number) {
    let setOfIds = this.getChildrenIds(+parentId, model, relTypeNumber);
    if (!setOfIds) return;
    const ifcRelAggregateTypeNumber = 160246688;
    for (const expressId of setOfIds) {
      if (!this.hasChildren(expressId, model)) continue;
      const setOfChildrenIds = this.getChildrenIds(
        expressId,
        model,
        ifcRelAggregateTypeNumber
      );
      if (!setOfChildrenIds) continue;
      setOfIds = new Set([...setOfIds, ...setOfChildrenIds]);
    }
    const childrenDataToConvert = new Map([[model.uuid, setOfIds]]);
    const childrenIdMap = toFragmentMap(this.fragments, childrenDataToConvert);
    return childrenIdMap;
  }

  getChildrenIds(parentId: number, model: any, relTypeNumber: number) {
    const allRelOfType = OBC.IfcPropertiesUtils.getAllItemsOfType(
      model.properties,
      relTypeNumber
    );
    for (const relOfType of allRelOfType) {
      let relatingObject =
        relOfType.RelatingObject || relOfType.RelatingStructure;
      let listRelatedObjects =
        relOfType.RelatedObjects || relOfType.RelatedElements;
      if (relatingObject.value !== parentId) continue;
      const setOfIds = new Set<number>(
        listRelatedObjects.map((object: any) => object.value)
      );
      return setOfIds;
    }
  }

  deselectElement = () => {
    this.highlighter.clear(this.defaultHighlightColor);
    this.visibilityData = {
      ...this.visibilityData,
      selectedElements: {},
      selectedSpaces: {},
    };
    this.sendVisibilityData();
  };

  async convertIfcToFragments(listIfc: File[]) {
    let fragments = this.components.tools.get(OBC.FragmentManager);
    // let fragments = new OBC.FragmentManager(this.components);
    const listZipFile: Blob[] = [];
    for (const ifcFile of listIfc) {
      const data = await ifcFile.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await this.fragmentIfcLoader.load(buffer, "ifc");

      const scene = this.components.scene.get();
      scene.add(model);

      if (!model) return;
      const dataToStore = fragments.export(model);
      const blob = new Blob([dataToStore]);
      const fragmentFile = new File([blob], "model.frag");
      const files = [];
      files.push(fragmentFile);
      files.push(new File([JSON.stringify(model.properties)], "model.json"));
      const zipFile = await downloadZip(files).blob();
      listZipFile.push(zipFile);
    }

    fragments.dispose();
    (fragments as any) = null;

    return listZipFile as File[];
  }

  private setupIfcLoader = () => {
    this.fragmentIfcLoader.settings.wasm = {
      path: "https://unpkg.com/web-ifc@0.0.46/",
      absolute: true,
    };

    this.fragmentIfcLoader.settings.webIfc = {
      COORDINATE_TO_ORIGIN: true,
      USE_FAST_BOOLS: false,
      OPTIMIZE_PROFILES: true,
    };
  };

  private async loadAllModels(project: Project) {
    if (!project.models) return;
    const modelsURL = await this.database.getModels(project);

    const listBuffers: Uint8Array[] = [];
    const listProperties: any[] = [];
    for (const url of modelsURL) {
      const { entries } = await unzip(url);

      const fileNames = Object.keys(entries);
      for (let i = 0; i < fileNames.length; i++) {
        const fileName = fileNames[i];
        const entry = entries[fileName];
        if (!fileName.includes(".frag")) continue;
        const jsonFileName = fileName.replace(".frag", ".json");
        const jsonEntry = entries[jsonFileName];
        const blob = await entry.blob();
        const jsonBlob = await jsonEntry.blob();
        const file = new File([blob], fileName);
        const data = await file.arrayBuffer();
        const buffer = new Uint8Array(data);
        listBuffers.push(buffer);
        //Properties
        const jsonFile = new File([jsonBlob], jsonFileName);
        const blobUrl = URL.createObjectURL(jsonFile);
        try {
          const response = await fetch(blobUrl);
          if (response.ok) {
            const properties = await response.json();
            listProperties.push(properties);
          } else {
            console.error(
              `Error fetching data from ${jsonFileName}: ${response.status} ${response.statusText}`
            );
          }
        } catch (error) {
          console.error(`Error fetching data from ${jsonFileName}: ${error}`);
        } finally {
          URL.revokeObjectURL(blobUrl);
        }
      }
    }
    // Affichage des modèles + insertion des propriétés dans les modèles + culling
    for (let i = 0; i < listBuffers.length; i++) {
      const model = await this.fragments.load(listBuffers[i]);
      this.uint8ArrayMap[model.uuid] = listBuffers[i];
      if (!this.allModels.length) {
        this.fragments.baseCoordinationModel = model.uuid;
      } else {
        this.fragments.coordinate([model]);
      }
      this.allModels.push(model);
      this.fragmentBbox.add(model);
      model.properties = listProperties[i];
      this.propertiesProcessor.process(model);
      if (model.properties) {
        this.factorUnits = OBC.IfcPropertiesUtils.getUnits(model.properties);
      }

      const meshes = [];
      for (const fragment of model.items) {
        meshes.push(fragment.mesh);
        this.culler.add(fragment.mesh);
      }
    }
    this.highlighter.update();

    await this.fragmentHider.loadCached();
    const fragmentClassifier = this.components.tools.get(
      OBC.FragmentClassifier
    );

    for (let i = 0; i < this.allModels.length; i++) {
      this.allModelsNameAndId.push({
        modelId: this.allModels[i].uuid,
        displayName: "",
      });
      fragmentClassifier.byModel(this.allModels[i].uuid, this.allModels[i]);
      fragmentClassifier.byEntity(this.allModels[i]);
      fragmentClassifier.byStorey(this.allModels[i]);
    }

    for (let i = 0; i < project.models.length; i++) {
      this.allModelsNameAndId[i].displayName = project.models[i].displayName;
    }

    this.getAllElementsAndSpaces(true);
    this.visibilityData = {
      ...this.visibilityData,
      visibleModels: this.allModelsNameAndId,
      visibleElements: this.allElementsIdMap,
    };
    this.toggleSpacesVisibility(false);

    this.sendVisibilityData();

    this.bboxMesh = this.fragmentBbox.getMesh();
    this.fragmentBbox.reset();

    await this.loadAndStoreProperties(project);
  }

  async loadAndStoreProperties(project: Project) {
    if (this.allModels.length) {
      const storedAllProperties = await this.database.getAllData(
        project.uid,
        "properties"
      );

      const storedAllMaterials = await this.database.getAllData(
        project.uid,
        "materials"
      );
      // Cas du premier chargement
      if (!storedAllProperties || !storedAllProperties.size) {
        console.log(storedAllProperties);
        //Chargement des propriétés et enregistrement en base
        this.loadPropertiesData(project.uid);
      } else {
        this.allProperties = storedAllProperties;
      }

      // Cas du premier chargement
      if (!storedAllMaterials || !storedAllMaterials.size) {
        // début gestion du chargement des matériaux
        const listModelUids: string[] = [];
        const listModelProperties: any[] = [];
        for (const model of this.allModels) {
          listModelUids.push(model.uuid);
          listModelProperties.push(model.properties);
        }
        this.materialWorker.postMessage({
          listModelUids,
          listModelProperties,
        });

        this.materialWorker.onmessage = (event) => {
          this.allMaterials = event.data;
          const convertedToStoreMaterials = convertMapDataToStore(
            this.allMaterials
          );
          this.database.storeData(
            project.uid,
            convertedToStoreMaterials,
            "materials"
          );
          this.mergePropertiesAndData();
          this.materialsWorkerLoaded = true;
          console.log("properties with materials well stored");
        };
      } else {
        this.allMaterials = storedAllMaterials;
        this.mergePropertiesAndData();
        this.materialsWorkerLoaded = true;
      }
    }
  }

  mergePropertiesAndData() {
    this.allMaterials.forEach((allMatByModel, key) => {
      const allPropByModel = this.allProperties.get(key);
      if (allMatByModel && allPropByModel) {
        const mergePropAndMat = [...allPropByModel, ...allMatByModel];
        this.allProperties.set(key, mergePropAndMat);
      }
    });
  }

  updateVisibleModels(selectedModelsName: string[]) {
    this.highlighter.clear(this.transparentColor);
    const modelsVisible = this.allModelsNameAndId.filter((model) =>
      selectedModelsName.includes(model.displayName)
    );
    const excludedModelsNameAndId = this.allModelsNameAndId.filter(
      (model) => !selectedModelsName.includes(model.displayName)
    );

    // On cache les modèles non sélectionnés
    for (const excludedModelNameAndId of excludedModelsNameAndId) {
      const modelId = excludedModelNameAndId.modelId;
      const [allElementsByModel, allSpacesByModel] =
        this.getAllElementsAndSpacesByModel(modelId);
      this.fragmentHider.set(false, allElementsByModel);
      this.fragmentHider.set(false, allSpacesByModel);
      this.visibilityData.visibleElements = subtractFragmentIdMaps(
        this.visibilityData.visibleElements,
        allElementsByModel
      );
      this.visibilityData.visibleSpaces = subtractFragmentIdMaps(
        this.visibilityData.visibleSpaces,
        allSpacesByModel
      );
    }

    // On affiche les modèles sélectionnés
    for (const visibleModelNameAndId of modelsVisible) {
      if (this.visibilityData.visibleModels.includes(visibleModelNameAndId))
        continue;
      const modelId = visibleModelNameAndId.modelId;
      const [allElementsByModel, allSpacesByModel] =
        this.getAllElementsAndSpacesByModel(modelId);

      this.visibilityData.visibleElements = unionFragmentIdMaps(
        this.visibilityData.visibleElements,
        allElementsByModel
      );
      if (this.isSpacesVisible) {
        this.fragmentHider.set(true, allSpacesByModel);
        this.visibilityData.visibleElements = unionFragmentIdMaps(
          this.visibilityData.visibleSpaces,
          allSpacesByModel
        );
      }
      this.fragmentHider.set(true, allElementsByModel);
    }
    this.visibilityData.visibleModels = modelsVisible;
    // MAJ des eléments / espaces dans allElements/allSpaces
    this.getAllElementsAndSpaces();
    this.sendVisibilityData();
  }

  async initViewer() {
    const controls = this.cameraComponent.controls;
    controls.fitToSphere(this.bboxMesh, true);
    await this.showAll();
    this.initMaterial();
  }

  private setUpPostProduction(active: boolean) {
    this.postProdRenderer.postproduction.enabled = active;
    this.postProdRenderer.postproduction.customEffects.outlineEnabled = active;
  }

  togglePostProduction(active: boolean) {
    this.isPostProdActive = active;
    this.setUpPostProduction(active);
  }

  private async setUpCulling() {
    await this.culler.setup();
    this.culler.needsUpdate = true;
    let controlsStartTimeout: ReturnType<typeof setTimeout> | null = null;

    const controls = this.cameraComponent.controls;

    controls.addEventListener("sleep", () => {
      this.onCameraControlsEnd(controlsStartTimeout);
    });
    document.addEventListener("mouseup", () => {
      this.onCameraControlsEnd(controlsStartTimeout);
    });

    controls.addEventListener("controlstart", () => {
      if (this.isPostProdActive) {
        // Set a timeout for control start
        controlsStartTimeout = setTimeout(() => {
          this.setUpPostProduction(false);
          controlsStartTimeout = null;
        }, 200);
      }
    });

    let wheelTimer: number | null = null;

    this.container.addEventListener(
      "wheel",
      () => {
        this.culler.needsUpdate = true;

        if (this.isPostProdActive) {
          this.setUpPostProduction(false);

          if (wheelTimer !== null) {
            clearTimeout(wheelTimer);
          }

          wheelTimer = setTimeout(() => {
            this.setUpPostProduction(true);
          }, 200) as unknown as number;
        }
      },
      { passive: true }
    );
  }

  onCameraControlsEnd(
    controlsStartTimeout: ReturnType<typeof setTimeout> | null
  ) {
    if (this.isPostProdActive) {
      if (controlsStartTimeout) {
        clearTimeout(controlsStartTimeout);
        controlsStartTimeout = null;
      }
      this.setUpPostProduction(true);
    }
    this.culler.needsUpdate = true;
  }

  private setUpHighlight() {
    this.highlighter.outlineEnabled = false;
    this.addHighlighter(this.defaultHighlightColor);
    this.addHighlighter(this.transparentColor, true, 0.1);
  }

  async addHighlighter(
    color: string,
    transparent: boolean = true,
    opacity: number = 0.9
  ) {
    const highlightMaterial = new THREE.MeshLambertMaterial({
      transparent: transparent,
      opacity: opacity,
      color: color,
      depthTest: false,
    });
    await this.highlighter.add(`${color}`, [highlightMaterial]);
  }

  async toggleSpacesVisibility(active: boolean) {
    this.fragmentHider.set(active, this.allSpacesIdMap);
    if (active) {
      this.isSpacesVisible = true;
      this.visibilityData = {
        ...this.visibilityData,
        visibleSpaces: this.allSpacesIdMap,
      };
    } else {
      this.isSpacesVisible = false;
      this.visibilityData = { ...this.visibilityData, visibleSpaces: {} };
    }
  }

  getAllElementsAndSpaces(firstLoading: boolean = false) {
    if (firstLoading) {
      for (const model of this.allModels) {
        const [allElementsByModel, allSpacesByModel] =
          this.getAllElementsAndSpacesByModel(model.uuid);
        console.log(allSpacesByModel);
        this.allSpacesIdMap = unionFragmentIdMaps(
          allSpacesByModel,
          this.allSpacesIdMap
        );
        this.allElementsIdMap = unionFragmentIdMaps(
          allElementsByModel,
          this.allElementsIdMap
        );
      }
    } else {
      this.allElementsIdMap = {};
      this.allSpacesIdMap = {};
      for (const modelNameAndId of this.visibilityData.visibleModels) {
        const modelObject = this.allModels.find((model) => {
          if (model.uuid === modelNameAndId.modelId) return model;
        });
        const [allElementsByModel, allSpacesByModel] =
          this.getAllElementsAndSpacesByModel(modelObject.uuid);
        this.allSpacesIdMap = unionFragmentIdMaps(
          allSpacesByModel,
          this.allSpacesIdMap
        );
        this.allElementsIdMap = unionFragmentIdMaps(
          allElementsByModel,
          this.allElementsIdMap
        );
      }
    }
  }

  getAllElementsAndSpacesByModel(modelId: string) {
    const fragmentClassifier = this.components.tools.get(
      OBC.FragmentClassifier
    );
    console.log("here");
    const allSpaces = fragmentClassifier.find({
      model: [modelId],
      entities: ["IFCSPACE"],
    });
    const allElementsWithSpaces = fragmentClassifier.find({
      model: [modelId],
    });
    const allElements = subtractFragmentIdMaps(
      allElementsWithSpaces,
      allSpaces
    ) as FragmentIdMap;

    return [allElements, allSpaces];
  }

  async showAll() {
    this.fragmentHider.set(true, this.allElementsIdMap);
    if (this.isSpacesVisible === true) {
      this.fragmentHider.set(true, this.allSpacesIdMap);
    }
    this.highlighter.clear(this.transparentColor);
    this.visibilityData = {
      ...this.visibilityData,
      visibleElements: this.allElementsIdMap,
      visibleSpaces: this.allSpacesIdMap,
      hasHiddenElements: false,
      isolated: false,
      highlight: false,
    };
    this.sendVisibilityData();
  }

  hideSelected() {
    this.fragmentHider.set(false, this.visibilityData.selectedElements);
    this.fragmentHider.set(false, this.visibilityData.selectedSpaces);
    this.highlighter.clear(this.defaultHighlightColor);

    const visibleElementsIdMap = subtractFragmentIdMaps(
      this.visibilityData.visibleElements,
      this.visibilityData.selectedElements
    );
    const visibleSpacesIdMap = subtractFragmentIdMaps(
      this.visibilityData.visibleSpaces,
      this.visibilityData.selectedSpaces
    );

    this.visibilityData = {
      ...this.visibilityData,
      isolated: false,
      hasHiddenElements: true,
      highlight: false,
      visibleElements: visibleElementsIdMap,
      visibleSpaces: visibleSpacesIdMap,
      selectedElements: {},
      selectedSpaces: {},
    };
    this.sendVisibilityData();
  }

  isolateSelected(isolationType?: string) {
    const selectedElements = this.visibilityData.selectedElements;
    const selectedSpaces = this.visibilityData.selectedSpaces;
    const elementsToHide = subtractFragmentIdMaps(
      this.allElementsIdMap,
      selectedElements
    );
    const spacesToHide = subtractFragmentIdMaps(
      this.allSpacesIdMap,
      selectedSpaces
    );

    this.highlighter.clear(this.transparentColor);

    if (isolationType && isolationType === "extract") {
      this.highlighter.highlightByID(this.transparentColor, elementsToHide);
    }
    this.fragmentHider.set(false, elementsToHide);
    this.fragmentHider.set(false, spacesToHide);

    this.visibilityData = {
      ...this.visibilityData,
      isolated: true,
      hasHiddenElements: true,
      visibleElements: selectedElements,
      visibleSpaces: selectedSpaces,
    };
    this.sendVisibilityData();
  }

  async selectVisible() {
    this.clearMultiSelectData();

    const visibleElements = this.visibilityData.visibleElements;
    await this.highlighter.highlightByID(
      this.defaultHighlightColor,
      visibleElements
    );

    this.visibilityData = {
      ...this.visibilityData,
      selectedElements: visibleElements,
      highlight: true,
    };
    this.sendVisibilityData();

    const selectedElementsByType = sortElementsByType(
      this.fragments,
      this.visibilityData.selectedElements,
      this.selectedLanguage
    );

    for (const fragmentId in this.visibilityData.selectedElements) {
      const model = findFragmentModel(this.fragments, fragmentId);
      for (const expressId of this.visibilityData.selectedElements[
        fragmentId
      ]) {
        const element = model.properties[+expressId];
        if (!element) continue;
        this.multiSelectData.globalIds.add({
          expressId: expressId,
          globalId: element.GlobalId.value,
        });
        this.multiSelectData.elementsByType = selectedElementsByType;
      }
    }

    this.events.trigger({
      type: "GET_MULTI_SELECT_DATA",
      payload: this.multiSelectData,
    });
    this.clearMultiSelectData();
  }

  setupWhiteMaterial() {
    if (!this.materialManager.get().includes("white")) {
      const whiteColor = new THREE.Color("white");
      const whiteMaterial = new THREE.MeshBasicMaterial({ color: whiteColor });

      this.materialManager.addMaterial("white", whiteMaterial);
      this.materialManager.addMeshes("white", this.fragments.meshes);
    }
    this.materialManager.set(true, ["white"]);
  }

  initMaterial() {
    if (this.materialManager.get().includes("white")) {
      this.materialManager.set(false, ["white"]);
    }
  }

  getFilterData() {
    const filterData = new Map();
    const fragmentClassifier = this.components.tools.get(
      OBC.FragmentClassifier
    );
    const listStoreysName = getAllStoreys(fragmentClassifier) as Storey[];
    const listClassesName = getAllClasses(fragmentClassifier) as Class[];
    // const allElementAssembly = OBC.IfcPropertiesUtils.getAllItemsOfType(
    //   this.allModels[0].properties,
    //   4123344466
    // );
    // if (allElementAssembly.length) {
    //   const elementAssemblyClassName: Class = {
    //     type: "Class",
    //     uid: "IFCELEMENTASSEMBLY",
    //     displayName: "IFCELEMENTASSEMBLY",
    //   };
    //   listClassesName.push(elementAssemblyClassName);
    // }
    const listPropAndValues = getPropsAndValues(
      this.allProperties,
      this.allModels,
      this.materialsWorkerLoaded
    );
    filterData.set("storeys", listStoreysName);
    filterData.set(
      "classes",
      translateListOfClasses(listClassesName, this.selectedLanguage)
    );
    filterData.set(
      "properties",
      translateProps(listPropAndValues, this.selectedLanguage)
    );
    this.events.trigger({ type: "LOAD_FILTER_DATA", payload: filterData });
  }

  async createFilter(
    listSelectedStoreys: Storey[],
    listSelectedClasses: Class[],
    queriesGroupList: FilterQueryGroup[]
  ) {
    this.events.trigger({ type: "CLOSE_RESULT_FILTER_DATA" });

    const allElementsAndSpacesIdMap = getAllEntitiesIdMap(
      this.allElementsIdMap,
      this.allSpacesIdMap
    );

    let newVisibleElements: OBC.FragmentIdMap = {};
    let matchingElementsBasicIdMap: OBC.FragmentIdMap = {};
    const translateSelectedClasses = reverseTranslateListOfClasses(
      listSelectedClasses,
      this.selectedLanguage
    );
    const fragmentClassifier = this.components.tools.get(
      OBC.FragmentClassifier
    );
    const elementsFilteredByStorey: FragmentIdMap =
      await getFittingElementsFromFilter(
        listSelectedStoreys,
        fragmentClassifier,
        allElementsAndSpacesIdMap
      );
    const elementsFilteredByClass: FragmentIdMap =
      await getFittingElementsFromFilter(
        translateSelectedClasses,
        fragmentClassifier,
        allElementsAndSpacesIdMap
      );

    const matchingElementsBasicFilterByModel = intersectFragmentIdMaps(
      elementsFilteredByStorey,
      elementsFilteredByClass
    );

    matchingElementsBasicIdMap = unionFragmentIdMaps(
      matchingElementsBasicFilterByModel,
      matchingElementsBasicIdMap
    );

    // Property Filter
    let matchingPropFilterIdMap: OBC.FragmentIdMap | undefined = {};

    for (const queryGroup of queriesGroupList) {
      const operator = queryGroup.operator;
      const matchingFragmentIdsMapQueryGroup =
        this.getMatchingIdMapFromQueryGroup(
          queryGroup,
          allElementsAndSpacesIdMap
        );

      if (!operator) {
        matchingPropFilterIdMap = matchingFragmentIdsMapQueryGroup;
      } else {
        if (operator === "AND") {
          matchingPropFilterIdMap = intersectFragmentIdMaps(
            matchingPropFilterIdMap,
            matchingFragmentIdsMapQueryGroup
          );
        }
        if (operator === "OR") {
          matchingPropFilterIdMap = unionFragmentIdMaps(
            matchingPropFilterIdMap,
            matchingFragmentIdsMapQueryGroup
          );
        }
      }
    }

    // Resultat du Filtre multi critères
    let matchingFilterIdMap: OBC.FragmentIdMap = {};
    if (queriesGroupList.length) {
      matchingFilterIdMap = intersectFragmentIdMaps(
        matchingElementsBasicIdMap,
        matchingPropFilterIdMap
      );
    } else {
      matchingFilterIdMap = matchingElementsBasicIdMap;
    }
    this.fragmentHider.set(true, this.allElementsIdMap);
    if (this.isSpacesVisible === true) {
      this.fragmentHider.set(true, this.allSpacesIdMap);
    }

    const listVisibleModels = this.visibilityData.visibleModels;
    let allVisibleElementsIdMap: FragmentIdMap = {};
    // On ne s'intéresse qu'aux élements des modèles visibles
    if (this.allModels.length > 1) {
      for (const model of listVisibleModels) {
        const modelId = model.modelId;
        const allElementsWithSpacesByModel = fragmentClassifier.find({
          model: [modelId],
        });
        allVisibleElementsIdMap = unionFragmentIdMaps(
          allVisibleElementsIdMap,
          allElementsWithSpacesByModel
        );
      }
      matchingFilterIdMap = intersectFragmentIdMaps(
        allVisibleElementsIdMap,
        matchingFilterIdMap
      );
    }

    newVisibleElements = { ...matchingFilterIdMap };
    const elementsToHide = subtractFragmentIdMaps(
      this.allElementsIdMap,
      matchingFilterIdMap
    ) as FragmentIdMap;

    await this.highlighter.highlightByID(this.transparentColor, elementsToHide);
    this.fragmentHider.set(false, elementsToHide);

    if (matchingFilterIdMap) {
      await this.highlighter.highlightByID(
        this.defaultHighlightColor,
        matchingFilterIdMap
      );
    }

    this.visibilityData = {
      ...this.visibilityData,
      visibleElements: newVisibleElements,
      selectedElements: newVisibleElements,
      isolated: true,
      hasHiddenElements: true,
      highlight: true,
    };
    if (this.allElementsIdMap === this.visibilityData.visibleElements) {
      this.visibilityData = {
        ...this.visibilityData,
        isolated: false,
        hasHiddenElements: false,
      };
    }
    const filterResultByType = sortElementsByType(
      this.fragments,
      matchingFilterIdMap,
      this.selectedLanguage
    );
    this.events.trigger({
      type: "GET_RESULT_FILTER_DATA",
      payload: { resultIdMap: matchingFilterIdMap, filterResultByType },
    });
    this.sendVisibilityData();
  }

  getMatchingIdMapFromQueryGroup(
    queryGroup: FilterQueryGroup,
    allElementsAndSpacesIdMap: FragmentIdMap
  ) {
    const matchingIdsQueryGroup = getMatchingIdsFromQueryGroup(
      queryGroup,
      this.allProperties,
      this.allModels,
      this.materialsWorkerLoaded,
      this.selectedLanguage
    );
    let matchingFragmentIdsMapQueryGroup: OBC.FragmentIdMap | undefined =
      toFragmentMap(this.fragments, matchingIdsQueryGroup);

    // Gestion des conditions égal / différents
    if (
      queryGroup.condition === "not-equal" ||
      queryGroup.condition === "not-includes"
    ) {
      matchingFragmentIdsMapQueryGroup = subtractFragmentIdMaps(
        allElementsAndSpacesIdMap,
        matchingFragmentIdsMapQueryGroup
      );
    }
    return matchingFragmentIdsMapQueryGroup;
  }

  getBQFromIdsByType(idsByTypeByModel: Map<string, Set<string>>) {
    const mapBQ: Map<string, number> = new Map();
    for (const [modelId, setOfIds] of idsByTypeByModel) {
      const model = this.allModels.find((model) => model.uuid === modelId);
      for (const expressId of setOfIds) {
        getBQFromElementId(
          model,
          expressId,
          true,
          mapBQ,
          this.propertiesProcessor,
          this.selectedLanguage,
          this.factorUnits
        );
      }
    }
    return mapBQ;
  }

  getResultFilterBqFromIdsByType(
    typeName: string,
    idsByTypeByModel: Map<string, Set<string>>
  ) {
    const mapBQ = this.getBQFromIdsByType(idsByTypeByModel);
    this.events.trigger({
      type: "GET_RESULT_FILTER_BQ_BY_TYPE",
      payload: { selectedTypeName: typeName, bqs: mapBQ },
    });
  }

  getMultiSelectBqFromIdsByType(
    typeName: string,
    idsByTypeByModel: Map<string, Set<string>>
  ) {
    const mapBQ = this.getBQFromIdsByType(idsByTypeByModel);
    this.events.trigger({
      type: "GET_MULTI_SELECT_BQ_BY_TYPE",
      payload: { selectedTypeName: typeName, bqs: mapBQ },
    });
  }

  async colorSelectedQuery(
    color: string,
    queryGroup: FilterQueryGroup,
    resultFilterIdMap: FragmentIdMap
  ) {
    if (Object.keys(queryGroup).length !== 0) {
      this.highlighter.clear(this.defaultHighlightColor);
      const allElementsAndSpacesIdMap = getAllEntitiesIdMap(
        this.allElementsIdMap,
        this.allSpacesIdMap
      );
      const matchingFragmentIdsMapQueryGroup =
        this.getMatchingIdMapFromQueryGroup(
          queryGroup,
          allElementsAndSpacesIdMap
        );

      // on ne garde uniquement les idMap qui fit avec le resultat du filtre (storey / class)
      const matchingIdMap = intersectFragmentIdMaps(
        resultFilterIdMap,
        matchingFragmentIdsMapQueryGroup
      );
      if (matchingFragmentIdsMapQueryGroup) {
        const allHighlighter = this.highlighter.get();
        if (!allHighlighter[color]) {
          await this.addHighlighter(color, false, 1);
        }

        await this.highlighter.highlightByID(color, matchingIdMap);
      }
    } else {
      this.highlighter.clear(color);
    }
  }

  removeColorHighlighter() {
    const existingHighligther = this.highlighter.get();
    for (const color in existingHighligther) {
      if (
        color !== this.defaultHighlightColor &&
        color !== this.transparentColor
      ) {
        this.highlighter.clear(color);
      }
    }
  }

  async updateFilterStateWithSavedFilterData(savedFilter: SavedFilter) {
    this.events.trigger({ type: "CLOSE_RESULT_FILTER_DATA" });
    await this.showAll();

    const resultIdMap = convertArrayToIdMap(savedFilter.convertedResultIdMap);
    const filterResultByType = sortElementsByType(
      this.fragments,
      resultIdMap,
      this.selectedLanguage
    );

    const filterResultData: {
      resultIdMap: FragmentIdMap;
      filterResultByType: Map<string, Map<string, Set<string>>>;
    } = { resultIdMap, filterResultByType };

    const queriesGroupList: FilterQueryGroup[] = savedFilter.filterQueryGroup;

    const filterRequest: {
      storeyQuery: boolean;
      classQuery: boolean;
      classes: Storey[];
      storeys: Class[];
      queriesGroupList: FilterQueryGroup[];
      filterName: string;
    } = {
      storeyQuery: false,
      classQuery: false,
      classes: [],
      storeys: [],
      queriesGroupList,
      filterName: savedFilter.filterName,
    };

    this.events.trigger({
      type: "UPDATE_FILTER_WITH_SAVED_FILTER_DATA",
      payload: { filterRequest, filterResultData },
    });

    const elementsToHide = subtractFragmentIdMaps(
      this.allElementsIdMap,
      resultIdMap
    ) as FragmentIdMap;

    await this.highlighter.highlightByID(this.transparentColor, elementsToHide);
    this.fragmentHider.set(false, elementsToHide);

    if (resultIdMap) {
      await this.highlighter.highlightByID(
        this.defaultHighlightColor,
        resultIdMap
      );
    }
    this.visibilityData = {
      ...this.visibilityData,
      visibleElements: resultIdMap,
      selectedElements: resultIdMap,
      isolated: true,
      hasHiddenElements: true,
      highlight: true,
    };
    this.sendVisibilityData();
  }

  loadPropertiesData(projectId: string) {
    for (const model of this.allModels) {
      const propertiesByModel = indexEntityRelations(model);
      this.allProperties.set(model.uuid, propertiesByModel);
    }
    const convertedToStoreProperties = convertMapDataToStore(
      this.allProperties
    );
    this.database.storeData(
      projectId,
      convertedToStoreProperties,
      "properties"
    );
  }

  checkIfSpaces(map: FragmentIdMap): boolean {
    let isSpaces: boolean = false;
    const result = intersectFragmentIdMaps(map, this.allSpacesIdMap);
    if (!isFragmentIdMapEmpty(result)) {
      isSpaces = true;
    }
    return isSpaces;
  }

  sendVisibilityData() {
    this.events.trigger({
      type: "SEND_VISIBILITY_DATA",
      payload: this.visibilityData,
    });
  }

  exportToExcel(
    elementsByType: Map<string, Map<string, Set<string>>>,
    propsToExport: string[]
  ) {
    const propsToExportObject = getPropsToExport(
      elementsByType,
      propsToExport,
      this.components,
      this.allModels,
      this.selectedLanguage,
      this.allProperties,
      this.propertiesProcessor,
      this.factorUnits
    );
    createExcelTable(propsToExportObject);
  }

  async editSingleElement(
    selectedElementIdMap: FragmentIdMap,
    editedProps: Map<string, string | boolean | number>
  ) {
    // const changeMap: ChangeMap = {};
    // for (const fragmentId in selectedElementIdMap) {
    //   const model = findFragmentModel(this.fragments, fragmentId);
    //   const [expressId] = selectedElementIdMap[fragmentId];
    //   for (const [propName, value] of editedProps) {
    //     const name = reverseTranslateValue(
    //       this.selectedLanguage,
    //       IfcPropertiesTranslation,
    //       propName
    //     );
    //     this.editValue(model, +expressId, name, value, changeMap);
    //   }
    //   const fileData = this.uint8ArrayMap[model.uuid] as Uint8Array;
    //   // const resultBuffer = await this.saveToIfc(model, fileData, changeMap);
    //   const ifc = await this.propertiesManager.saveToIfc(model, fileData);
    //   const a = document.createElement("a");
    //   const url = URL.createObjectURL(new Blob([ifc]));
    //   a.href = url;
    //   a.download = model.name;
    //   a.click();
    //   URL.revokeObjectURL(url);
    // }
  }

  editValue(
    model: any,
    expressID: number,
    name: string,
    value: string | number | boolean,
    changeMap: ChangeMap
  ) {
    const { properties } = OBC.IfcPropertiesManager.getIFCInfo(model);
    const prop = properties[expressID];
    const { key: valueKey } = OBC.IfcPropertiesUtils.getQuantityValue(
      properties,
      expressID
    );

    const { key: nameKey } = OBC.IfcPropertiesUtils.getEntityName(
      properties,
      expressID
    );

    if (name !== "" && nameKey) {
      if (prop[nameKey]?.value) {
        prop[nameKey].value = name;
      } else {
        prop.Name = { type: 1, value: name };
      }
    }

    if (value !== "" && valueKey) {
      if (prop[valueKey]?.value) {
        prop[valueKey].value = value;
      } else {
        prop.NominalValue = { type: 1, value };
      }
    }

    this.registerChange(model, changeMap, expressID);
  }

  registerChange(model: any, changeMap: ChangeMap, ...expressID: number[]) {
    if (!changeMap[model.uuid]) {
      changeMap[model.uuid] = new Set();
    }
    for (const id of expressID) {
      changeMap[model.uuid].add(id);
    }
  }

  async saveToIfc(model: any, fileData: Uint8Array, changeMap: ChangeMap) {
    const { properties } = OBC.IfcPropertiesManager.getIFCInfo(model);
    const ifcLoader = this.components.tools.get(OBC.FragmentIfcLoader);
    let ifcApi = ifcLoader.get();
    const modelID = await ifcLoader.readIfcFile(fileData);
    const changes = changeMap[model.uuid] ?? [];
    for (const expressID of changes) {
      const data = properties[expressID] as any;
      if (!data) {
        try {
          ifcApi.DeleteLine(modelID, expressID);
        } catch (err) {
          // Nothing here...
        }
      } else {
        try {
          ifcApi.WriteLine(modelID, data);
        } catch (err) {
          // Nothing here...
        }
      }
    }
    const modifiedIFC = ifcApi.SaveModel(modelID);
    ifcLoader.get().CloseModel(modelID);

    (ifcApi as any) = null;
    ifcApi = new WEBIFC.IfcAPI();
    return modifiedIFC;
  }

  // async readIfcFile(data: Uint8Array, ifcApi: any) {
  //   const { path, absolute } = this.fragmentIfcLoader.settings.wasm;
  //   ifcApi.SetWasmPath(path, absolute);
  //   await ifcApi.Init();
  //   return ifcApi.OpenModel(data, this.fragmentIfcLoader.settings.webIfc);
  // }
}
