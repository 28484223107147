import { FC, useState } from "react";
import { useAppContext } from "../../../middleware/context-provider";
import { AccountMenu } from "./account-menu";
import styles from "./avatar-profil.module.css";

export const AvatarProfil: FC<{}> = (props) => {
  const state = useAppContext()[0];
  const [isAccountMenuVisible, setIsAccountMenuVisible] =
    useState<boolean>(false);
  const toggleAccountMenu = () => {
    setIsAccountMenuVisible((prevState) => !prevState);
  };
  if (!state.user) return <></>;
  if (!state.user.displayName) return <></>;
  const firstLetter = state.user.displayName[0];
  return (
    <>
      <div className={styles["avatar"]} onClick={toggleAccountMenu}>
        {firstLetter}
      </div>
      {isAccountMenuVisible && <AccountMenu />}
    </>
  );
};
