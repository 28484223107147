import { Checkbox } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./result-filter-content.module.css";
import { useAppContext } from "../../../../middleware/context-provider";
import { getRandomHexColor } from "../../utils/helpers";
import {
  FilterConditions,
  FilterQuery,
  FilterQueryGroup,
} from "../../../../types";
import { ExportExcelContent } from "../exportExcel/export-excel-content";
import { DetailByTypeSection } from "../detailByType/detail-by-type-section";
import { SaveExportFooter } from "../save-export-footer/save-export-footer";
import { SaveResultContent } from "../save-result/save-result-content";

export const ResultFilter: FC = () => {
  const [state, dispatch] = useAppContext();

  const filterRequest = state.filterRequest;
  const filterResultByType = state.filterResultData?.filterResultByType;
  const filterBqBySelectedType = state.filterBqBySelectedType;

  const [selectedColors, setSelectedColors] = useState<Record<string, string>>(
    {}
  );
  const [colorCheckboxesState, setColorCheckboxesState] = useState<any[]>([]);
  const [colorActivated, setColorActivated] = useState<boolean>(true);

  const [exportExl, setExportExl] = useState<boolean>(false);
  const [saveResult, setSaveResult] = useState<boolean>(false);

  const [filterQueryGroupToSave, setFilterQueryGroupToSave] = useState<
    FilterQueryGroup[]
  >([]);

  useEffect(() => {
    // on charge les couleurs générées aléatoirement
    loadRandomColors();
  }, []);

  useEffect(() => {
    initColorCheckboxes();
  }, [selectedColors]);

  if (!filterRequest) return <></>;
  if (!filterResultByType) return <></>;

  const loadRandomColors = () => {
    filterRequest.queriesGroupList.forEach((queryGroup, index) => {
      setColorActivated(true);
      if (
        queryGroup.condition === "not-equal" ||
        queryGroup.condition === "not-includes"
      ) {
        setColorActivated(false);
      }
      queryGroup.queries.forEach((query, index_) => {
        const rowKey = `${index},${index_}`;
        let queryColor: string = "";
        if (!query.color) {
          queryColor = getRandomHexColor();
        } else {
          queryColor = query.color;
        }

        setSelectedColors((prevColors) => ({
          ...prevColors,
          [rowKey]: queryColor,
        }));
      });
    });
  };

  const initColorCheckboxes = () => {
    setColorCheckboxesState(
      Object.entries(selectedColors).map(([rowKey]) => ({
        rowKey,
        isChecked: false,
      }))
    );
    dispatch({ type: "REMOVE_COLORS" });
  };

  const colorInputChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    rowKey: string
  ) => {
    setSelectedColors((prevState) => ({
      ...prevState,
      [rowKey]: event.target.value,
    }));
    setColorCheckboxesState((prevState) =>
      Object.entries(selectedColors).map(([colorRowKey]) =>
        rowKey === colorRowKey ? { ...prevState, isChecked: false } : prevState
      )
    );
  };

  // const handleColorInputFocus = (rowKey: string) => {
  //   setUncheckedCheckboxes((prevUncheckedCheckboxes) => [
  //     ...prevUncheckedCheckboxes,
  //     rowKey,
  //   ]);
  // };

  const checkBoxChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    index: string,
    queryGroup: FilterQueryGroup,
    selectedQuery: FilterQuery
  ) => {
    const isChecked = event.target.checked;
    const id = event.target.id;
    let tempList = colorCheckboxesState.map((checkbox) =>
      checkbox.rowKey === id ? { ...checkbox, isChecked: isChecked } : checkbox
    );
    setColorCheckboxesState(tempList);

    const selectedColor = selectedColors[index];
    if (isChecked) {
      sendColorQuery(queryGroup, selectedQuery, selectedColor);
    } else {
      clearColorQuery(selectedColor);
    }
  };

  const checkAllHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let tempList = colorCheckboxesState.map((checkbox) => {
      return { ...checkbox, isChecked: isChecked };
    });
    setColorCheckboxesState(tempList);

    if (isChecked) {
      Object.entries(selectedColors).map(([rowKey, color]) => {
        const { queryGroup, query } = findQueryByIndex(rowKey);
        sendColorQuery(queryGroup, query, color);
      });
    } else {
      Object.entries(selectedColors).map(([rowKey, color]) => {
        clearColorQuery(color);
      });
    }
  };

  const sendColorQuery = (
    queryGroup: FilterQueryGroup,
    query: FilterQuery,
    color: string
  ) => {
    const newFilterQueryGroup = createNewQueryGroup(queryGroup, query);
    dispatch({
      type: "SEND_COLOR_QUERY",
      payload: {
        selectedColor: color,
        queryGroup: newFilterQueryGroup,
        resultIdMap: state.filterResultData?.resultIdMap,
      },
    });
  };

  // Un queryGroup avec uniquement une query
  const createNewQueryGroup = (
    queryGroup: FilterQueryGroup,
    query: FilterQuery
  ) => {
    let newFilterQueryGroup: FilterQueryGroup | null = null;
    let condition: FilterConditions;
    // Le FilterGroup contient toutes les queries matchant avec le mot rechercher, il faut donc changer la condition
    switch (queryGroup.condition) {
      case "includes":
        condition = "equal";
        break;
      case "not-includes":
        condition = "not-equal";
        break;
      default:
        condition = queryGroup.condition;
        break;
    }
    const queries = [query];
    newFilterQueryGroup = {
      ...queryGroup,
      condition,
      queries,
    };
    return newFilterQueryGroup;
  };

  const clearColorQuery = (color: string) => {
    dispatch({
      type: "SEND_COLOR_QUERY",
      payload: { selectedColor: color, queryGroup: {}, resultIdMap: {} },
    });
  };

  const findQueryByIndex = (index: string) => {
    const indexes = index.split(",");
    const queryGroupIndex = indexes[0];
    const queryIndex = indexes[1];
    const currentQueryGroup: FilterQueryGroup =
      filterRequest.queriesGroupList[+queryGroupIndex];
    const currentQuery: FilterQuery = currentQueryGroup.queries[+queryIndex];
    return { queryGroup: currentQueryGroup, query: currentQuery };
  };

  const getBQByType = (typeName: string) => {
    if (filterResultByType.has(typeName)) {
      const filterResultBySelectedType = filterResultByType.get(typeName);
      if (filterResultBySelectedType) {
        dispatch({
          type: "SEND_TYPENAME_FOR_RESULT_FILTER_BQ",
          payload: {
            filterResultBySelectedType,
            typeName,
          },
        });
      }
    }
  };

  const closeBQ = () => {
    dispatch({
      type: "CLOSE_RESULT_FILTER_BQ",
    });
  };

  const openExportExcel = () => {
    setExportExl(true);
  };
  const closeExportExcel = () => {
    setExportExl(false);
  };
  const openSaveResult = () => {
    getFilterDataToSave();
    setSaveResult(true);
  };
  const closeSaveResult = () => {
    setSaveResult(false);
  };

  const getFilterDataToSave = () => {
    Object.entries(selectedColors).map(([rowKey, color]) => {
      const { queryGroup, query } = findQueryByIndex(rowKey);
      query.color = color;
      const newQueryGroup = createNewQueryGroup(queryGroup, query);
      setFilterQueryGroupToSave((prevData) => [
        ...prevData,
        ...[newQueryGroup],
      ]);
    });
  };

  const filterContentClasses = `${styles["filter-content__block"]} ${styles["larger"]}`;

  return (
    <>
      <div className={styles["two-rows-with-footer"]}>
        {/* {state.filterRequest?.filterName && (
          <div className={styles.title}>
            <span>{state.filterRequest?.filterName}</span>{" "}
          </div>
        )} */}
        <div>
          {filterRequest.storeyQuery && (
            <div className={styles["filter-content__line"]}>
              <div className={styles.subtitle}>
                <span>Etage(s) sélectionné(s)</span>
                <span className={styles["round-number"]}>
                  {filterRequest.storeys?.length}
                </span>
              </div>
            </div>
          )}
          {filterRequest.classQuery && (
            <div className={styles["filter-content__line"]}>
              <div className={styles.subtitle}>
                <span>Catégorie(s) sélectionnée(s)</span>
                <span className={styles["round-number"]}>
                  {filterRequest.classes?.length}
                </span>
              </div>
            </div>
          )}
          {filterRequest.queriesGroupList.length > 0 && (
            <div className={filterContentClasses}>
              <div className={styles["filter-content__line"]}>
                <div className={styles.subtitle}>
                  <span>Propriétées sélectionnées</span>
                  {colorActivated && (
                    <Checkbox
                      size="small"
                      id={"all"}
                      checked={
                        !colorCheckboxesState.some(
                          (colorCheckbox: any) =>
                            colorCheckbox?.isChecked !== true
                        )
                      }
                      onChange={checkAllHandler}
                    />
                  )}
                </div>
              </div>
              <div className={styles["scroll-content"]}>
                <table className={styles["table"]}>
                  <tbody>
                    {colorCheckboxesState.length > 0 &&
                      filterRequest.queriesGroupList.map((queryGroup, index) =>
                        queryGroup.queries.map((query, index_) => {
                          const rowKey = `${index},${index_}`;
                          const colorCheckbox = colorCheckboxesState.find(
                            (checkbox) => checkbox.rowKey === rowKey
                          );
                          return (
                            <tr key={rowKey}>
                              <td>{queryGroup.operator}</td>
                              <td>{query.propName}</td>
                              <td>{queryGroup.condition}</td>
                              <td>{query.propValue.toString()}</td>
                              {queryGroup.condition !== "not-equal" &&
                                queryGroup.condition !== "not-includes" && (
                                  <>
                                    <td>
                                      <input
                                        type="color"
                                        value={
                                          selectedColors[rowKey] || "#ffffff"
                                        }
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>
                                        ) =>
                                          colorInputChangeHandler(event, rowKey)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Checkbox
                                        size="small"
                                        id={rowKey}
                                        checked={
                                          colorCheckbox?.isChecked || false
                                        }
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>
                                        ) =>
                                          checkBoxChangeHandler(
                                            event,
                                            rowKey,
                                            queryGroup,
                                            query
                                          )
                                        }
                                      />
                                    </td>
                                  </>
                                )}
                            </tr>
                          );
                        })
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <DetailByTypeSection
            elementsByType={filterResultByType}
            elementBqBySelectedType={filterBqBySelectedType}
            closeBQ={closeBQ}
            getBqByType={getBQByType}
          />
        </div>
        {filterResultByType.size ? (
          <SaveExportFooter
            openExportExcel={openExportExcel}
            openSaveResult={openSaveResult}
          />
        ) : (
          <></>
        )}
      </div>
      {exportExl && state.filterResultData && (
        <ExportExcelContent
          elementsByType={state.filterResultData.filterResultByType}
          onClose={closeExportExcel}
        />
      )}
      {saveResult && state.filterResultData && (
        <SaveResultContent
          onClose={closeSaveResult}
          filterQueryGroupToSave={filterQueryGroupToSave}
        />
      )}
    </>
  );
};
