import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../middleware/context-provider";
import { AvatarProfil } from "./account-menu/avatar-profil";
import { GoBack } from "./go-back";
import styles from "./header.module.css";

export const Header: FC = () => {
  const location = useLocation();
  const state = useAppContext()[0];
  let showBackBtn = location.pathname !== "/projects" ? true : false;

  let title = "Mes Projets";
  if (state.project) {
    title = state.project.projectName;
  }

  return (
    <header className={styles["header"]}>
      {showBackBtn && <GoBack />}
      <h1>{title}</h1>
      <AvatarProfil />
    </header>
  );
};
